import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import * as interfaces from '@ds/interfaces';

import { ApiService } from './api.service';
import { PaginatedResults, PaginatedQuery } from '@ds/interfaces';
import { tap } from 'rxjs/operators';

const resource = 'form-entries';
type ModelType = interfaces.DsFormEntry;

export interface FormEntryFindAllOptions extends PaginatedQuery {
  id?: string | number | string[] | number[];
}

@Injectable({
  providedIn: 'root',
})
export class FormEntryService {
  constructor(private api: ApiService) {}

  findAll(
    params?: FormEntryFindAllOptions
  ): Observable<PaginatedResults<ModelType>> {
    return this.api.get<PaginatedResults<ModelType>>(resource, { ...params });
  }

  findOne(id?: number): Observable<ModelType> {
    return this.api.get(`${resource}/${id}`);
  }

  create(model: ModelType): Observable<ModelType> {
    return this.api.post(resource, model);
  }

  update(model: ModelType): Observable<ModelType> {
    return this.api.put(`${resource}/${model.id.toString()}`, model);
  }

  destroy(model: ModelType): Observable<ModelType> {
    return this.api.delete(`${resource}/${model.id.toString()}`);
  }
}
