import { Directive, HostListener, HostBinding } from '@angular/core';
import { Subject, BehaviorSubject } from 'rxjs';

@Directive({
  selector: '[dsMenuItem]',
})
export class DsMenuItemDirective {
  readonly isHovered: Subject<boolean> = new BehaviorSubject<boolean>(false);

  @HostListener('mouseenter')
  handleMouseEnter() {
    this.isHovered.next(true);
  }

  @HostListener('mouseleave')
  handleMouseLeave() {
    this.isHovered.next(false);
  }

  @HostBinding('class.ds-menu-item')
  get menuItemClass() {
    return true;
  }
}
