import {
  ChangeDetectionStrategy,
  Component,
  HostBinding,
  Input,
} from '@angular/core';

import { DomSanitizer, SafeStyle } from '@angular/platform-browser';
import { ImageLightboxService } from '@ds/core';
import { RowContentBlock, SectionContentBlock } from '@ds/interfaces';
import { Observable, of } from 'rxjs';
import { ContentBlockDataService } from '../../services/content-block-data.service';

@Component({
  selector: 'ds-cb-section',
  template: `
    <ds-cb-header
      *ngIf="cbSectionTitle && !isTitleInline"
      [class]="titlePosition"
      [text]="cbSectionTitle"
      textSize="large"
    >
    </ds-cb-header>
    <div
      class="section-container"
      [style.padding.px]="innerPadding"
      [style.border-radius.px]="innerPadding"
      [style.background-color]="innerBackgroundColor"
    >
      <div *ngIf="cbSectionTitle && isTitleInline" class="ds-row-sc inline">
        <ds-cb-header
          [text]="cbSectionTitle"
          textSize="large"
          textAlign="center"
        ></ds-cb-header>
      </div>
      <ds-cb
        *ngFor="let row of _rows | async; trackBy: trackByFn"
        [block]="row"
      ></ds-cb>
      <ng-content></ng-content>
      <div class="divider"></div>
    </div>
  `,
  styles: [
    `
      :host {
        position: relative;
        flex-direction: column;
        display: flex;
        margin-top: 0;
        align-items: center;
        margin-left: auto;
        margin-right: auto;
        background-size: cover;
        justify-content: center;

        padding-top: 70px;

        padding-bottom: 25px;
        padding-left: 0px;
        padding-right: 0px;
        background-position: center;
      }

      @media (min-width: 1400px) {
        :host:not(.inline-title) {
          padding-top: 95px;
        }
      }
      .section-container {
        max-width: var(--ds-cb-section-max-width, 1170px);
        width: 95%;
      }
      .divider {
        width: 100%;
        display: block;
        border-bottom: 1px solid var(--ds-cb-section-divider-color);
      }
      :host(.inline-title) {
        padding: 15px 0px;
      }

      :host-context(.hide-divider) .divider {
        display: none;
      }
      ds-cb-header.top-left {
        position: absolute;
        top: 0;
        left: 0;
        margin-top: 0 !important;
      }
      ds-cb-header.top-right {
        position: absolute;
        top: 0;
        right: 0;
        margin-top: 0 !important;
      }
      ds-cb-header.bottom-left {
        position: absolute;
        bottom: 0;
        left: 0;
        margin-top: 0 !important;
      }
      ds-cb-header.bottom-right {
        position: absolute;
        bottom: 0;
        right: 0;
        margin-top: 0 !important;
      }
      ds-cb-header {
        max-width: 100%;
        padding: 10px 25px;
        background-color: var(
          --ds-cb-section-title-background-color,
          var(--ds-cb-title-bar-background-color)
        );
      }
      ds-cb-header ::ng-deep h2 {
        color: var(--ds-cb-section-title-color, var(--ds-cb-title-bar-color));
        min-width: 300px;
      }
      :host:not(.inline-title) ds-cb-header {
        width: 100%;
      }
      :host:not(.inline-title) ds-cb-header ::ng-deep h2 {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ContentBlockSectionComponent {
  @Input() set rows(value: RowContentBlock[]) {
    this._rows = of(value);
  }
  @Input() cbSectionTitle: string;
  @Input() height: number;
  @Input() heightUnits: '%' | 'px' | 'vh';
  @Input()
  set backgroundImage(val: string) {
    this.backgroundImageUrl = val ? this.safeTitleBackgroundImage(val) : null;
  }
  @Input() innerBackgroundColor: string;
  @Input() innerPadding: number;
  @HostBinding('class.hide-divider') @Input() hideDivider: boolean;

  @Input() @HostBinding('style.background-color') backgroundColor: string;

  @HostBinding('style.background-image') backgroundImageUrl: SafeStyle;
  @Input() @HostBinding('style.justify-content') justifyContent:
    | 'center'
    | 'flex-start'
    | 'flex-end';

  @HostBinding('style.min-height')
  get sectionHeight(): string {
    if (!this.heightUnits || !this.height) {
      return 'auto';
    }
    return `${this.height}${this.heightUnits}`;
  }

  @Input() titlePosition: string;

  @Input()
  set block(val: SectionContentBlock) {
    this.init(val);
  }

  @HostBinding('class.inline-title') get isTitleInline() {
    return !this.titlePosition || this.titlePosition === 'inline';
  }

  @HostBinding('class.absolute-title') get absoluteTitle() {
    return !this.isTitleInline;
  }

  _rows: Observable<RowContentBlock[]>;

  constructor(
    private cbDataService: ContentBlockDataService,
    private sanitizer: DomSanitizer,
    private imageService: ImageLightboxService
  ) {}

  init(block: SectionContentBlock) {
    this.cbSectionTitle = block.config.title;
    this._rows =
      block.content && block.content.length
        ? of(block.content)
        : this.cbDataService.getChildren(block.id);
    this.height = block.config.height;
    this.heightUnits = block.config.heightUnits;
    this.justifyContent = block.config.justifyContent;
    this.backgroundImage = block.config.outerBackgroundImage;
    this.backgroundColor = block.config.outerBackgroundColor;
    this.innerBackgroundColor = block.config.innerBackgroundColor;
    this.innerPadding = block.config.innerPadding;
    this.hideDivider = block.config.hideDivider;
    this.titlePosition = block.config.titlePosition;
  }

  trackByFn(index, model) {
    return model.id;
  }

  safeTitleBackgroundImage(imageHandle: string) {
    return imageHandle
      ? this.sanitizer.bypassSecurityTrustStyle(
          `url(${this.imageService.getUrl(imageHandle)})`
        )
      : null;
  }
}
