import { Component } from '@angular/core';
import {
  TContentBlock,
  FirstDataPaymentFormContentBlock,
} from '@ds/interfaces';

@Component({
  template: ` <form
    action="https://globalgatewaye4.firstdata.com/pay"
    id="pay_now_form_74742aaf30"
    method="post"
    class="form-horizontal"
    name="paymentForm"
  >
    <hr />
    <input name="x_login" type="hidden" value="WSP-TRUCK-is2n2wDVTA" />
    <input name="x_show_form" type="hidden" value="PAYMENT_FORM" />
    <input name="x_fp_sequence" type="hidden" value="1599251335638860672" />
    <input
      name="x_fp_hash"
      type="hidden"
      value="PNB-1.0-dbbabd6e4026883a9b07f6ea02d0bc4f9d4a8b3a"
    />
    <input name="x_amount" type="hidden" value="" />
    <input name="x_currency_code" type="hidden" value="USD" />
    <input name="x_test_request" type="hidden" value="FALSE" />
    <input name="x_relay_response" type="hidden" value="" />
    <input
      name="donation_prompt"
      type="hidden"
      value="Pay Now Truck Center Companies"
    />

    <div class="form-group">
      <label class="control-label col-sm-4" for="contactFormEmail"
        >Name on Account:</label
      >
      <div class="col-sm-8">
        <input
          type="text"
          class="form-control"
          ng-model="payment.x1"
          name="x_invoice_num"
          id="x_invoice_num"
          required
        />
      </div>
    </div>

    <div class="form-group">
      <label class="control-label col-sm-4" for="contactFormEmail"
        >Account Number:</label
      >
      <div class="col-sm-8">
        <input
          type="text"
          class="form-control"
          ng-model="payment.x2"
          name="x_po_num"
          id="x_po_num"
          required
        />
      </div>
    </div>

    <div class="form-group">
      <label class="control-label col-sm-4" for="contactFormEmail"
        >Phone Number:</label
      >
      <div class="col-sm-8">
        <input
          type="text"
          class="form-control"
          ng-model="payment.x3"
          name="x_reference_3"
          id="x_reference_3"
          required
        />
      </div>
    </div>

    <input
      name="button_code"
      type="hidden"
      value="Pay Now Truck Center Companies"
    />
    <div>
      <button
        type="button"
        class="ds-button"
        ng-disabled="paymentForm.$invalid"
        onclick="document.getElementById('pay_now_form_74742aaf30').submit()"
      >
        <i class="fa fa-lock"></i> Make A Payment Now
      </button>
      <br />
      <br />
      You will be taken to a secure payment form.
    </div>
  </form>`,
})
export class ContentBlockFirstDataPaymentFormComponent {
  block: FirstDataPaymentFormContentBlock;

  constructor() {}

  init(block: FirstDataPaymentFormContentBlock) {
    this.block = block;
  }
}
