import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Input,
} from '@angular/core';
import { LocationService, PageService } from '@ds/api-services';
import { RetailLocation, PublicRouteParams, Page } from '@ds/interfaces';
import { Observable, of } from 'rxjs';
import {
  map,
  distinctUntilChanged,
  switchMap,
  tap,
  publishReplay,
  refCount,
} from 'rxjs/operators';
import { Router, ActivatedRoute } from '@angular/router';
import { ContentBlockPageService } from '../../services/content-block-page.service';
import { ContentBlockDataService } from '../../services/content-block-data.service';
import { filterNullOrUndefined } from '@ds/core';

@Component({
  selector: 'ds-cb-location',
  template: `
    <ng-container *ngIf="isBrowsing$ | async; else contentTpl">
      <!-- <ds-cb
        *ngFor="let block of content | async; trackBy: trackByFn"
        [block]="block"
        class="main"
      ></ds-cb> -->
      <ds-cb-location-list
        [isLegacy]="isLegacy"
        [locations$]="locations$"
      ></ds-cb-location-list>
    </ng-container>
    <ng-template #contentTpl>
      <ds-cb-location-detail
        *ngIf="isLegacy"
        [parentSlug]="locationPageSlug"
        [location]="currentLocation$ | async"
      ></ds-cb-location-detail>
      <ds-cb-location-detail-two
        *ngIf="!isLegacy"
        [parentSlug]="locationPageSlug"
        [location]="currentLocation$ | async"
      ></ds-cb-location-detail-two>
    </ng-template>
  `,
  styles: [],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ContentBlockLocationComponent implements OnInit {
  @Input() pageData: Page;
  locationPageSlug: string;

  isBrowsing$ = this.route.params.pipe(
    map((params) => !params.p1 || params.p1.length === 0)
  );

  isLegacy = this.cbPageService.isLegacySite;

  locations$ = this.locationService.findAll().pipe(
    map((d) => d.results),
    publishReplay(1),
    refCount(),
    filterNullOrUndefined()
  );

  currentLocation$: Observable<RetailLocation> = this.route.params.pipe(
    map((params: PublicRouteParams): string => {
      if (params.slug !== this.locationPageSlug) {
        return null;
      }

      if (!params.p1 || params.p1.length === 0) {
        return null;
      }

      return params.p1.split('-').pop();
    }),
    distinctUntilChanged(),
    switchMap((id) => {
      if (!id) {
        return of(null);
      }
      return this.locationService.findOne(id);
    }),
    tap((location: RetailLocation) => {
      if (location) {
        this.cbPageService.setTitleAndStuff({ title: location.name });
      } else {
        this.cbPageService.setTitleAndStuff();
      }
    })
  );

  content: Observable<any[]>;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private locationService: LocationService,
    private cbPageService: ContentBlockPageService,
    private pageService: PageService,
    private cbDataService: ContentBlockDataService
  ) {
    this.locationPageSlug = this.route.snapshot.params.slug;
  }

  ngOnInit() {
    this.content = this.cbDataService.getChildren(null);
  }

  trackByFn(index, model) {
    return model.id;
  }
}
