import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NonePipe } from './pipes/none.pipe';
import { NoneDatePipe } from './pipes/none-date.pipe';
import { PhonePipe } from './pipes/phone-pipe';
import { OverlayModule } from '@angular/cdk/overlay';
import { PortalModule } from '@angular/cdk/portal';
import { DsMenuComponent } from './components/menu/ds-menu.component';
import { DsMenuItemDirective } from './components/menu/ds-menu-item.directive';
import { DsMenuTriggerDirective } from './components/menu/ds-menu-trigger.directive';
import { ImageLightBoxComponent } from './components/image-lightbox/image-lightbox.component';
import { NgxGalleryModule } from '@kolkov/ngx-gallery';
import { ImageDirective } from './directives/image.directive';
import { ModalComponent } from './components/modal/modal.service';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

@NgModule({
  imports: [
    FormsModule,
    CommonModule,
    OverlayModule,
    PortalModule,
    NgxGalleryModule,
    ReactiveFormsModule,
  ],
  declarations: [
    NonePipe,
    NoneDatePipe,
    PhonePipe,
    DsMenuComponent,
    DsMenuItemDirective,
    DsMenuTriggerDirective,
    ImageDirective,
    ImageLightBoxComponent,
    ModalComponent,
  ],
  exports: [
    NonePipe,
    NoneDatePipe,
    PhonePipe,
    DsMenuComponent,
    DsMenuItemDirective,
    DsMenuTriggerDirective,
    ImageDirective,
    ImageLightBoxComponent,
    OverlayModule,
    FormsModule,
    ReactiveFormsModule,
  ],
})
export class CoreModule {}
