import {
  Component,
  Input,
  OnInit,
  ChangeDetectionStrategy,
  HostListener,
} from '@angular/core';
import {
  TContentBlock,
  Page,
  SiteUpdateMessageEvent,
  SiteUpdateMessageTypes,
} from '@ds/interfaces';
import { ContentBlockDataService } from '../../services/content-block-data.service';

@Component({
  selector: 'ds-cb-footer',
  template: `
    <div
      class="foot-content ds-row"
      *ngFor="let block of _content | async; trackBy: trackByFn"
    >
      <div class="ds-col"><ds-cb [block]="block"></ds-cb></div>
    </div>
  `,
  styles: [
    `
      :host {
        display: block;
        padding-left: 15px;
        padding-right: 15px;
      }

      .foot-content {
        color: var(--ds-cb-footer-text-color);
        background-color: var(--ds-cb-footer-background-color);
      }
    `,
  ],
  providers: [ContentBlockDataService],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ContentBlockFooterComponent implements OnInit {
  @Input() footerPage: Page;

  @HostListener('window:message', ['$event'])
  onMessage(e: SiteUpdateMessageEvent) {
    if (!e.data || !e.data.type) return;
    console.log('[New Message Event]', e.data);
    const msg = e.data;

    switch (msg.type) {
      case SiteUpdateMessageTypes.PUBLIC_SAVE_PAGE:
        if (msg.pageId !== this.footerPage.id) return;
        this.cbDataService.savePage(this.footerPage.id);
        break;
      case SiteUpdateMessageTypes.PUBLIC_UPDATE_PAGE:
        if (msg.pageData.id !== this.footerPage.id) return;
        this.footerPage = msg.pageData;
        this.ngOnInit();
        break;
      case SiteUpdateMessageTypes.PUBLIC_UPDATE_CB:
        this.cbDataService.update(msg.content);
        break;
      case SiteUpdateMessageTypes.PUBLIC_HIGHLIGHT_CB:
        this.cbDataService.setHighlighted(msg.cbId);
        break;
      case SiteUpdateMessageTypes.PUBLIC_ADD_CB:
        this.cbDataService.add(msg.content);
        break;
      case SiteUpdateMessageTypes.PUBLIC_REMOVE_CB:
        this.cbDataService.remove(msg.content);
        break;
      default:
        break;
    }
  }

  constructor(private cbDataService: ContentBlockDataService) {}

  ngOnInit() {
    this.cbDataService.registerContent(this.footerPage);
  }

  get _content() {
    return this.cbDataService.getChildren(null);
  }

  trackByFn(index, model) {
    return model.id;
  }
}
