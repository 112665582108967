import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Input,
} from '@angular/core';
import { InventoryMakesContentBlock } from '@ds/interfaces';
import {
  InventoryConfigService,
  PublicInventoryConfig,
} from '@ds/api-services';
import { Observable } from 'rxjs';

@Component({
  selector: 'ds-cb-content-block-inventory-makes',
  template: `
    <ng-container *ngIf="inventoryConfig | async as config">
      <ul class="ds-fluid-text g-wrapper">
        <li *ngFor="let make of config.series" class="g-item">
          <a [routerLink]="['/', slug, make.label]">{{ make.label }}</a>
          <ul>
            <li *ngFor="let series of make.options">
              <a [routerLink]="['/', slug, make.label, series.label]">{{
                series.label
              }}</a>
            </li>
          </ul>
        </li>
      </ul>
    </ng-container>
  `,
  styles: [
    `
      ul {
        list-style: square;
      }

      ul > li {
        margin: 5px 5px 5px 20px;
      }

      li > ul {
        list-style: circle;
      }

      .g-wrapper {
        display: block;
        column-width: 200px;
      }
      .g-item {
        break-inside: avoid;
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ContentBlockInventoryMakesComponent implements OnInit {
  _block: InventoryMakesContentBlock;
  @Input()
  set block(val: InventoryMakesContentBlock) {
    this.init(val);
  }

  inventoryConfig: Observable<PublicInventoryConfig>;

  constructor(private invService: InventoryConfigService) {}

  init(block: InventoryMakesContentBlock) {
    this._block = block;
  }

  ngOnInit() {
    this.inventoryConfig = this.invService.findAllPublic();
  }

  get slug() {
    return this._block.linkToPage.slug;
  }
}
