/**
 * Purpose of interface is to define communication
 * and action between child iframe and parent
 *
 * O when admin loads page -> Public_Navigate_Page
 * O when admin loads content block  -> Public_Highlight_CB
 * O when admin unloads content block  -> Public_UnHighlight_CB
 * O when admin updates content block -> Public_Update_CB
 * O when admin updates site -> Public_Update_Site
 * O when admin updates page -> Public_Update_Page
 *
 * O when public clicks on content block -> Admin_Navigate_CB
 * O when public wants to navigate to another page -> Admin_Navigate_Page (which will then send message back down to public to navigate)
 *
 */

import { Page, ContentBlock, TContentBlock } from './page.interfaces';
import { Site } from './site.interface';
import { SiteTheme } from './theme.interface';

// ACTIONS SENT TO CHILD, IFRAME OR PUBLIC

export enum SiteUpdateMessageTypes {
  PUBLIC_NOTIFY_CHILD_READY = 'DS_WS_PUBLIC_NOTIFY_CHILD_READY',
  PUBLIC_NAVIGATE_PAGE = 'DS_WS_PUBLIC_NAVIGATE_PAGE',
  PUBLIC_HIGHLIGHT_CB = 'DS_WS_PUBLIC_HIGHLIGHT_CB',
  PUBLIC_REMOVE_HIGHLIGHT_CB = 'DS_WS_PUBLIC_REMOVE_HIGHLIGHT_CB',
  PUBLIC_UPDATE_CB = 'DS_WS_PUBLIC_UPDATE_CB',
  PUBLIC_REMOVE_CB = 'DS_WS_PUBLIC_REMOVE_CB',
  PUBLIC_ADD_CB = 'DS_WS_PUBLIC_ADD_CB',
  PUBLIC_MOVE_CB = 'DS_WS_PUBLIC_MOVE_CB',
  PUBLIC_UPDATE_SITE = 'DS_WS_PUBLIC_UPDATE_SITE',
  PUBLIC_UPDATE_SITE_THEME = 'DS_WS_PUBLIC_UPDATE_SITE_THEME',
  PUBLIC_UPDATE_PAGE = 'DS_WS_PUBLIC_UPDATE_PAGE',
  PUBLIC_SAVE_PAGE = 'DS_WS_PUBLIC_SAVE_PAGE',
  // ACTIONS SENT TO PARENT, ADMIN, OR EDITOR
  ADMIN_NOTIFY_PUBLIC_READY = 'DS_WS_ADMIN_NOTIFY_PUBLIC_READY',
  ADMIN_NAVIGATE_CB = 'DS_WS_ADMIN_NAVIGATE_CB',
  ADMIN_NAVIGATE_PAGE = 'DS_WS_ADMIN_NAVIGATE_PAGE',
  ADMIN_SAVE_PAGE = 'DS_WS_ADMIN_SAVE_PAGE',
}

// type SiteUpdateMessageTypes =
//   | PUBLIC_NAVIGATE_PAGE
//   | PUBLIC_HIGHLIGHT_CB
//   | PUBLIC_REMOVE_HIGHLIGHT_CB
//   | PUBLIC_UPDATE_CB
//   | PUBLIC_UPDATE_SITE
//   | PUBLIC_UPDATE_PAGE
//   | ADMIN_NAVIGATE_CB
//   | ADMIN_NAVIGATE_PAGE
//   | ADMIN_NOTIFY_PUBLIC_READY
//   | PUBLIC_NOTIFY_CHILD_READY;

export interface SiteUpdateMessageData {
  type: SiteUpdateMessageTypes;
  siteData?: Site;
  pageData?: Page;
  content?: TContentBlock | TContentBlock[];
  slug?: string;
  cbId?: number | string;
  pageId?: number;
  theme?: SiteTheme;
}

export interface SiteUpdateMessageEvent extends MessageEvent {
  data: SiteUpdateMessageData;
}
