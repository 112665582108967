import { Component, Inject } from '@angular/core';
import { ModalRef, DS_MODAL_DATA } from '@ds/core';
import { BlogConfigTag } from '@ds/interfaces';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'ds-cb-video-modal',
  template: ` <video width="490" height="282" controls>
    <source [src]="source" type="video/mp4" />
    Your browser does not support the video tag.
  </video>`,
  styles: [``],
})
export class ContentBlockBlogVideoModalComponent {
  constructor(
    private sanitizer: DomSanitizer,
    private modalRef: ModalRef<string>,
    @Inject(DS_MODAL_DATA)
    public data: {
      url: string;
    }
  ) {}

  get source() {
    return (
      this.data &&
      this.data.url &&
      this.sanitizer.bypassSecurityTrustResourceUrl(this.data.url)
    );
  }
}
