import { Injectable } from '@angular/core';
import { AuthResponse, Session, User } from '@ds/interfaces';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { first, map, tap } from 'rxjs/operators';
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private _session = new BehaviorSubject<Session>(null);

  get session() {
    return this._session.getValue();
  }
  /**
   * Filters null and auto completes so
   * no need to unsubscribe.
   */
  get session$() {
    return this._session
      .asObservable()
      .pipe(first((session) => session !== null));
  }

  constructor(private api: ApiService) {}

  requestToken(props: {
    emailAddress: string;
    smsNumber: string;
  }): Observable<AuthResponse> {
    return this.api.post('request-login', props);
  }

  submitToken(token: string): Observable<AuthResponse> {
    return this.api.post('login', { token });
  }

  logout(): Observable<AuthResponse> {
    return this.api.post('logout');
  }

  getSession() {
    return this.api.getOne<Session>(`session`).pipe(
      tap((session) => {
        if (window['TrackJS']) {
          window['TrackJS'].configure({
            userId: session.profile.emailAddress,
          });
          window['TrackJS'].addMetadata('role', session.profile.role);
          console.log('[AppEffects] setting TrackJS to user');
        }
        this._session.next(session);
      })
    );
  }

  isLoggedIn(): Observable<boolean> {
    if (this.session) {
      return of(true);
    }
    return this.getSession().pipe(map(() => true));
  }

  impersonate(user: User) {
    return this.api.post(`/session/impersonate/${user.id}`);
  }

  unimpersonate() {
    return this.api.post(`/session/unimpersonate`);
  }
}
