import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { ExtraPaginatedResults, Page } from '@ds/interfaces';
import { BlogSearchParams } from './content-block-blog.service';

@Component({
  selector: 'ds-cb-blog-browse',
  template: `
    <div class="ds-col" *ngIf="entries">
      <div class="ds-row">
        <div class="ds-col-12">
          <ds-cb-blog-active-filters *ngIf="params" [params]="params">
          </ds-cb-blog-active-filters>
        </div>

        <div
          class="ds-col-12 d-flex justify-content-center justify-content-md-start"
        >
          <ds-cb-pagination
            [currentPage]="entries.currentPage"
            [totalPages]="entries.totalPages"
            [previousPageRoute]="previousPage"
            [nextPageRoute]="nextPage"
          ></ds-cb-pagination>
        </div>

        <div class="ds-col-12 entry-list-grid">
          <ds-cb-blog-card
            *ngFor="
              let entry of entries.results;
              trackBy: trackByFn;
              let _index = index;
              let _first = first
            "
            [class.first-entry]="_first"
            [pageSlug]="slug"
            [entry]="entry"
          ></ds-cb-blog-card>
        </div>
        <div
          class="ds-col-12 d-flex justify-content-center justify-content-md-start"
        >
          <ds-cb-pagination
            [currentPage]="entries.currentPage"
            [totalPages]="entries.totalPages"
            [previousPageRoute]="previousPage"
            [nextPageRoute]="nextPage"
          ></ds-cb-pagination>
        </div>
      </div>
    </div>
  `,
  styles: [
    `
      :host {
        display: block;
      }
      .entry-list-grid {
        display: grid;
        grid-gap: 10px;
        grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
        grid-template-rows: 500px;
        grid-auto-rows: 400px;
      }
      .pagination-bottom {
        margin-bottom: 25px;
      }
      .first-entry {
        grid-column-start: span 2;
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ContentBlockBlogBrowseComponent {
  @Input() entries: ExtraPaginatedResults<Page>;
  @Input() params: BlogSearchParams;
  @Input() slug: string;
  @Input() previousPage: any[];
  @Input() nextPage: any[];

  trackByFn(index, model) {
    return model.id;
  }

  blogCardClass(currentPage: number, index: number) {
    return `entry-${currentPage}-${index}`;
  }
}
