import { Injectable } from '@angular/core';
import * as interfaces from '@ds/interfaces';
import { PaginatedQuery, PaginatedResults, DISABLED } from '@ds/interfaces';
import { Observable, of } from 'rxjs';
import { ApiService } from './api.service';

export interface UserFindAllOptions extends PaginatedQuery {
  id?: string | number | string[] | number[];
  disabled?: boolean;
  hasInventory?: boolean;
  locationId?: number | number[];
  locationCity?: string;
}

// generic variables loaded at top to make using
// this as a template easy. Can be removed / replaced
const resource = 'users';
type ModelType = interfaces.User;

@Injectable({
  providedIn: 'root',
})
export class UserService {
  constructor(private api: ApiService) {}

  findAll(params?: UserFindAllOptions) {
    return this.api.getPaginated<ModelType>(resource, { ...params });
  }

  findOne(id: number | string): Observable<ModelType> {
    return this.api.get(`${resource}/${id.toString()}`);
  }

  create(model: ModelType): Observable<ModelType> {
    return this.api.post(resource, model);
  }

  update(model: ModelType): Observable<ModelType> {
    return this.api.put(`${resource}/${model.id.toString()}`, model);
  }

  destroy(model: ModelType): Observable<ModelType> {
    return this.api.delete(`${resource}/${model.id.toString()}`);
  }

  updateIcon(
    model: ModelType,
    icon: interfaces.ApiFile
  ): Observable<ModelType> {
    return this.api.put(`${resource}/${model.id.toString()}/icon`, {
      ...icon,
    });
  }

  destroyIcon(model: ModelType): Observable<ModelType> {
    return this.api.delete(`${resource}/${model.id.toString()}/icon`);
  }

  disable(model: ModelType): Observable<ModelType> {
    return of({
      ...model,
      role: DISABLED,
    });
    // return this.api.put(`${resource}/${model.id.toString()}/disable`, {});
  }

  transferContacts(
    from: ModelType | number,
    to: ModelType | number,
    contactIds: number | number[]
  ): Observable<Boolean> {
    const fromId = typeof from === 'number' ? from : from.id;
    const toId = typeof to === 'number' ? to : to.id;
    return of(true);
    // return this.api.put(`${resource}/transfer-contacts`, {
    //   fromId,
    //   toId,
    //   contactIds
    // });
  }

  getExportUrl(type: string, params: UserFindAllOptions) {
    return this.api.getUrl(resource, {
      ...params,
      export: type,
    });
  }
}
