import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { InventoryConfigService, InventoryService } from '@ds/api-services';
import { filterNullOrUndefined } from '@ds/core';
import {
  Inventory,
  InventoryFindAllOptions,
  Page,
  PublicRouteParams,
} from '@ds/interfaces';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import {
  debounceTime,
  distinctUntilChanged,
  first,
  map,
  switchMap,
  tap,
} from 'rxjs/operators';
import { ContentBlockPageService } from '../../services/content-block-page.service';

/**
 * Pagination Note
 * We keep query param "?page=1" and the UX the same. Despite the fact
 * that the server start pagination at ZERO. So when the user see's page
 * 2, we are actually asking for page 1 from the server. And since page 1
 * is root, we filter that out when generating links
 */

interface QueryParams {
  type?: string;
  body?: string;
  sleeper?: string;
  engine?: string;
  trantype?: string;
  transpeed?: string;
}

const newUsedMap = {
  new: true,
  used: false,
};

export interface InventorySearchParams {
  make?: string;
  series?: string;
  model?: string;
  type?: string;
  body?: string;
  sleeper?: string;
  engine?: string;
  tran?: string;
  tranSpeed?: string;
  sort?: string;
  order?: string;
  physicalLocationId?: number[];
  q?: string;
  page?: number;
  limit?: number;
}

const queryParamKeys = [
  'type',
  'body',
  'sleeper',
  'engine',
  'tran',
  'tranSpeed',
  'sort',
  'q',
  'page',
];

@UntilDestroy()
@Injectable()
export class ContentBlockInventoryService {
  private defaultSearchParams: InventorySearchParams = {
    limit: 12, // divisible by 2, 3, 4 for frontend ux
    page: 1,
  };

  private pageInventoryFilters: InventoryFindAllOptions = {};

  public inventoryPageSlug: string;
  public pageData: Page;

  public invConfig$ = this.invConfigService.findAllPublic().pipe(first());

  routeParams = this.route.params as Observable<PublicRouteParams>;

  public isBrowsing$: BehaviorSubject<boolean> = new BehaviorSubject(null);
  public showSearch$: BehaviorSubject<boolean> = new BehaviorSubject(null);
  public routeParams$: BehaviorSubject<InventorySearchParams> =
    new BehaviorSubject(null);

  isLegacySite = this.cbPageService.isLegacySite;

  public inventory$ = this.routeParams$.pipe(
    map((params) => ({
      ...this.defaultSearchParams,
      ...params,
    })),
    switchMap((params) =>
      this.inventoryService.findAllPublic({
        category: null,
        manufacturer: params.make,
        manufacturerModel: params.model,
        manufacturerSeries: params.series,
        manufacturerYear: null,
        isNew: params.type ? newUsedMap[params.type.toLowerCase()] : null,
        sleeperType: params.sleeper,
        bodyType: params.body,
        engineMake: params.engine,
        transmissionSpeed: params.tranSpeed,
        transmissionType: params.tran,
        q: params.q,
        limit: params.limit || 25,
        page: +params.page > 0 ? params.page - 1 : 0,
        physicalLocationId: params.physicalLocationId,
        sortProp: params.sort,
        sortOrder: params.order,
        ...this.pageInventoryFilters,
      })
    ),
    filterNullOrUndefined()
  );

  currentInventoryItem$ = this.routeParams.pipe(
    map((params) => {
      // if (params.slug !== this.inventoryPageSlug) {
      //   return null;
      // }

      if (params.p1 !== 'view') {
        return null;
      }

      return params.p2.split('-').pop();
    }),
    filterNullOrUndefined(),
    distinctUntilChanged(),
    switchMap((id) => this.inventoryService.findOne(id)),
    tap((item) => {
      this.cbPageService.setTitleAndStuff({
        title: item.displayName,
        imageHandle: item.defaultPhotoHandle,
        description: `${item.displayName}: ${item.props.publicDescription}`,
      });
    })
  );

  constructor(
    private inventoryService: InventoryService,
    private invConfigService: InventoryConfigService,
    private router: Router,
    private route: ActivatedRoute,
    private cbPageService: ContentBlockPageService // private log: Logger
  ) {
    combineLatest([this.route.data, this.route.params])
      .pipe(debounceTime(0), untilDestroyed(this))
      .subscribe(
        () => {
          console.log(
            '[Inventory Service] Loading: ',
            this.route.snapshot.params.slug
          );

          const params = this.route.snapshot.params as PublicRouteParams;
          const pageData: Page = this.route.snapshot.data.pageData;

          this.inventoryPageSlug = params.slug;
          this.pageData = pageData;

          if (pageData && pageData.config && pageData.config.inventoryFilter) {
            this.pageInventoryFilters = pageData.config.inventoryFilter;
          }

          this.isBrowsing$.next(
            params.slug === this.inventoryPageSlug && params.p1 !== 'view'
          );

          this.showSearch$.next(!pageData.config.inventoryHideSearch);

          this.updateRouteParams(params);
        },
        () => {},
        () => {
          this.isBrowsing$.complete();
          this.showSearch$.complete();
          this.routeParams$.complete();
        }
      );
  }

  private updateRouteParams(params: PublicRouteParams) {
    if (params.slug !== this.inventoryPageSlug) {
      return null;
    }

    if (params.p1 === 'view') {
      return null;
    }

    const filteredQueryParams: InventorySearchParams = queryParamKeys.reduce(
      (a, c) => {
        if (params[c]) {
          a[c] = params[c];
        }
        return a;
      },
      {}
    );

    const p = {
      ...(params.p1 && { make: params.p1 }),
      ...(params.p2 && { series: params.p2 }),
      ...(params.p3 && { model: params.p3 }),
      ...filteredQueryParams,
    };

    this.storeParams(p);
    const title = [p.make, p.series, p.model].filter((i) => !!i);
    if (title.length) {
      this.cbPageService.setTitleAndStuff({
        title: title.join(' '),
      });
    } else {
      this.cbPageService.setTitleAndStuff();
    }

    this.routeParams$.next(p);
  }

  updateStore(updatedParams: InventorySearchParams) {
    const paths = this.generateRoutesFromStore(updatedParams);
    return this.router.navigate(paths);
  }

  generateRoutesFromStore(updatedParams: InventorySearchParams = {}) {
    const slug = this.inventoryPageSlug;
    const params = {
      ...this.currentParams,
      ...updatedParams,
    };
    const { make, series, model } = params;
    const queryParams = queryParamKeys.reduce((a, c) => {
      if (params[c]) {
        a[c] = params[c];
      }
      return a;
    }, {});

    return ['/', slug, make, series, model, queryParams].filter((p) => !!p);
  }

  get currentParams() {
    return this.cbPageService.getInventoryParams() || {};
  }

  storeParams(params: InventorySearchParams) {
    this.cbPageService.storeInventoryParams(params);
  }

  resetSearch() {
    this.cbPageService.storeInventoryParams({});
  }

  setDefaultSearchParams(params: InventorySearchParams) {
    this.defaultSearchParams = {
      ...this.defaultSearchParams,
      ...params,
    };
  }

  resetDefaultSearchParams(params: InventorySearchParams = {}) {
    this.defaultSearchParams = params;
  }

  nextPage() {
    const page = (+this.currentParams.page || 1) + 1;
    return this.generateRoutesFromStore({ page });
  }

  previousPage() {
    // Page 1 should not get a query param, so if the current page is "2", then
    // we will just return null. See note about pagination at top of page.
    const page =
      +this.currentParams.page <= 2 ? null : +this.currentParams.page - 1;
    return this.generateRoutesFromStore({ page });
  }
}
