import { Component, ChangeDetectionStrategy, Input } from '@angular/core';
import {
  HTMLContentBlock,
  QuoteContentBlock,
  SimpleTextContentBlock,
} from '@ds/interfaces';

@Component({
  selector: 'ds-cb-simple-text',
  template: `
    <p
      [class]="fluidTextSizeClass"
      [style.text-align]="textAlign"
      [style.font-weight]="fontWeight"
    >
      {{ text }}
      <ng-content></ng-content>
    </p>
  `,
  styles: [
    `
      blockquote {
        position: relative;
        text-align: right;
      }
      .quote-icon {
        float: left;
        width: 50px;
        height: 50px;
      }
      footer {
        margin-top: 15px;
        color: var(--ds-cb-quote-footer-color);
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ContentBlockSimpleTextComponent {
  @Input() text: string;
  @Input() fluidTextSize: string;
  @Input() color: string;
  @Input() textAlign = 'left';
  @Input() fontWeight = 'normal';

  _block: SimpleTextContentBlock;

  @Input()
  set block(val: SimpleTextContentBlock) {
    this.init(val);
  }

  get fluidTextSizeClass() {
    return this.fluidTextSize
      ? `ds-fluid-text-${this.fluidTextSize}`
      : 'ds-fluid-text';
  }

  init(block: SimpleTextContentBlock) {
    this._block = block;
    this.text = block.config.text;
    this.color = block.config.color;
    this.fluidTextSize = block.config.fluidTextSize;
    this.textAlign = block.config.textAlign || 'left';
    this.fontWeight = block.config.fontWeight || 'normal';
  }
}
