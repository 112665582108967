import { Injectable } from '@angular/core';
import { TContentBlock, Page, Site, PageMaps } from '@ds/interfaces';
import { BehaviorSubject, Observable } from 'rxjs';
import { distinctUntilChanged, tap, pluck, map } from 'rxjs/operators';
import { ContentBlockWebsiteEditService } from './content-block-website-edit.service';

function sortContentFunc(a: Partial<TContentBlock>, b: Partial<TContentBlock>) {
  return (a.position || 0) - (b.position || 0);
}

function mergeData(c: TContentBlock, data: PageMaps): TContentBlock {
  return {
    ...c,
    linkToPage: c.linkToPageId ? data.linkToPageMap[c.linkToPageId] : null,
    websiteContact: c.websiteContactId
      ? data.websiteContactMap[c.websiteContactId]
      : null,
    location: c.locationId ? data.locationMap[c.locationId] : null,
    inventoryItem: c.inventoryId ? data.inventoryMap[c.inventoryId] : null,
  };
}

export interface State {
  page: Page;
  content: TContentBlock[];
  highlightedCB?: TContentBlock['id'];
}

const initState: State = {
  page: null,
  content: [],
};

@Injectable()
export class ContentBlockDataService {
  store = new BehaviorSubject<State>(initState);

  changes = this.store.asObservable().pipe(distinctUntilChanged());

  content = this.changes.pipe(pluck('content'));

  highlightedCb = this.changes.pipe(pluck('highlightedCB'));

  get state() {
    return this.store.value;
  }

  setState(state: State) {
    this.store.next(state);
  }

  setContent(content: TContentBlock[]) {
    this.store.next({
      ...this.state,
      content: content.sort(sortContentFunc),
    });
  }

  setHighlighted(id: TContentBlock['id']) {
    this.store.next({
      ...this.state,
      highlightedCB: id,
    });
  }

  get isLegacySite() {
    return !this.state || !this.state.page || this.state.page.siteId === 1;
  }

  constructor(private wsDataMsg: ContentBlockWebsiteEditService) {}

  registerContent(page: Page) {
    this.setState({
      page: page,
      content: page.publishedContent
        ? page.publishedContent
            // .map((c) => mergeData(c, page.dataMaps))
            .sort(sortContentFunc)
        : [],
    });
  }

  getChildren<T extends TContentBlock>(
    id: TContentBlock['id']
  ): Observable<T[]> {
    return this.content.pipe(
      map((children: T[]) => children.filter((c) => c.parentId === id))
    );
  }

  update(content: TContentBlock | TContentBlock[]) {
    const state = this.state;
    if (Array.isArray(content)) {
      if (content[0].pageId !== state.page.id) {
        return;
      }
      const filterIds = content.map((c) => c.id);
      this.setContent([
        ...state.content.filter((c) => !filterIds.includes(c.id)),
        ...content,
      ]);
    } else {
      if (content.pageId !== state.page.id) {
        return;
      }

      this.setContent([
        ...state.content.filter((c) => c.id !== content.id),
        content,
      ]);
    }
  }

  add(content: TContentBlock | TContentBlock[]) {
    if (Array.isArray(content)) {
      if (content[0].pageId !== this.state.page.id) {
        return;
      }
      this.setContent([...this.state.content, ...content]);
    } else {
      if (content.pageId !== this.state.page.id) {
        return;
      }

      this.setContent([...this.state.content, content]);
    }
  }

  remove(content: TContentBlock | TContentBlock[]) {
    if (Array.isArray(content)) {
      if (content.length === 0) {
        return;
      }
      if (content[0].pageId !== this.state.page.id) {
        return;
      }
      const oldIds = content.map((c) => c.id);
      this.setContent(this.state.content.filter((c) => !oldIds.includes(c.id)));
    } else {
      if (content.pageId !== this.state.page.id) {
        return;
      }
      this.setContent(this.state.content.filter((c) => c.id !== content.id));
    }
  }

  selectContentBlock(block: TContentBlock) {
    if (!this.wsDataMsg.isEditing) {
      return false;
    }
    this.wsDataMsg.adminNavigateCb(this.state.page.slug, block.id);
    return true;
  }

  updatePage(page: Page) {
    this.store.next({
      ...this.state,
      page,
    });
  }

  savePage(pageId: number) {
    if (pageId !== this.state.page.id) {
      return;
    }
    // this gets page data and then
    this.wsDataMsg.adminSavePage({
      ...this.store.value.page,
      publishedContent: this.store.value.content,
    });
  }
}
