import { Injectable } from '@angular/core';
import {
  Inventory,
  InventoryPhoto,
  PaginatedQuery,
  InventoryFindAllOptions,
} from '@ds/interfaces';
import { Observable } from 'rxjs';
import { ApiService } from './api.service';

const resource = 'inventory';

@Injectable({
  providedIn: 'root',
})
export class InventoryService {
  constructor(private api: ApiService) {}

  findAllPublic(params?: InventoryFindAllOptions) {
    return this.api.getPaginated<Inventory>(resource, { ...params });
  }

  findAll(params?: InventoryFindAllOptions) {
    return this.api.getPaginated<Inventory>(resource, {
      ...params,
      isAdmin: true,
    });
  }

  findOne(id: number | string) {
    return this.api.get<Inventory>(`${resource}/${id.toString()}`);
  }

  create(model: Inventory, dryRun = false): Observable<Inventory> {
    return this.api.post(`${resource}?dryRun=${dryRun}`, model);
  }

  update(model: Inventory, dryRun = false): Observable<Inventory> {
    return this.api.put(
      `${resource}/${model.id.toString()}?dryRun=${dryRun}`,
      model
    );
  }

  destroy(model: Inventory): Observable<Inventory> {
    return this.api.delete(`${resource}/${model.id.toString()}`);
  }

  email(params: {
    recipients: string[];
    htmlContent: string;
    inventoryId: number;
    subject: string;
  }): Observable<Inventory> {
    return this.api.post(`/emails/inventory-details`, params);
  }

  setDefaultPhoto(
    model: Inventory,
    inventoryPhoto: InventoryPhoto
  ): Observable<Inventory> {
    return this.api.put(
      `${resource}/${model.id.toString()}/default-photo/${inventoryPhoto.id.toString()}`,
      {}
    );
  }

  getExportUrl(type: string, params: InventoryFindAllOptions) {
    return this.api.getUrl(resource, {
      ...params,
      export: type,
      isAdmin: true,
    });
  }
}
