import {
  Component,
  OnInit,
  Input,
  ChangeDetectionStrategy,
} from '@angular/core';
import {
  WebsiteContact,
  WebsiteContactsContentBlock,
  ContactCardContentBlock,
  ContactCardType,
} from '@ds/interfaces';
import { ContentBlockDataService } from '../../services/content-block-data.service';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
  selector: 'ds-cb-website-contacts',
  template: `
    <ds-cb-contact-card
      *ngFor="let contact of _contacts | async; trackBy: trackByFn"
      [type]="type"
      [contact]="contact"
    ></ds-cb-contact-card>
  `,
  styles: [
    `
      :host {
        display: grid;
        grid-gap: 10px;
        grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ContentBlockWebsiteContactsComponent {
  @Input() set contacts(contacts: WebsiteContact[]) {
    this._contacts = of(contacts);
  }

  @Input() type: ContactCardType = 'default';

  _block: WebsiteContactsContentBlock;
  @Input()
  set block(val: WebsiteContactsContentBlock) {
    this.init(val);
  }

  _contacts: Observable<WebsiteContact[]>;

  constructor(private cbDataService: ContentBlockDataService) {}

  init(block: WebsiteContactsContentBlock) {
    this._block = block;
    this._contacts = block.content
      ? of(block.content.map((i) => i.websiteContact))
      : this.cbDataService
          .getChildren(block.id)
          .pipe(
            map((c: ContactCardContentBlock[]) =>
              c.map((i) => i.websiteContact)
            )
          );
  }

  trackByFn(index, model) {
    return model.id;
  }
}
