/**
 * This service is used when a page (or subpage) is loaded to apply all the title and meta data updates
 */

import { Injectable } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { PublicInventoryConfig } from '@ds/api-services';
import { filterNullOrUndefined, ImageLightboxService } from '@ds/core';
import { Page, PublicRouteParams, Site } from '@ds/interfaces';
import { BehaviorSubject, merge, Observable } from 'rxjs';
import {
  distinctUntilKeyChanged,
  map,
  publishReplay,
  refCount,
  take,
  tap,
} from 'rxjs/operators';
import { BlogSearchParams } from '../components/content-block-blog/content-block-blog.service';
import { InventorySearchParams } from '../components/content-block-inventory/content-block-inventory.service';

export interface MetaPageProps {
  title?: string;
  imageHandle?: string;
  description?: string;
  siteName?: string;
  titleBarBackgroundPosition?: string;
  showImageInTitleBar?: boolean;
}

@Injectable()
export class ContentBlockPageService {
  routeParams$ = this.route.params as Observable<PublicRouteParams>;
  routeData$ = this.route.data as Observable<{
    pageData: Page;
    siteData: Site;
  }>;

  siteData: Site = null;

  updatedPageData: BehaviorSubject<Page> = new BehaviorSubject(null);
  updatedPageData$ = this.updatedPageData
    .asObservable()
    .pipe(filterNullOrUndefined());

  private defaultPageProps: MetaPageProps = {};

  private _pageTitleBarConfig$: BehaviorSubject<{
    title?: string;
    imageHandle?: string;
    titleBarBackgroundPosition?: string;
    showImageInTitleBar?: boolean;
  }> = new BehaviorSubject({});

  get pageTitleBarConfig$() {
    return this._pageTitleBarConfig$
      .asObservable()
      .pipe(filterNullOrUndefined());
  }

  _pageData$: Observable<Page> = this.routeData$.pipe(
    map((data) => data.pageData),
    filterNullOrUndefined(),
    distinctUntilKeyChanged('id')
  );

  pageData$: Observable<Page> = merge(
    this._pageData$,
    this.updatedPageData$
  ).pipe(
    tap((p) => {
      this.defaultPageProps = {
        title: p.title,
        imageHandle: p.config.imageHandle,
        description: p.config.description,
        titleBarBackgroundPosition: p.config.titleBarBackgroundPosition,
        showImageInTitleBar: p.config.showImageInTitleBar,
      };
      this.setTitleAndStuff();
    }),
    publishReplay(1),
    refCount()
  );

  get isLegacySite() {
    return this.siteData.id === 1;
  }

  private inventoryParamsState: {
    [key: string]: InventorySearchParams;
  } = {};
  private inventoryConfigState: {
    [key: string]: PublicInventoryConfig;
  } = {};

  private blogParamsState: {
    [key: string]: BlogSearchParams;
  } = {};

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private titleService: Title,
    private metaService: Meta,
    private imageService: ImageLightboxService
  ) {
    this.routeData$
      .pipe(
        map((data) => data.siteData),
        filterNullOrUndefined(),
        take(1)
      )
      .subscribe((siteData) => {
        this.siteData = siteData;
        this.metaService.addTags([
          {
            property: 'og:site_name',
            content: siteData.title,
          },
        ]);
      });

    this.metaService.addTags([
      {
        property: 'og:title',
        content: '',
      },
      {
        property: 'og:image',
        content: '',
      },
      {
        name: 'description',
        content: '',
      },
      {
        property: 'og:description',
        content: '',
      },
      {
        property: 'og:local',
        content: 'en-US',
      },
    ]);
  }

  storeInventoryParams(params: InventorySearchParams) {
    this.inventoryParamsState = {
      ...this.inventoryParamsState,
      [this.route.snapshot.params['slug']]: params,
    };
  }

  getInventoryParams() {
    return this.inventoryParamsState[this.route.snapshot.params['slug']];
  }

  storeBlogParams(params: BlogSearchParams) {
    this.blogParamsState = {
      ...this.blogParamsState,
      [this.route.snapshot.params['slug']]: params,
    };
  }

  getBlogParams() {
    return this.blogParamsState[this.route.snapshot.params['slug']];
  }

  setTitleAndStuff(_props: MetaPageProps = {}) {
    const props = {
      ...this.defaultPageProps,
      ..._props,
    };

    this.titleService.setTitle(`${props.title} | ${this.siteData.title}`);

    this._pageTitleBarConfig$.next({
      title: props.title,
      imageHandle: props.imageHandle,
      titleBarBackgroundPosition: props.titleBarBackgroundPosition,
      showImageInTitleBar: props.showImageInTitleBar,
    });

    // Make sure any tags are updating have be added first
    // above
    // reference for updating tags https://www.concretepage.com/angular/angular-meta-service-for-meta-tags

    this.metaService.updateTag({
      property: 'og:title',
      content: props.title,
    });
    this.metaService.updateTag({
      property: 'og:image',
      content: this.imageService.getUrl(props.imageHandle),
    });
    this.metaService.updateTag({
      name: 'description',
      content: props.description && props.description.substr(0, 200),
    });
    this.metaService.updateTag({
      property: 'og:description',
      content: props.description && props.description.substr(0, 200),
    });
  }
}
