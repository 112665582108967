import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  HostBinding,
  Inject,
  Input,
  OnDestroy,
  OnInit,
  PLATFORM_ID,
} from '@angular/core';
import { SectionContentBlock, SectionSliderContentBlock } from '@ds/interfaces';
import { Observable, of } from 'rxjs';
import { ContentBlockDataService } from '../../services/content-block-data.service';
import { map } from 'rxjs/operators';
import { isPlatformBrowser } from '@angular/common';
import { ContentBlockWebsiteEditService } from '../../services/content-block-website-edit.service';

@Component({
  selector: 'ds-cb-section-slider',
  template: `
    <ds-cb
      *ngFor="let block of content | async; let i = index; trackBy: trackByFn"
      [block]="block"
      [class.active]="i === activeIndex"
    ></ds-cb>
    <div class="ds-cb-sec-slider-nav ds-cb-sec-slider-previous">
      <span
        class="icon"
        inlineSVG="/icons/solid/angle-left.svg"
        role="button"
        (click)="previousSlide()"
      ></span>
    </div>
    <div class="ds-cb-sec-slider-nav ds-cb-sec-slider-next">
      <span
        class="icon"
        inlineSVG="/icons/solid/angle-right.svg"
        (click)="nextSlide()"
      ></span>
    </div>
  `,
  styles: [
    `
      :host {
        display: block;
        position: relative;
        width: 100%;
      }
      ds-cb {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        display: none;
      }
      ds-cb.active {
        display: block;
      }
      :host-context(.is-editing) ds-cb.active {
        display: none;
      }
      :host-context(.is-editing) ds-cb:first-of-type {
        display: block;
      }
      :host-context(.is-editing) ds-cb.is-editing-child,
      :host-context(.is-editing) ds-cb.is-editing-active {
        display: block;
      }
      :host ::ng-deep ds-cb > ds-cb-section {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
      }
      .ds-cb-sec-slider-nav {
        position: absolute;
        display: flex;
      }
      .ds-cb-sec-slider-nav .icon {
        height: 50px;
        width: 50px;
        display: inline-block;
        cursor: pointer;
        opacity: 0.5;
      }
      .ds-cb-sec-slider-nav .icon:hover {
        opacity: 1;
      }
      .ds-cb-sec-slider-previous {
        left: 0;
        top: 50%;
        transform: translateY(-50%);
      }
      .ds-cb-sec-slider-next {
        right: 0;
        top: 50%;
        transform: translateY(-50%);
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ContentBlockSectionSliderComponent
  implements OnDestroy, AfterViewInit
{
  @Input() height: number;
  @Input() heightUnits: '%' | 'px' | 'vh';
  @Input() timing = 5;
  content?: Observable<SectionContentBlock[]> = of([]);

  activeIndex = 0;

  @HostBinding('style.min-height')
  get sectionHeight(): string {
    if (!this.heightUnits || !this.height) {
      return '20px';
    }
    return `${this.height}${this.heightUnits}`;
  }

  constructor(
    @Inject(PLATFORM_ID) private platformId: string,
    private cdRef: ChangeDetectorRef,
    private cbDataService: ContentBlockDataService,
    public wsEditService: ContentBlockWebsiteEditService
  ) {}

  @Input()
  set block(val: SectionSliderContentBlock) {
    this.init(val);
  }

  interval: NodeJS.Timeout;

  childCount = 0;

  init(block: SectionSliderContentBlock) {
    this.height = block.config.height;
    this.heightUnits = block.config.heightUnits;
    this.timing = block.config.timing || 5;
    this.content =
      block.content && block.content.length
        ? of(block.content).pipe(map((c) => this.mapContent(c)))
        : this.cbDataService
            .getChildren(block.id)
            .pipe(map((c) => this.mapContent(c as SectionContentBlock[])));
  }

  mapContent(content: SectionContentBlock[]): SectionContentBlock[] {
    this.childCount = content.length;
    return content.map((c) => {
      return {
        ...c,
        config: {
          ...c.config,
          height: null,
          heightUnits: null,
          hideDivider: true,
        },
      };
    });
  }

  trackByFn(index: number, item: SectionContentBlock) {
    return item.id;
  }

  ngAfterViewInit() {
    if (this.wsEditService.isEditing || !isPlatformBrowser(this.platformId)) {
      return;
    }
    this.autoRotate();
  }

  autoRotate() {
    if (this.interval) {
      clearInterval(this.interval);
    }
    this.interval = setInterval(() => {
      if (!this.childCount) {
        this.activeIndex = 0;
      } else if (this.activeIndex < this.childCount - 1) {
        this.activeIndex += 1;
      } else {
        this.activeIndex = 0;
      }
      this.cdRef.markForCheck();
    }, this.timing * 1000);
  }

  ngOnDestroy() {
    if (this.interval) {
      clearInterval(this.interval);
    }
  }

  nextSlide() {
    if (this.activeIndex < this.childCount - 1) {
      this.activeIndex += 1;
    } else {
      this.activeIndex = 0;
    }
    this.autoRotate();
    this.cdRef.markForCheck();
  }

  previousSlide() {
    if (this.activeIndex > 0) {
      this.activeIndex -= 1;
    } else {
      this.activeIndex = this.childCount - 1;
    }
    this.autoRotate();
    this.cdRef.markForCheck();
  }
}
