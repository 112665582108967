import {
  ChangeDetectionStrategy,
  Component,
  Host,
  HostBinding,
  HostListener,
  Input,
} from '@angular/core';
import { CardContentBlock, TContentBlock, TextSize } from '@ds/interfaces';
import { Observable, of } from 'rxjs';
import { ContentBlockDataService } from '../../services/content-block-data.service';
import { DomSanitizer, SafeStyle } from '@angular/platform-browser';
import { ImageLightboxService } from '@ds/core';

@Component({
  selector: 'ds-cb-card',
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: `
    <div class="top-spacer" [style.margin-top.px]="spacerMargin"></div>
    <div class="header" [style.background-color]="headerFooterBackgroundColor">
      <ng-content select="[card-header]"></ng-content>
      <!-- <span *ngIf="header.length">{{ header }}</span> -->
      <ds-cb-header
        *ngIf="header.length"
        [text]="header"
        [textSize]="headerTextSize"
        [textAlign]="headerTextAlign"
        [class.use-default-align]="!headerTextAlign"
        [color]="headerFooterColor"
        [class.has-custom-color]="headerFooterColor"
      ></ds-cb-header>
    </div>
    <div
      class="content"
      *ngIf="showContent"
      [style.background-color]="backgroundColor"
      [style.justify-content]="justifyContent"
    >
      <ng-content></ng-content>
      <div
        class="card-item"
        *ngFor="let block of content | async; trackBy: trackByFn"
      >
        <ds-cb [block]="block"></ds-cb>
      </div>
    </div>
    <div class="footer" [style.background-color]="headerFooterBackgroundColor">
      <ng-content select="[card-footer]"></ng-content>
      <ds-cb-header
        *ngIf="footer.length"
        [text]="footer"
        [textSize]="footerTextSize"
        [textAlign]="footerTextAlign"
        [class.use-default-align]="!footerTextAlign"
        [color]="headerFooterColor"
        [class.has-custom-color]="headerFooterColor"
      ></ds-cb-header>
    </div>

    <div class="bottom-spacer" [style.margin-bottom.px]="spacerMargin"></div>
  `,
  styles: [
    `
      :host {
        position: relative;
        display: block;
        width: 100%;
        border-radius: 3px;
        display: flex;
        flex-direction: column;
        overflow: hidden;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        background-color: var(--ds-cb-card-background-color);
      }
      :host(.fill-height) {
        height: 100%;
      }
      :host(.border) {
        border: 1px solid var(--ds-cb-card-border-color);
        border-radius: 3px;
      }
      :host(.border) .card-item:not(:last-child) {
        border-bottom: 1px solid var(--ds-cb-card-border-color);
      }

      .card-inner-box {
        display: flex;
        flex-direction: column;
        height: 100%;
      }

      .header:empty,
      .footer:empty {
        display: none;
      }
      .header,
      .footer {
        width: 100%;
        font-weight: bold;
        background-color: var(--ds-cb-card-header-footer-background-color);
        padding: 10px 5px;
      }
      
      :host :host-context(ds-cb-footer)  {
        background-color: var(--ds-cb-footer-card-background-color, var(--ds-cb-card-background-color));
      }

      :host-context(ds-cb-footer) .header,
      :host-context(ds-cb-footer) .footer {
        background-color: var(--ds-cb-footer-card-header-footer-background-color, var(--ds-cb-card-header-footer-background-color));
      }

      
      .use-default-align ::ng-deep h2 {
        text-align: var(--ds-cb-card-header-footer-text-align, left);
      }
      
      :host:not(.has-custom-hf-color) ::ng-deep h2 {
        color: var(--ds-cb-card-header-footer-color) !important;
      }
      
      :host-context(ds-cb-footer) .header:not(.has-custom-hf-color) ::ng-deep h2,
      :host-context(ds-cb-footer) .footer:not(.has-custom-hf-color) ::ng-deep h2 {
        color: var(--ds-cb-footer-card-header-footer-text-color, var(--ds-cb-card-header-footer-color)) !important;
      }

      :host ::ng-deep h2 {
        text-transform: none;
      }

      .header {
        position: absolute;
        bottom: 0;
        width: 100%;
        border-top-right-radius: 3px;
        border-top-left-radius: 3px;
      }
      .footer {
        position: absolute;
        top: 0;
        width: 100%;
        border-bottom-right-radius: 3px;
        border-bottom-left-radius: 3px;
      }
      :host ::ng-deep .card-item,
      .card-item {
        display: block;
        padding: 10px 15px;
        margin-bottom: -1px;
      }
      :host(.compact) .card-item {
        padding: 2px 5px;
      }
      .badge {
        display: inline-block;
        min-width: 10px;
        padding: 3px 7px;
        font-size: 12px;
        font-weight: bold;
        color: #fff;
        line-height: 1;
        vertical-align: baseline;
        white-space: nowrap;
        text-align: center;
        background-color: #999;
        border-radius: 10px;
      }
      .content {
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
      }
      :host(.default) .top-spacer,
      :host(.default) .bottom-spacer,
      :host(.slide-up) .bottom-spacer,
      :host(.slide-down) .top-spacer {
        display: none;
      }

      :host-context(.is-editing-active) .header,
      :host-context(.is-editing-active) .footer,
      :host.hovered .header,
      :host.hovered .footer {
        position: relative;
      }

      :host(.default) .header,
      :host(.default) .footer,
      :host(.slide-up) .footer,
      :host(.slide-down) .header {
        position: relative;
      }

      .top-spacer {
        transition: all.4s cubic-bezier(0.39, 1.02, 0.68, 1);
      }
      .bottom-spacer {
        transition: all.4s cubic-bezier(0.39, 1.02, 0.68, 1);
      }

      :host-context(.is-editing-active) .top-spacer {
        margin-top: 0!important;
      },

      :host-context(.is-editing-active) .bottom-spacer  {
        margin-bottom: 0!important;
      },
    `,
  ],
  animations: [],
})
export class ContentBlockCardComponent {
  @Input() @HostBinding('style.max-height.px') get maxHeight(): number | null {
    if (this.type !== 'default') return this.minHeight;
    return null;
  }

  @Input()
  set backgroundImage(val: string) {
    this.backgroundImageUrl = val ? this.safeBackgroundImage(val) : null;
  }

  @Input()
  set block(val: CardContentBlock) {
    this.init(val);
  }

  get spacerMargin(): number | null {
    return this.hovered ? 0 : this.minHeight;
  }

  @HostBinding('class.has-custom-hf-color')
  get hasCustomHeaderFooterColor(): boolean {
    return !!this.headerFooterColor;
  }

  constructor(
    private cbDataService: ContentBlockDataService,
    private sanitizer: DomSanitizer,
    private imageService: ImageLightboxService
  ) {}

  @Input() header = '';
  @Input() footer = '';
  @Input() content: Observable<TContentBlock[]> = of([]);

  @Input() @HostBinding('class') type: 'default' | 'slide-up' | 'slide-down' =
    'default';

  @Input() showContent = true;

  @Input() @HostBinding('class.border') showBorder = true;
  @Input() @HostBinding('class.compact') compact = true;
  @Input() @HostBinding('style.background-color') backgroundColor = null;

  @Input() @HostBinding('style.min-height.px') minHeight = null;

  @Input() @HostBinding('class.hovered') hovered = false;

  @Input() @HostBinding('class.fill-height') fillHeight = false;

  @HostBinding('style.background-image') backgroundImageUrl: SafeStyle;

  @Input() headerTextAlign: 'left' | 'center' | 'right' | null = null;
  @Input() footerTextAlign: 'left' | 'center' | 'right' | null = null;

  @Input() headerTextSize: TextSize = 'small';
  @Input() footerTextSize: TextSize = 'small';

  @Input() headerFooterColor: string | null;

  @Input() headerFooterBackgroundColor: string | null;

  @Input() justifyContent:
    | 'center'
    | 'flex-start'
    | 'flex-end'
    | 'space-between'
    | 'space-around'
    | 'space-evenly' = 'flex-start';

  @Input() @HostListener('mouseenter') onMouseEnter() {
    if (!this.type || this.type === 'default') return;
    this.hovered = true;
  }
  @Input() @HostListener('mouseleave') onMouseLeave() {
    if (!this.type || this.type === 'default') return;
    this.hovered = false;
  }

  @Input() @HostListener('click') onClick() {
    this.hovered = !this.hovered;
  }

  init(block: CardContentBlock) {
    this.header = block.config.header || '';
    this.footer = block.config.footer || '';
    this.showBorder = block.config.showBorder;
    this.type = block.config.type || 'default';
    this.compact = block.config.compact;
    this.content = block.content
      ? of(block.content)
      : this.cbDataService.getChildren(block.id);
    this.backgroundColor = block.config.backgroundColor;
    this.backgroundImage = block.config.backgroundImage;
    this.minHeight = block.config.minHeight;
    this.headerTextAlign = block.config.headerTextAlign || null;
    this.footerTextAlign = block.config.footerTextAlign || null;
    this.headerFooterColor = block.config.headerFooterColor || null;
    this.headerFooterBackgroundColor =
      block.config.headerFooterBackgroundColor || null;
    this.justifyContent = block.config.justifyContent || 'flex-start';
    this.fillHeight = block.config.fillHeight;
    this.headerTextSize = block.config.headerTextSize || 'small';
    this.footerTextSize = block.config.footerTextSize || 'small';
  }

  trackByFn(index, model) {
    return model.id;
  }

  safeBackgroundImage(imageHandle: string) {
    return imageHandle
      ? this.sanitizer.bypassSecurityTrustStyle(
          `url(${this.imageService.getUrl(imageHandle)})`
        )
      : null;
  }
}
