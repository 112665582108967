import {
  Component,
  Input,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
} from '@angular/core';
import { ColumnContentBlock, TContentBlock } from '@ds/interfaces';
import { ContentBlockDataService } from '../../services/content-block-data.service';
import { Observable, of } from 'rxjs';
import { tap } from 'rxjs/operators';

@Component({
  selector: 'ds-cb-column',
  template: `
    <ds-cb
      *ngFor="let block of content | async; let i = index; trackBy: trackByFn"
      [block]="block"
    ></ds-cb>
  `,
  styles: [
    `
      :host {
        display: block;
        width: 100%;
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ContentBlockColumnComponent {
  content: Observable<TContentBlock[]> = of([]);

  _block: ColumnContentBlock;
  @Input()
  set block(val: ColumnContentBlock) {
    this.init(val);
  }

  constructor(
    private cbDataService: ContentBlockDataService,
    private cd: ChangeDetectorRef
  ) {}

  init(block: ColumnContentBlock) {
    this.content = block.content
      ? of(block.content)
      : this.cbDataService.getChildren(block.id);
    this._block = block;
  }

  trackByFn(index, model) {
    return model.id;
  }
}
