import { Component, Inject } from '@angular/core';
import { ModalRef, DS_MODAL_DATA } from '@ds/core';
import { BlogConfigDate } from '@ds/interfaces';

@Component({
  selector: 'ds-cb-blog-dates-modal',
  template: `
    <div class="dates">
      <a
        href=""
        class="date"
        *ngFor="let date of data.dates"
        [routerLink]="['/', data.slug, { year: date.year, month: date.month }]"
        (click)="dateClicked($event)"
      >
        {{ monthNames[date.month - 1] }}, {{ date.year }}
        <span class="badge badge-pill badge-secondary">{{ date.count }}</span>
      </a>
    </div>
  `,
  styles: [
    `
      .dates {
        border: 1px solid var(--ds-cb-card-border-color);
        width: 100%;
      }
      .date:not(:last-child) {
        border-bottom: 1px solid var(--ds-cb-card-border-color);
      }

      .date {
        padding: 10px 15px;
        margin-bottom: -1px;
        display: flex;
        justify-content: space-between;
        text-decoration: none;
        align-items: flex-start;
      }
    `,
  ],
})
export class ContentBlockBlogDatesModalComponent {
  monthNames = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];

  constructor(
    private modalRef: ModalRef<{ year; month }>,
    @Inject(DS_MODAL_DATA)
    public data: {
      dates: BlogConfigDate[];
      slug: string;
    }
  ) {}

  dateClicked($event) {
    $event.preventDefault();
    this.modalRef.close();
  }
}
