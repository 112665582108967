import {
  ChangeDetectorRef,
  Component,
  Injectable,
  NgZone,
} from '@angular/core';

import { Client, init, PickerFileMetadata, PickerOptions } from 'filestack-js';
import { Observable, of, Subject } from 'rxjs';
import { map, mergeMap } from 'rxjs/operators';

import { UploadService } from '@ds/api-services';
import { filterNullOrUndefined } from '@ds/core';
import { ApiFile } from '@ds/interfaces';
import { FieldType } from '@ngx-formly/material';

function convertFileResponse(file: PickerFileMetadata): ApiFile {
  const { filename, handle, mimetype, size, source, url } = file;
  return {
    filename,
    handle,
    mimetype,
    size,
    source,
    url,
    metadataResponse: file,
  };
}

@Injectable({
  providedIn: 'root',
})
export class ContentBlockFileStackService {
  _fileStackPublicClient: Client;

  get fileStackPublicClient(): Observable<Client> {
    if (this._fileStackPublicClient) {
      return of(this._fileStackPublicClient);
    }

    return this.uploadService.getFormConfig().pipe(
      map((config) => {
        this._fileStackPublicClient = init(config.public.key);
        return this._fileStackPublicClient;
      })
    );
  }

  constructor(private ngZone: NgZone, private uploadService: UploadService) {}

  uploadFile(opts: Partial<PickerOptions> = {}) {
    const uploadDone: Subject<ApiFile[]> = new Subject();
    return this.fileStackPublicClient.pipe(
      mergeMap((client) => {
        client
          .picker({
            ...this.fromDefaults(opts),
            onCancel: () => {
              uploadDone.complete();
            },
            onUploadDone: (resp) => {
              this.ngZone.run(() => {
                const response = resp.filesUploaded.map(convertFileResponse);
                uploadDone.next(response);
              });
            },
          })
          .open()
          .then(void 0)
          .catch((error) => {
            uploadDone.next(error);
            uploadDone.complete();
          });
        return uploadDone;
      }),
      filterNullOrUndefined()
    );
  }

  fromDefaults(opts: Partial<PickerOptions> = {}): PickerOptions {
    const defaults = {
      fromSources: [
        'local_file_system',
        'url',
        'imagesearch',
        'facebook',
        'instagram',
        'googledrive',
        'dropbox',
        'webcam',
        'video',
        'audio',
        'box',
        'github',
        'gmail',
        'picasa',
        'onedrive',
        'onedriveforbusiness',
      ],
      minFiles: 1,
      maxFiles: 1,
      accept: ['image/*', '.pdf', 'text/plain'],
      maxSize: 50 * 1024 * 1024,
      // dropPane: null
    };

    return { ...defaults, ...opts };
  }
}

@Component({
  template: `
    <div>
      <button
        *ngIf="!this.files.length"
        type="button"
        class="ds-button alt"
        (click)="uploadFile($event)"
      >
        Select file
      </button>
      <ul *ngFor="let file of this.files; let i = index">
        <li>
          <span class="badge badge-secondary"
            >{{ file.filename }}
            <a
              class="badge"
              href="javascript: void"
              role="button"
              alt="remove file"
              title="remove file"
              (click)="removeFile($event, i)"
              >( X )</a
            >
          </span>
        </li>
      </ul>
    </div>
  `,
})
export class ContentBlockFileUploadComponent extends FieldType {
  constructor(
    private cbFileStackService: ContentBlockFileStackService,
    private cd: ChangeDetectorRef
  ) {
    super();
  }

  uploadFile(ev: MouseEvent) {
    ev.preventDefault();
    ev.stopPropagation();
    this.cbFileStackService.uploadFile().subscribe((files) => {
      this.formControl.markAsDirty();
      this.formControl.markAsTouched();
      this.formControl.patchValue(files);
      this.cd.markForCheck();
    });
  }

  get files(): ApiFile[] {
    return this.value || [];
  }

  removeFile($event: MouseEvent, index: number) {
    $event.preventDefault();
    const files: ApiFile[] = this.value;
    this.formControl.patchValue(files.filter((v, i) => i !== index));
    this.cd.markForCheck();
  }
}
