import {
  Directive,
  Input,
  HostListener,
  ViewContainerRef,
  ElementRef,
  Injector,
  ComponentFactoryResolver,
  ApplicationRef,
  OnInit,
} from '@angular/core';
import { TemplatePortal, DomPortalOutlet } from '@angular/cdk/portal';
import {
  OverlayRef,
  Overlay,
  OverlayConfig,
  ScrollStrategyOptions,
} from '@angular/cdk/overlay';
import { DsMenuComponent } from './ds-menu.component';
import { Subscription, Subject, combineLatest } from 'rxjs';
import { filter, debounceTime } from 'rxjs/operators';

export const MENU_PANEL_TOP_PADDING = 8;

@Directive({
  selector: '[dsMenuTriggerFor]',
  exportAs: 'dsMenuTrigger',
})
export class DsMenuTriggerDirective implements OnInit {
  @Input() dsMenuTriggerFor: DsMenuComponent;
  @Input() trigger: 'hover' | 'click' = 'hover';
  @Input() mode: 'in-place' | 'overlay';

  menuOpen = false;

  private portal: TemplatePortal;
  private overlayRef: OverlayRef | null = null;
  private closingActionsSubscription = Subscription.EMPTY;
  private portalHost: DomPortalOutlet;

  constructor(
    private element: ElementRef<HTMLElement>,
    private viewContainerRef: ViewContainerRef,
    private overlay: Overlay,
    private scrollStrategies: ScrollStrategyOptions,
    private componentFactoryResolver: ComponentFactoryResolver,
    private injector: Injector,
    private appRef: ApplicationRef
  ) {}

  readonly isHovered: Subject<boolean> = new Subject<boolean>();

  @HostListener('mouseenter')
  handleMouseEnter() {
    this.isHovered.next(true);
  }

  @HostListener('mouseleave')
  handleMouseLeave() {
    this.isHovered.next(false);
  }

  @HostListener('mouseover')
  openMenuHover() {
    if (this.trigger === 'hover') {
      this._openMenu();
    }
  }

  @HostListener('click', ['$event'])
  toggleMenuOnClick($event) {
    if (this.trigger === 'click') {
      $event.stopPropagation();
      $event.preventDefault();
      this._toggleMenu();
    }
  }

  ngOnInit() {
    this.dsMenuTriggerFor.closed.asObservable().subscribe(() => {
      this._closeMenu();
    });
  }

  closeMenu() {
    this.dsMenuTriggerFor.closed.emit();
  }

  private _closeMenu() {
    if (this.menuOpen) {
      if (this.overlayRef) {
        this.overlayRef.detach();
        this.closingActionsSubscription.unsubscribe();
      }
      if (this.portalHost) {
        this.portalHost.detach();
      }
      this.menuOpen = false;
      this.dsMenuTriggerFor.closed.emit();
    }
  }

  private _menuClosingActions() {
    const lostFocus = combineLatest([
      this.isHovered,
      this.dsMenuTriggerFor.isHovered,
    ]).pipe(
      debounceTime(300),
      filter(
        ([menuIsHovered, triggerIsHovered]) =>
          !menuIsHovered && !triggerIsHovered
      )
    );

    return lostFocus;
  }

  private _createOverlay(): OverlayRef {
    return this.overlayRef || this.overlay.create(this._getOverlayConfig());
  }

  private _getOverlayConfig(): OverlayConfig {
    return new OverlayConfig({
      positionStrategy: this.overlay
        .position()
        .flexibleConnectedTo(this.element)
        .withPositions([
          {
            originX: 'start',
            originY: 'bottom',
            overlayX: 'start',
            overlayY: 'top',
          },
        ]),
      backdropClass: 'cdk-overlay-transparent-backdrop',
      scrollStrategy: this.scrollStrategies.block(),
      direction: 'ltr',
    });
  }

  private _getPortal(): TemplatePortal {
    if (
      !this.portal ||
      this.portal.templateRef !== this.dsMenuTriggerFor.templateRef
    ) {
      this.portal = new TemplatePortal(
        this.dsMenuTriggerFor.templateRef,
        this.viewContainerRef
      );
    }

    return this.portal;
  }

  private _toggleMenu() {
    if (!this.menuOpen) {
      this._openMenu();
    } else {
      this.closeMenu();
    }
  }
  private _openMenu() {
    if (!this.menuOpen) {
      const portal = this._getPortal();

      if (this.mode === 'in-place') {
        this.portalHost = new DomPortalOutlet(
          this.dsMenuTriggerFor.portalOutlet.nativeElement,
          this.componentFactoryResolver,
          this.appRef,
          this.injector
        );

        this.portalHost.attach(portal);
      } else {
        this.overlayRef = this._createOverlay();
        this.overlayRef.attach(this._getPortal());
        this.closingActionsSubscription = this._menuClosingActions().subscribe(
          () => this.closeMenu()
        );
      }

      this.menuOpen = true;
    }
  }
}
