import { Component, Input } from '@angular/core';
import { ListContentBlock, TContentBlock } from '@ds/interfaces';
import { Observable, of } from 'rxjs';
import { ContentBlockDataService } from '../../services/content-block-data.service';

@Component({
  selector: 'ds-cb-list',
  template: `
    <ul [ngClass]="layout">
      <li *ngFor="let block of content | async">
        <div class="bullet" inlineSVG="/icons/times-circle.svg"></div>
        <ds-cb [block]="block"></ds-cb>
      </li>
    </ul>
  `,
  styles: [
    `
      :host {
        display: block;
        width: 100%;
      }
      ul {
        display: flex;
        list-style: circle;
      }
      ul.vertical {
        flex-direction: column;
      }
      ul.horizontal {
        flex-direction: row;
        justify-content: space-between;
      }
      li {
        display: flex;
      }
      .vertical > li {
        width: 100%;
      }
      .horizontal > li {
        justify-content: space-between;
      }
      .bullet {
        display: inline-block;
        height: 15px;
        width: 15px;
        margin: 5px 5px 0 0;
      }
    `,
  ],
})
export class ContentBlockListComponent {
  content: Observable<TContentBlock[]> = of([]);
  @Input() layout: string;

  constructor(private cbDataService: ContentBlockDataService) {}

  init(block: ListContentBlock) {
    this.layout = block.config.layout || '';
    this.content = block.content
      ? of(block.content)
      : this.cbDataService.getChildren(block.id);
  }
}
