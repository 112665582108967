import {
  Component,
  Input,
  OnInit,
  HostBinding,
  ChangeDetectionStrategy,
  OnChanges,
  ChangeDetectorRef,
} from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ImageLightboxService } from '@ds/core';
import { MetaPageProps } from '../../services/content-block-page.service';

@Component({
  selector: 'ds-cb-title-bar',
  template: `
    <div
      class="background-image"
      [style.background-image]="safeTitleBackgroundImage"
      [style.background-position]="config.titleBarBackgroundPosition"
      *ngIf="config.showImageInTitleBar"
    ></div>
    <div
      class="title-row"
      [class.ds-has-background-image]="config.showImageInTitleBar"
    >
      <div class="title-background left-column"></div>
      <div class="center-column">
        <div class="center-inner-column">
          <h1 *ngIf="config.title" class="title-background">
            {{ config.title }}
          </h1>
        </div>
      </div>
      <div class="right-column"></div>
    </div>
  `,
  styles: [
    `
      :host {
        display: flex;
        max-width: 100%;
        width: 100%;
        min-height: var(--ds-cb-title-bar-min-height);
        align-items: center;
        position: relative;
        overflow: hidden;
      }
      .background-image {
        position: absolute;
        top: 0;
        background-image: url(https://freightlinerads.azureedge.net/Page%209.jpg);
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
        height: 100%;
        width: 100%;
        filter: blur(4px);
        overflow: hidden;
        transform: scale(1.08);
      }
      .title-row {
        display: flex;
        width: 100%;
      }
      .title-background {
        background-color: var(--ds-cb-title-bar-background-color);
      }
      .left-column,
      .right-column {
        flex: 1 0 auto;
      }
      .center-column {
        max-width: 1000px;
        width: 100%;
        display: flex;
        align-items: center;
      }
      .center-inner-column {
        flex: 1;
        height: 100%;
        display: flex;
        align-items: center;
      }
      :host ::ng-deep h1 {
        font-size: 3.5rem;
        line-height: 3.5rem;
        letter-spacing: 0.1rem;
        font-weight: bold;
        color: var(--ds-cb-title-bar-color);
        text-shadow: 3px 3px var(--ds-cb-title-bar-shadow-color);
        padding-left: 25px;
        padding-right: 50px;
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ContentBlockTitleBarComponent implements OnChanges {
  @Input() config: MetaPageProps = {};

  constructor(
    private sanitizer: DomSanitizer,
    private imageService: ImageLightboxService,
    private cd: ChangeDetectorRef
  ) {}

  get safeTitleBackgroundImage() {
    return this.config.imageHandle
      ? this.sanitizer.bypassSecurityTrustStyle(
          `url(${this.imageService.getUrl(this.config.imageHandle)})`
        )
      : null;
  }

  ngOnChanges(changes): void {
    console.log('*** Changes in titlebar', changes);
    this.cd.markForCheck();
  }
}
