import {
  Component,
  EventEmitter,
  Input,
  Output,
  ChangeDetectionStrategy,
} from '@angular/core';
import { HTMLContentBlock } from '@ds/interfaces';
import { SafeHtml, DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'ds-cb-html',
  template: `
    <div
      class="ds-cb-html"
      [class.has-font-size]="fontSize"
      [style.font-size.px]="fontSize"
      [style.color]="color"
      [style.line-height.px]="lineHeight"
      [innerHTML]="sanitizedHtml"
    ></div>
  `,
  styles: [
    `
      :host {
        display: block;
      }
      :host ::ng-deep .ds-cb-html > p {
        margin-top: 15px;
        margin-left: 0px;
        margin-right: 0px;
      }

      :host ::ng-deep .ds-cb-html > p:first-child {
        margin-top: 0px;
      }

      :host ::ng-deep .ds-cb-html p > strong {
        font-weight: bold;
      }

      :host ::ng-deep .ds-cb-html p > a {
        text-decoration: underline;
      }

      :host ::ng-deep .ds-cb-html > ul {
        list-style: circle;
      }

      :host ::ng-deep .ds-cb-html > ul > li {
        margin-left: 20px;
      }

      :host ::ng-deep .ds-cb-html > p > em {
        margin-left: 20px;
      }

      :host ::ng-deep .ds-cb-html dt {
        font-weight: bold;
      }

      :host ::ng-deep .ds-cb-html dd {
        margin-bottom: 1em;
      }
    `,
    // styles moved to content-block-site
    // the above host contact needs to move because of wysiwg
    //
    // !!! Note this component uses fluid-text.scss !!!
    // ::ng-deep is required because html is injected
    // that the component can not attach the encapsulated hostid
    // `
    //   :host {
    //     display: block;
    //   }
    //   :host ::ng-deep .html > p {
    //     margin-top: 15px;
    //     margin-left: 0px;
    //     margin-right: 0px;
    //   }
    //   :host ::ng-deep .html > p:first-child {
    //     margin-top: 0px;
    //   }
    //   :host ::ng-deep .html p > strong {
    //     font-weight: bold;
    //   }
    //   :host ::ng-deep .html p > a {
    //     text-decoration: underline;
    //   }
    //   :host ::ng-deep .html > ul {
    //     list-style: circle;
    //   }
    //   :host ::ng-deep .html > ul > li {
    //     margin-left: 20px;
    //   }
    //   :host ::ng-deep .html > p > em {
    //     margin-left: 20px;
    //   }
    // `
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ContentBlockHTMLComponent {
  @Input()
  set html(val: string) {
    this.sanitizedHtml = this.sanitizer.bypassSecurityTrustHtml(val);
  }
  @Output() change: EventEmitter<HTMLContentBlock> = new EventEmitter();

  fontSize: number;
  color: string;

  get lineHeight() {
    return this.fontSize ? this.fontSize * 1.25 : null;
  }

  _block: HTMLContentBlock;
  @Input()
  set block(val: HTMLContentBlock) {
    this.init(val);
  }

  sanitizedHtml: SafeHtml;

  constructor(private sanitizer: DomSanitizer) {}

  init(block: HTMLContentBlock) {
    this._block = block;
    this.html = block.config.html;
    this.fontSize = block.config.fontSize;
    this.color = block.config.color;
  }

  get id() {
    return this.block ? this.block.id : null;
  }
}
