import { Injectable } from '@angular/core';
import * as interfaces from '@ds/interfaces';
import { InventoryMake, InventoryModel, InventorySeries } from '@ds/interfaces';
import { BehaviorSubject, Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { ApiService } from './api.service';
import { startCase } from 'lodash';

interface SelectOption {
  value: any;
  label: string | number;
  group?: string;
}

const resource = 'inventory/config';
type InventoryConfig = interfaces.InventoryConfig;
type InventoryConfigFormatted = interfaces.InventoryConfigFormatted;

export function convertList(
  labels: (number | string | null)[] = []
): SelectOption[] {
  return labels.map((value) => ({ value: value, label: value }));
}

function formatSeries(
  series: InventorySeries[],
  makes: InventoryMake[]
): InventorySeries[] {
  return series.map((_series) => {
    return {
      ..._series,
      inventoryMake: makes.find((make) => make.id === _series.inventoryMakeId)
        .label,
    };
  });
}

function formatSeriesOptions(
  series: InventorySeries[],
  makes: InventoryMake[]
): SelectOption[] {
  return formatSeries(series, makes).map(({ label, inventoryMake }) => ({
    value: label,
    label,
    group: inventoryMake,
  }));
}

function formatModels(
  models: InventoryModel[],
  series: InventorySeries[],
  makes: InventoryMake[]
): (InventoryModel & {
  inventoryMakeLabel: string;
  inventorySeriesLabel: string;
})[] {
  return models.map((model) => {
    const _series = series.find((s) => s.id === model.inventorySeriesId);
    return {
      ...model,
      inventorySeriesLabel: _series.label,
      inventoryMakeLabel: makes.find(
        (_make) => _make.id === _series.inventoryMakeId
      ).label,
    };
  });
}

function formatModelsOptions(
  models: InventoryModel[],
  series: InventorySeries[],
  makes: InventoryMake[]
): SelectOption[] {
  return formatModels(models, series, makes).map(
    ({ label, inventorySeriesLabel }) => ({
      value: label,
      label,
      group: inventorySeriesLabel,
    })
  );
}

function getYearRange(years) {
  const currentYear = new Date().getFullYear();
  const endYear = new Date();
  endYear.setFullYear(currentYear - years);
  const result = [];
  let startYear = endYear.getFullYear();
  while (currentYear >= startYear) {
    result.push(startYear++);
  }

  return result;
}

function format(config: InventoryConfig): InventoryConfigFormatted {
  const formatted = {
    steerOptions: [
      { value: '0', label: 'NO' },
      { value: '1', label: 'YES' },
    ],
    yearsOptions: getYearRange(49).map((year) => ({
      value: year,
      label: year,
    })),
    newOrUsedOptions: config.newOrUsed.map((val: any) =>
      val === 'New'
        ? { value: true, label: 'New' }
        : { value: false, label: 'Used' }
    ),
    inventoryMakesOptions: config.inventoryMakes.map((make) => ({
      value: make.label,
      label: make.label,
    })),
    inventorySeries: formatSeries(
      config.inventorySeries,
      config.inventoryMakes
    ),
    inventoryModels: formatModels(
      config.inventoryModels,
      config.inventorySeries,
      config.inventoryMakes
    ),
    inventorySeriesOptions: formatSeriesOptions(
      config.inventorySeries,
      config.inventoryMakes
    ),
    inventoryModelsOptions: formatModelsOptions(
      config.inventoryModels,
      config.inventorySeries,
      config.inventoryMakes
    ),
  };

  return {
    ...config,
    ...formatted,
  };
}

export interface PublicInventorySeriesConfig {
  label: string;
  options: {
    label: string;
    value: string;
  }[];
}

export interface PublicInventoryConfig {
  types: SelectOption[];
  makes: SelectOption[];
  series: PublicInventorySeriesConfig[];
  models: SelectOption[];
  bodyType: SelectOption[];
  sleeperType: SelectOption[];
  engineMake: SelectOption[];
  transmissionType: SelectOption[];
  transSpeeds: SelectOption[];
  sortOptions: SelectOption[];
}

function formatForPublic(config: InventoryConfig): PublicInventoryConfig {
  return {
    types: convertList(['New', 'Used']),
    makes: convertList(config.actualMakes.map((m) => m.label)),
    series: config.actualMakes.map((make) => ({
      label: make.label,
      options: config.actualSeries
        .filter((series) => series.parent === make.label)
        .map((series) => ({
          label: series.label,
          value: [make.label, series.label].join('|||'),
        })),
    })),
    models: config.actualModels.map((model) => {
      const series = config.actualSeries.find((s) => s.label === model.parent);
      const make = config.actualMakes.find((m) => {
        if (series) {
          return m.label === series.parent;
        } else {
          return m.label === model.grandparent;
        }
      });
      return {
        label: model.label,
        value: [make.label, series?.label, model.label],
      };
    }),
    // models: formatModels(
    //   config.inventoryModels,
    //   config.inventorySeries,
    //   config.inventoryMakes
    // ),
    bodyType: convertList(config.actualBodyTypes),
    sleeperType: convertList(config.actualSleeperTypes),
    engineMake: convertList(config.actualEngineMakes),
    transmissionType: convertList(config.actualTransmissionTypes),
    transSpeeds: convertList(config.actualTransmissionSpeeds),
    sortOptions: convertList([
      'price',
      'manufacturerYear',
      'soldAt',
      'physicalLocation.name',
      'stockNumber',
      'vin',
      'mileage',
      'createdAt',
      'updatedAt',
    ]).map((option) => ({
      label: startCase(option.label.toString()),
      value: option.value,
    })),
  };
}

@Injectable({
  providedIn: 'root',
})
export class InventoryConfigService {
  constructor(private api: ApiService) {}

  private _config = new BehaviorSubject<InventoryConfig>(null);
  private _configFormatted = new BehaviorSubject<InventoryConfigFormatted>({});

  get config() {
    return this._config.getValue();
  }

  get configFormatted(): InventoryConfigFormatted {
    return this._configFormatted.getValue();
  }

  findAll() {
    return this.api.get<InventoryConfig>(resource).pipe(
      tap((results) => this._config.next(results)),
      tap((results) => this._configFormatted.next(format(results)))
    );
  }

  findAllPublic(): Observable<PublicInventoryConfig> {
    return this.api
      .get<InventoryConfig>(resource)
      .pipe(map((results) => formatForPublic(results)));
  }
}
