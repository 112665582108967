import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Inventory } from '@ds/interfaces';

@Component({
  selector: 'ds-cb-inventory-card',
  template: `
    <a [routerLink]="['/', slug, 'view', item.slug]">
      <div class="inventory-image">
        <img
          dsImage
          [imageHandle]="defaultImageHandle"
          [alt]="item.displayName"
          imageSize="medium"
        />
      </div>
      <div class="just-listed" *ngIf="justListed"></div>
      <!-- <div class="stock-number">{{ item.stockNumber }}</div> -->
      <h4 class="ds-fluid-text display-name">
        <span>{{ item.manufacturerYear }}</span>
        <span class="manufacturer-label">{{ item.manufacturer }}</span>
        <span>{{ item.manufacturerModel }}</span>
      </h4>
      <dl class="ds-fluid-text" *ngIf="isLegacy">
        <ng-container *ngFor="let field of displayFields">
          <dt>{{ field.label }}</dt>
          <dd [innerHTML]="field.value"></dd>
        </ng-container>
      </dl>
      <dl class="ds-fluid-text" *ngIf="!isLegacy">
        <dt>Price</dt>
        <dd>{{ item.price | currency }}</dd>
        <dt>Miles</dt>
        <dd>{{ item.props.mileage | number }}</dd>
      </dl>
    </a>
  `,
  styles: [
    `
      :host {
        position: relative;
        display: block;
        border-radius: 4px;
        max-width: 100%;
        padding: 4px;
        background-color: var(--ds-cb-card-background-color, white);
        box-shadow: 0 1px 2px rgba(0, 0, 0, 0.3);
        transition: box-shadow 0.3s ease-in-out;
      }
      :host:hover {
        box-shadow: 0 5px 15px rgba(0, 0, 0, 0.5);
      }
      .display-name {
        white-space: nowrap;
        display: flex;
        max-width: 100%;
        text-align: center;
        justify-content: center;
      }
      .manufacturer-label {
        overflow: hidden;
        text-overflow: ellipsis;
        margin: 0 5px;
      }
      a {
        display: flex;
        flex-direction: column;
        text-decoration: none;
        color: var(--ds-text-color);
      }

      .inventory-image {
        min-height: 150px;
        margin: -4px -4px 10px -4px;
      }
      .inventory-image img {
        background-color: var(--ds-cb-image-background-color);
        border-color: var(--ds-cb-image-border-color);
        max-width: 100%;
        min-height: 150px;
        max-height: 150px;
        height: 150px;
        object-fit: cover;
        vertical-align: middle;
        width: 100%;
      }
      dt {
        float: left;
        clear: left;
        width: 60px;
        text-align: right;
        font-weight: bold;
        opacity: 0.75;
      }
      dt::after {
        content: ':';
      }
      dd {
        margin: 0 0 0 70px;
      }
      .stock-number {
        font-weight: bold;
        display: inline-block;
        position: absolute;
        top: 8px;
        left: 8px;
        background: rgba(255, 255, 255, 0.5);
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;
        border-bottom-right-radius: 5px;
        border-bottom-left-radius: 5px;
        padding: 2px;
      }
      .just-listed {
        background-image: url(/assets/images/just_listed_ribbon.png);
        background-size: contain;
        background-repeat: no-repeat;
        background-position: top right;
        position: absolute;
        top: -3px;
        right: -3px;
        width: 50%;
        height: 50%;
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ContentBlockInventoryCardComponent {
  @Input() item: Inventory;
  @Input() slug: string;
  @Input() isLegacy = true;

  get defaultImageHandle() {
    if (this.item.isReadonly) {
      return (
        this.item.props.foreignImageUrls && this.item.props.foreignImageUrls[0]
      );
    }
    return this.item.defaultPhotoHandle;
  }

  get displayFields(): {
    label: string;
    value: string;
  }[] {
    return [
      {
        label: 'Engine',
        value: this.item.props.engineMake,
      },
      {
        label: 'Price',
        value: `$${this.item.price || 0}`,
      },
      {
        label: 'Miles',
        value: `${this.item.props.mileage || 0}`,
      },
      {
        label: 'Trans',
        value: `${this.item.props.transmissionMake} ${this.item.props.transmissionSpeed} Spd`,
      },
      {
        label: 'GVW',
        value: this.item.props.GVWR,
      },
      {
        label: 'Wheel',
        value: [
          'Base:',
          this.item.props.wheelBase,
          '<br>',
          'Fifth:',
          this.item.props.fifthWheelType,
          '<br>',
          'Make:',
          this.item.props.fifthWheelMake,
        ].join(''),
      },
    ];
  }

  get justListed() {
    return this.item.daysAdded < 14;
  }
}
