import { Injectable } from '@angular/core';
import { BlogConfig, Page, PageTypes, PaginatedQuery } from '@ds/interfaces';
import { Observable } from 'rxjs';
import { ApiService } from './api.service';

export interface PageFindAllOptions extends PaginatedQuery {
  siteId?: string | number;
  select?: string[];
  type?: PageTypes | PageTypes[];
  tag?: string | string[];
  year?: string | number;
  month?: string | number;
  date?: string;
  includeUnpublished?: boolean;
}

const resource = 'pages';

@Injectable({
  providedIn: 'root',
})
export class PageService {
  constructor(private api: ApiService) {}

  findAll(params?: PageFindAllOptions) {
    return this.api.getPaginated<Page>(resource, { ...params });
  }

  findOne(slugOrId: string | number, siteId?: number): Observable<Page> {
    const params = siteId ? { siteId } : null;
    return this.api.get(`/pages/${slugOrId}`, params);
  }

  updatePage(page: Page, siteId: number) {
    return this.api.put(`${resource}/${page.id.toString()}`, page, {
      params: { siteId },
    });
  }

  create(model: Page): Observable<Page> {
    return this.api.post(resource, model);
  }

  blogConfig() {
    return this.api.get<BlogConfig>('blog-config', {
      pageType: PageTypes.BLOG_ENTRY,
    });
  }

  destroy(model: Page, siteId: number): Observable<Page> {
    return this.api.delete(`${resource}/${model.id.toString()}`, null, {
      params: { siteId },
    });
  }
}

const AMPERSAND_REGEX = /&/gi;
const SPECIAL_CHARS_REGEX = /[^\w\d\s-]/gi;
const SPACES_REGEX = /\s/gi;
const SPECIAL_CHARS_REGEX_START = /^([^\w\d]*)/gi;
const SPECIAL_CHARS_REGEX_END = /([^\w\d]*)$/gi;
const SPECIAL_CHARS_SUBSTITUTION = '-';

export function toSlug(v: string): string {
  return v
    .toLowerCase()
    .replace(SPECIAL_CHARS_REGEX_START, '')
    .replace(SPECIAL_CHARS_REGEX_END, '')
    .replace(AMPERSAND_REGEX, 'and')
    .replace(SPECIAL_CHARS_REGEX, '')
    .replace(SPACES_REGEX, SPECIAL_CHARS_SUBSTITUTION)
    .replace(
      new RegExp(`${SPECIAL_CHARS_SUBSTITUTION}+`, 'g'),
      SPECIAL_CHARS_SUBSTITUTION
    );
}
