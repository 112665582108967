import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { ApiService } from './api.service';
import { WebsiteEmailEntry } from '@ds/interfaces';

const resource = 'website-email-entries';

@Injectable({
  providedIn: 'root',
})
export class WebsiteEmailEntryService {
  constructor(private api: ApiService) {}

  findAll() {}

  findOne() {}

  create(entry: WebsiteEmailEntry) {
    return this.api.post<WebsiteEmailEntry>(resource, entry);
  }

  delete() {}
}
