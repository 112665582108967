import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

import { ImageLightboxService, ImageSizes, ModalService } from '@ds/core';
import {
  HourTypes,
  RetailLocation,
  RetailLocationHours,
  WebsiteEmailEntry,
  WebsiteEmailEntryType,
} from '@ds/interfaces';

import { ContentBlockContactFormComponent } from '../content-block-contact-card/content-block-contact-form.component';

@Component({
  selector: 'ds-cb-location-detail-two',
  template: `
    <ng-container *ngIf="location">
      <ds-cb-section
        height="300"
        heightUnits="px"
        [cbSectionTitle]="location.name"
        titlePosition="top-left"
        [hideDivider]="true"
        [backgroundImage]="location.defaultPhotoHandle"
        *ngIf="location.defaultPhotoHandle"
      >
        <div class="ds-row"></div>
      </ds-cb-section>
      <ds-cb-section
        backgroundColor="#626262"
        [hideDivider]="true"
        [cbSectionTitle]="location.defaultPhotoHandle ? null : location.name"
        titlePosition="top-left"
      >
        <div class="ds-row">
          <div class="ds-col-sm-6">
            <div class="ds-row justify-content-center">
              <ul class="ds-fluid-text-lg ds-text-center">
                <li *ngFor="let phoneNumber of location.phoneNumbers">
                  <span class="phone-label"
                    >{{
                      phoneNumber.type.toLocaleUpperCase().replace('_', ' ')
                    }}:</span
                  >
                  <a href="{{ 'tel:' + phoneNumber.number }}" class="phone">{{
                    phoneNumber.number
                  }}</a>
                </li>
              </ul>
            </div>
            <div class="ds-row justify-content-center">
              <button
                class="ds-button email-us"
                (click)="emailLocation($event)"
              >
                <span class="icon" [inlineSVG]="'/icons/envelope.svg'"></span
                >Email Us
              </button>
            </div>
            <div class="addr-divider"></div>
            <div class="ds-row ds-text-center justify-content-center">
              <address class="ds-fluid-text-lg">
                {{ location.address.addressLineOne }} <br />
                {{ location.address.addressLineTwo }}
                <br
                  *ngIf="
                    location.address.addressLineTwo &&
                    location.address.addressLineTwo.trim().length
                  "
                />
                {{ location.address.city }}, {{ location.address.state }}
                <br />
                {{ location.address.postalCode }}
              </address>
            </div>
            <div class="ds-row justify-content-center">
              <a
                *ngIf="
                  location.config.googleMapsLink &&
                  location.config.googleMapsLink.length
                "
                [href]="location.config.googleMapsLink"
              >
                <span class="icon" [inlineSVG]="'/icons/map.svg'"></span>Google
                Maps</a
              >
            </div>
            <div class="addr-divider"></div>
            <div class="ds-row justify-content-center">
              <a [href]="towingLink" target="_blank">Towing Recommendations</a>
            </div>
          </div>
          <div class="ds-col-sm-6">
            <ds-cb-card class="map-card">
              <iframe
                style="width: 100%; min-height: 350px;"
                [src]="embedLink"
              ></iframe>
            </ds-cb-card>
          </div>
        </div>
      </ds-cb-section>
      <ds-cb-section backgroundColor="#626262" [hideDivider]="true">
      </ds-cb-section>
      <ds-cb-section
        hideDivider="true"
        cbSectionTitle="Hours"
        titlePosition="top-left"
      >
        <ds-cb></ds-cb>
        <div
          class="ds-row ds-row-centered justify-content-center"
          style="justify-content: center;"
        >
          <div
            class="ds-col-sm-4"
            *ngFor="let item of location.hoursOfOperation"
          >
            <ds-cb-card [header]="item.label" headerTextSize="large">
              <ul class="ds-fluid-text" style="margin-bottom: 15px;">
                <li
                  *ngFor="let day of [0, 1, 2, 3, 4, 5, 6]; index as i"
                  [class.active-day]="i === today"
                >
                  {{ weekDays[i] }}:
                  {{ getHours(item.hours[i]) }}
                </li>
              </ul>
              <p style="margin-bottom: 15px" *ngIf="item.disclaimer">
                {{ item.disclaimer }}
              </p>
            </ds-cb-card>
          </div>
        </div>
      </ds-cb-section>
      <ds-cb-section
        hideDivider="true"
        cbSectionTitle="Services and Amenities"
        titlePosition="top-left"
        backgroundColor="#626262"
      >
        <!-- <ds-cb-link
          *ngIf="parentSlug"
          [route]="[parentSlug]"
          text="Back To List"
        ></ds-cb-link> -->
        <ds-cb></ds-cb>
        <div
          class="ds-row ds-row-centered justify-content-center"
          style="justify-content: center;"
        >
          <div class="ds-col-sm-6">
            <ds-cb-card
              header="Services"
              headerTextSize="large"
              *ngIf="location.services && location.services.length"
            >
              <ul class="services ds-fluid-text">
                <li *ngFor="let service of location.services">
                  <span class="icon" [inlineSVG]="'/icons/cog.svg'"></span
                  >{{ service }}
                </li>
              </ul>
            </ds-cb-card>
          </div>
          <div class="ds-col-sm-6">
            <ds-cb-card header="Amenities" headerTextSize="large">
              <ul class="amenities ds-fluid-text">
                <li *ngFor="let item of location.amenities">
                  <span class="icon" [inlineSVG]="'/icons/cog.svg'"></span
                  >{{ item }}
                </li>
                <!--
                <li>
                  <span class="icon" [inlineSVG]="'/icons/cog.svg'"></span
                  >Drivers' lounge
                </li>
                <li>
                  <span class="icon" [inlineSVG]="'/icons/cog.svg'"></span>Free
                  water and coffee
                </li>
                <li>
                  <span class="icon" [inlineSVG]="'/icons/cog.svg'"></span>Free
                  Wi-Fi
                </li>
                <li>
                  <span class="icon" [inlineSVG]="'/icons/cog.svg'"></span
                  >Satellite tv
                </li>
                <li>
                  <span class="icon" [inlineSVG]="'/icons/cog.svg'"></span
                  >Vending machines
                </li>
                <li>
                  <span class="icon" [inlineSVG]="'/icons/cog.svg'"></span>Hotel
                  shuttle
                </li>
                <li>
                  <span class="icon" [inlineSVG]="'/icons/cog.svg'"></span>List
                  of hotels, restaurants and taxis
                </li>
                <li>
                  <span class="icon" [inlineSVG]="'/icons/cog.svg'"></span>Non
                  smoking facility
                </li>
                <li>
                  <span class="icon" [inlineSVG]="'/icons/cog.svg'"></span
                  >Trailer parking
                </li> -->
              </ul>
            </ds-cb-card>
          </div>
        </div>
      </ds-cb-section>
      <!-- <ds-cb-section>
        <div class="ds-row map" *ngIf="embedLink">
          <div class="ds-col">
            <ds-cb-card class="map-card">
              <iframe
                style="width: 100%; min-height: 350px;"
                [src]="embedLink"
              ></iframe>
            </ds-cb-card>
          </div>
        </div>
      </ds-cb-section> -->
      <ds-cb-section
        *ngIf="location"
        cbSectionTitle="We can help"
        titlePosition="top-left"
        [hideDivider]="true"
      >
        <ds-cb></ds-cb>
        <ds-cb-website-contacts
          [contacts]="location.websiteContacts"
          type="square"
        ></ds-cb-website-contacts>
      </ds-cb-section>
    </ng-container>
  `,
  styles: [
    `
      ds-cb-card:not(:last-child) {
        margin-bottom: 16px;
      }

      .active-day {
        font-weight: bold;
      }

      address {
        font-style: normal;
      }

      .ds-button {
        margin-top: 4px;
      }

      .icon {
        width: 16px;
        display: inline-block;
        margin-right: 4px;
      }

      ul.services .icon,
      ul.amenities .icon {
        width: 12px;
        color: rgb(217, 83, 79);
      }

      :host ::ng-deep ds-cb-card:not(.map-card) .content {
        padding: 10px 15px;
      }

      :host-context(.small) .map {
        display: none;
      }

      .contact-card a {
        text-decoration: none;
      }

      .ds-row-centered {
        display: flex;
        flex-wrap: wrap;
        margin-right: -15px;
        margin-left: -15px;
        justify-content: center;
      }
      .addr-divider {
        margin: 25px;
        border: 1px solid var(--ds-cb-section-divider-color);
        opacity: 0.5;
      }

      .phone-label {
        min-width: 125px;
        display: inline-block;
        text-align: left;
      }

      .email-us {
        width: 250px;
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ContentBlockLocationDetailTwoComponent {
  @Input()
  location: RetailLocation;

  @Input()
  parentSlug: string;

  weekDays = [
    'Sunday',
    'Monday',
    'Tuesday',
    'Wednesday',
    'Thursday',
    'Friday',
    'Saturday',
  ];

  today = new Date().getDay();

  get towingLink() {
    return this.location?.config?.towingLink || 'https://bit.ly/TCCTowing';
  }

  constructor(
    private modalService: ModalService,
    private sanitizer: DomSanitizer
  ) {}

  emailLocation($event: MouseEvent) {
    $event.preventDefault();
    this.modalService.open<void, WebsiteEmailEntry>(
      ContentBlockContactFormComponent,
      {
        type: WebsiteEmailEntryType.LOCATION,
        locationId: this.location.id,
        location: this.location,
      }
    );
  }

  getHours(hours: RetailLocationHours): string {
    if (!hours || hours.type === HourTypes.CLOSED) {
      return 'Closed';
    } else if (hours.type === HourTypes['24H']) {
      return '24Hr';
    } else if (hours.type === HourTypes.BY_APPOINTMENT) {
      return 'By Appointment';
    }
    return `${hours.openTime}${hours.openPeriod} - ${hours.closedTime}${hours.closedPeriod}`;
  }

  get embedLink() {
    if (!this.location.config || !this.location.config.googleMapsEmbed) {
      return;
    }
    return this.sanitizer.bypassSecurityTrustResourceUrl(
      this.location.config.googleMapsEmbed
    );
  }
}
