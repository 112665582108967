import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  OnDestroy,
  ChangeDetectorRef,
  OnInit,
} from '@angular/core';
import {
  PublicInventoryConfig,
  PublicInventorySeriesConfig,
} from '@ds/api-services';
import { SelectOption } from '@ds/interfaces';
import { InventorySearchParams } from './content-block-inventory.service';
import { Subject } from 'rxjs';
import {
  debounceTime,
  distinctUntilChanged,
  map,
  takeUntil,
} from 'rxjs/operators';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';

@Component({
  selector: 'ds-cb-inventory-search-form',
  template: `
    <ds-cb-card [showBorder]="false">
      <div card-header (click)="toggleSearchOptions()">Search Options</div>
      <div *ngIf="showSearchOptions">
        <ds-cb-inventory-search-form-select
          label="New & Used"
          [options]="config?.types"
          [value]="params.type"
          (valueUpdate)="changeTriggered('type', $event)"
        >
        </ds-cb-inventory-search-form-select>
        <ds-cb-inventory-search-form-select
          label="Makes"
          [options]="config?.makes"
          [value]="params.make"
          (valueUpdate)="makeChanged($event)"
        >
        </ds-cb-inventory-search-form-select>
        <ds-cb-inventory-search-form-select
          label="Series"
          [groupedOptions]="series"
          [value]="params.series"
          (valueUpdate)="seriesChanged($event)"
        >
        </ds-cb-inventory-search-form-select>
        <ds-cb-inventory-search-form-select
          label="Models"
          [options]="models"
          [value]="params.model"
          (valueUpdate)="modelChanged($event)"
        >
        </ds-cb-inventory-search-form-select>
        <ds-cb-inventory-search-form-select
          label="Body Types"
          [options]="config?.bodyType"
          [value]="params.body"
          (valueUpdate)="changeTriggered('body', $event)"
        >
        </ds-cb-inventory-search-form-select>
        <ds-cb-inventory-search-form-select
          label="Sleeper Types"
          [options]="config?.sleeperType"
          [value]="params.sleeper"
          (valueUpdate)="changeTriggered('sleeper', $event)"
        >
        </ds-cb-inventory-search-form-select>
        <ds-cb-inventory-search-form-select
          label="Engine Types"
          [options]="config?.engineMake"
          [value]="params.engine"
          (valueUpdate)="changeTriggered('engine', $event)"
        >
        </ds-cb-inventory-search-form-select>
        <ds-cb-inventory-search-form-select
          label="Transmission Types"
          [options]="config?.transmissionType"
          [value]="params.tran"
          (valueUpdate)="changeTriggered('tran', $event)"
        >
        </ds-cb-inventory-search-form-select>
        <ds-cb-inventory-search-form-select
          label="Transmission Speeds"
          [options]="config?.transSpeeds"
          [value]="params.tranSpeed"
          (valueUpdate)="changeTriggered('tranSpeed', $event)"
        >
        </ds-cb-inventory-search-form-select>
        <div style="display: flex">
          <input
            class="form-control"
            placeholder="Stock Number / Vin"
            [value]="params.q || ''"
            #searchInput
          />
          <button
            class="ds-button"
            style="height: 38px; margin: 5px;"
            (click)="searchInput$.next(searchInput.value)"
          >
            Search
          </button>
        </div>
      </div>
    </ds-cb-card>

    <ds-cb-card [showBorder]="false">
      <div card-header (click)="toggleSortResults()">Sort Results</div>
      <div *ngIf="showSortResults">
        <ds-cb-inventory-search-form-select
          label="Select One"
          [options]="config?.sortOptions"
          [value]="sortOrder"
          (valueUpdate)="changeTriggered('sort', $event)"
        >
        </ds-cb-inventory-search-form-select>
      </div>
    </ds-cb-card>
  `,
  styles: [
    `
      ds-cb-inventory-search-form-select {
        padding: 5px;
      }
      input.form-control {
        margin: 5px;
      }

      ds-cb-card:not(:last-child) {
        margin-bottom: 8px;
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ContentBlockInventorySearchFormComponent
  implements OnInit, OnChanges, OnDestroy
{
  @Input() params: InventorySearchParams = {};
  @Input() config: PublicInventoryConfig;

  @Output() update: EventEmitter<Partial<InventorySearchParams>> =
    new EventEmitter();

  makes: string[] = [];
  series: PublicInventorySeriesConfig[];
  transSpeeds: string[];
  models: SelectOption[];
  sortOptions: SelectOption[];

  searchInput$ = new Subject<string>();

  showSearchOptions = true;
  showSortResults = true;
  isMobile: boolean;
  private onDestroy$ = new Subject();

  constructor(
    private breakpointObserver: BreakpointObserver,
    private cdRef: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this.searchInput$
      .pipe(
        debounceTime(500),
        distinctUntilChanged(),
        takeUntil(this.onDestroy$)
      )
      .subscribe((val) => {
        this.changeTriggered('q', val);
      });

    this.breakpointObserver
      .observe([Breakpoints.Medium, Breakpoints.Small, Breakpoints.XSmall])
      .pipe(
        map((result) => result.matches),
        takeUntil(this.onDestroy$)
      )
      .subscribe((isMobile) => {
        this.isMobile = isMobile;
        if (!this.isMobile) {
          this.showSearchOptions = this.showSortResults = true;
          this.cdRef.markForCheck();
        }
      });
  }

  ngOnChanges() {
    if (this.config) {
      this.setupSeriesAndModels();
    }

    if (this.params === null) {
      this.params = {};
    }
  }

  ngOnDestroy() {
    this.onDestroy$.next();
  }

  setupSeriesAndModels() {
    this.series = this.config.series.filter((make) =>
      this.params.make ? make.label === this.params.make : true
    );

    this.models = this.config.models
      .filter((model) => {
        if (!this.params.make && !this.params.series) {
          return true;
        }

        if (this.params.series && this.params.series !== model.value[1]) {
          return false;
        }

        if (this.params.make && this.params.make !== model.value[0]) {
          return false;
        }

        return true;
      })
      .map((model) => ({
        label: model.label,
        value: model.value.join('|||'),
      }));
  }

  emitChange(props) {
    this.update.emit({
      ...props,
      page: null,
    });

    if (props.sort) {
      this.toggleSortResults();
    } else {
      this.toggleSearchOptions();
    }
  }

  changeTriggered(field: string, value: string) {
    this.emitChange({ [field]: value.length ? value : null, page: null });
  }

  makeChanged(value: string) {
    this.emitChange({
      make: value.length ? value : null,
      series: null,
      model: null,
    });
  }

  seriesChanged(value: string) {
    if (value.length === 0) {
      this.emitChange({
        series: null,
        model: null,
      });
      return;
    }

    const val = value.split('|||');
    this.emitChange({
      make: val[0],
      series: val[1],
      model: null,
    });
  }

  modelChanged(value: string) {
    if (value.length === 0) {
      this.emitChange({
        model: null,
      });
      return;
    }
    const val = value.split('|||');
    this.emitChange({
      make: val[0],
      series: val[1],
      model: val[2],
    });
  }

  get sortOrder() {
    return this.params.sort || 'stockNumber';
  }

  toggleSearchOptions() {
    if (this.isMobile) {
      this.showSearchOptions = !this.showSearchOptions;
    }
  }

  toggleSortResults() {
    if (this.isMobile) {
      this.showSortResults = !this.showSortResults;
    }
  }
}
