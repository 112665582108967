import { Pipe, PipeTransform } from '@angular/core';
/*
 * prints out '[ None ]' or specified placeholder when no content exists.
 */
@Pipe({ name: 'none' })
export class NonePipe implements PipeTransform {
  transform(
    value: string | number | boolean,
    placeholder: string | number = 'none'
  ): string | number | boolean {
    if (value === null || value === undefined) {
      return `~ ${placeholder} ~`;
    }
    if (typeof value === 'string' && value.length === 0) {
      return `~ ${placeholder} ~`;
    }
    return value;
  }
}
