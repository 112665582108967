import { Component, ChangeDetectionStrategy, Input } from '@angular/core';
import { QuoteContentBlock } from '@ds/interfaces';

@Component({
  selector: 'ds-cb-block-quote',
  template: `
    <blockquote [attr.cite]="citeUrl" class="ds-fluid-text">
      <div
        class="quote-icon"
        inlineSVG="/icons/solid/quote-left.svg"
        role="button"
      ></div>
      <p class="ds-fluid-text-xl">{{ text }}</p>
      <footer class="ds-fluid-text-sm">— {{ author }}</footer>
    </blockquote>
  `,
  styles: [
    `
      blockquote {
        position: relative;
        text-align: right;
      }
      .quote-icon {
        float: left;
        width: 50px;
        height: 50px;
      }
      footer {
        margin-top: 15px;
        color: var(--ds-cb-quote-footer-color);
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ContentBlockQuoteComponent {
  @Input() text: string;
  @Input() author: string;
  @Input() citeUrl: string;
  @Input()
  set block(val: QuoteContentBlock) {
    this.init(val);
  }

  init(block: QuoteContentBlock) {
    this.text = block.config.text;
    this.author = block.config.author;
    this.citeUrl = block.config.citeUrl;
  }
}
