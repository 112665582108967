import { Inventory } from './inventory.interface';
import { WebsiteContact } from './website-contact.interface';
import { RetailLocation } from './location.interface';

export enum WebsiteEmailEntryType {
  LOCATION = 'LOCATION',
  INVENTORY = 'INVENTORY',
  CONTACT = 'CONTACT',
}

export interface WebsiteEmailEntryAnswers {
  name?: string;
  emailAddress?: string;
  phoneNumber?: string;
  comments?: string;
  stockNumber?: string;
}

export interface WebsiteEmailEntry {
  id?: number;
  orgId?: number;
  createdByUserId?: number;
  updatedByUserId?: number;
  locationId?: number;
  inventoryId?: number;
  websiteContactId?: number;

  type: WebsiteEmailEntryType;
  answers?: WebsiteEmailEntryAnswers;

  location?: Partial<RetailLocation>;
  inventoryItem?: Partial<Inventory>;
  websiteContact?: Partial<WebsiteContact>;
}
