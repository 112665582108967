import {
  Component,
  OnInit,
  Input,
  ChangeDetectionStrategy,
} from '@angular/core';
import { Page } from '@ds/interfaces';

@Component({
  selector: 'ds-cb-blog-card',
  template: `
    <a [routerLink]="['/', pageSlug, entry.slug]">
      <figure>
        <div class="blog-image">
          <img dsImage [imageHandle]="entry.config.imageHandle" />
        </div>
        <div class="blog-info">
          <figcaption class="ds-fluid-text">
            <h3>{{ entry.title }}</h3>
            <span [innerHTML]="description"></span>
          </figcaption>
          <div class="click-for-more">Read More →</div>
          <div class="divider"></div>
          <div class="author">
            <img
              class="author-avatar"
              dsImage
              [imageHandle]="entry.websiteContact.photoHandle"
              imageSize="small"
            />
            <div>
              <div>
                <strong>{{ entry.publishedOn | date }}</strong
                ><br />
                <strong> {{ entry.websiteContact.name }} </strong>
                <span class="author-title">{{
                  entry.websiteContact.title
                }}</span>
              </div>
            </div>
          </div>
        </div>
      </figure>
    </a>

    <div class="ds-tags">
      <a
        href=""
        [routerLink]="['./', { tag: tag.tag }]"
        *ngFor="let tag of entry.tags"
        class="ds-tag"
      >
        <span class="icon" [inlineSVG]="'/icons/tag.svg'"></span
        >{{ tag.tag }}</a
      >
    </div>
  `,
  styles: [
    `
      :host {
        position: relative;
        display: flex;
        flex-direction: column;
        border-radius: 4px;
        max-width: 100%;
        background-color: white;
        box-shadow: 0 1px 2px rgba(0, 0, 0, 0.3);
        transition: box-shadow 0.3s ease-in-out;
      }

      figure {
        height: 100%;
        display: flex;
        flex-direction: column;
      }
      :host:hover {
        box-shadow: 0 5px 15px rgba(0, 0, 0, 0.5);
      }
      :host > a {
        text-decoration: none;
        color: var(--ds-text-color);
        flex-grow: 1;
        overflow: hidden;
        padding: 25px;
      }
      .blog-image {
        margin: -25px -25px 25px -25px;
      }
      .blog-image img {
        background-color: var(--ds-cb-image-background-color);
        border-color: var(--ds-cb-image-border-color);
        max-width: 100%;
        min-height: 150px;
        max-height: 150px;
        height: 150px;
        object-fit: cover;
        vertical-align: middle;
        width: 100%;
      }

      .blog-info {
        flex-grow: 1;
        display: flex;
        flex-direction: column;
        overflow: hidden;
      }

      .blog-info .ds-fluid-text {
        flex-grow: 1;
        overflow: hidden;
        overflow-text: ellipse;
      }
      figcaption {
      }
      .click-for-more {
        color: var(--ds-link-color);
      }
      .divider {
        width: 100%;
        display: block;
        border-bottom: 1px solid var(--ds-cb-section-divider-color);
      }
      .blog-info > div:not(.author),
      figcaption {
        margin-bottom: 15px;
      }

      .author {
        display: flex;
        align-items: center;
        margin-bottom: 0;
      }

      .author-avatar {
        width: 40px;
        height: 40px;
        border-radius: 50%;
        margin-right: 10px;
        object-fit: cover;
      }
      .author-title {
        white-space: nowrap;
        overflow: hidden;
        overflow-text: ellipse;
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ContentBlockBlogCardComponent implements OnInit {
  @Input() pageSlug = '';
  @Input() entry: Page;

  constructor() {}

  ngOnInit() {}

  get description() {
    return this.entry.config.description.substr(0, 250);
  }
}
