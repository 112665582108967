import { WebsiteContact } from './website-contact.interface';
import { Tag } from './tag.interface';
import { Inventory } from './inventory.interface';
import { RetailLocation } from './location.interface';
import { DsForm } from './form.interface';
import { InventoryFindAllOptions } from './inventory-search.interface';

export enum ImageFitOptions {
  clip = 'clip',
  crop = 'crop',
  scale = 'scale',
  max = 'max',
}

export enum PageTypes {
  'PAGE_CONTENT' = 'PAGE_CONTENT',
  'PAGE_INDEX' = 'PAGE_INDEX',
  'INVENTORY' = 'INVENTORY',
  'BLOG_INDEX' = 'BLOG_INDEX',
  'BLOG_ENTRY' = 'BLOG_ENTRY',
  'LOCATION_INDEX' = 'LOCATION_INDEX',
  'LOCATION_DETAIL' = 'LOCATION_DETAIL',
  'REDIRECT' = 'REDIRECT',
  'SITE_FOOTER' = 'SITE_FOOTER',
  'STAFF_BROWSER' = 'STAFF_BROWSER',
}

export enum CbSizes {
  'Quarter' = '3',
  'Third' = '4',
  'Half' = '6',
  'TwoThirds' = '8',
  'ThreeQuarters' = '9',
}

export interface PageMaps {
  linkToPageMap?: { [key: number]: Page };
  websiteContactMap?: { [key: number]: WebsiteContact };
  locationMap?: { [key: number]: RetailLocation };
  inventoryMap?: { [key: number]: Inventory };
}

export interface Page extends PageMaps {
  orgId?: number;
  id?: number;
  slug?: string;
  siteId?: number;
  title?: string;
  icon?: string;
  navOrder?: number;
  type?: PageTypes;
  dataMaps?: PageMaps;
  config: {
    description?: string;
    fileHandle?: string;
    hasMenu?: boolean;
    imageHandle?: string;
    inventoryFilter?: InventoryFindAllOptions;
    inventoryHideSearch?: boolean;
    physicalLocationId?: number[];
    showImageInTitleBar?: boolean;
    showTitleBar?: boolean;
    titleBarBackgroundPosition?: string;
    url?: string;
  };
  content: (
    | SectionContentBlock
    | SectionSliderContentBlock
    | RowContentBlock
  )[];
  publishedContent?: TContentBlock[];
  parentId?: number;
  children?: Page[];
  publishedOn?: Date;
  websiteContact?: WebsiteContact;
  websiteContactId?: number;
  tags?: Tag[];
  uploadHandle?: string;
}

export interface CbLink {
  url: string;
}

export const BlockTypePage = 'PAGE';
// export const BlockTypeContainer = 'CONTAINER';
export const BlockTypeSection = 'SECTION';
export const BlockTypeSectionSlider = 'SECTION_SLIDER';
export const BlockTypeColumn = 'COLUMN';
export const BlockTypeRow = 'ROW';
export const BlockTypeCard = 'CARD';
export const BlockTypeImage = 'IMAGE';
export const BlockTypeGallery = 'GALLERY';
export const BlockTypeHTML = 'HTML';
export const BlockTypeSimpleText = 'TEXT';
export const BlockTypeLink = 'LINK';
export const BlockTypeHeader = 'HEADER';
export const BlockTypeList = 'LIST';
// export const 'BlockTypeListItem = 'LIST_ITEM';
export const BlockTypeContactCard = 'CONTACT_CARD';
export const BlockTypeVideo = 'VIDEO';
export const BlockTypeYouTube = 'YOUTUBE';
export const BlockTypeQuote = 'QUOTE';
export const BlockTypeSlider = 'SLIDER';
export const BlockTypeBlogIndex = 'BLOG-INDEX';
export const BlockTypeFinancingCalculator = 'FINANCING_CALCULATOR';
export const BlockTypeWebsiteContacts = 'WEBSITE_CONTACTS';
export const BlockTypeInventory = 'INVENTORY';
export const BlockTypeInventoryList = 'INVENTORY_LIST';
export const BlockTypeInventoryCard = 'INVENTORY_CARD';
export const BlockTypeScript = 'SCRIPT';
export const BlockTypeInventoryMakes = 'INVENTORY_MAKES';
export const BlockTypeFirstDataPaymentForm = 'FIRST_DATA_PAYMENT_FORM';
export const BlockTypeLocationList = 'LOCATION_LIST';
export const BlockTypeIFrame = 'IFRAME';

export const BlockTypeForm = 'FORM';
export const BlockTypeInput = 'FORM_INPUT';

export const BlockTypeFolderBrowser = 'FOLDER_BROWSER';

export const BlockTypeHoverCard = 'HOVER_CARD';

export const BlockTypesArray = [
  BlockTypePage,
  BlockTypeSection,
  BlockTypeSectionSlider,
  BlockTypeColumn,
  BlockTypeRow,
  BlockTypeCard,
  BlockTypeImage,
  BlockTypeGallery,
  BlockTypeHTML,
  BlockTypeSimpleText,
  BlockTypeLink,
  BlockTypeHeader,
  BlockTypeList,
  BlockTypeContactCard,
  BlockTypeVideo,
  BlockTypeYouTube,
  BlockTypeQuote,
  BlockTypeSlider,
  BlockTypeBlogIndex,
  BlockTypeFinancingCalculator,
  BlockTypeWebsiteContacts,
  BlockTypeInventory,
  BlockTypeInventoryList,
  BlockTypeInventoryCard,
  BlockTypeScript,
  BlockTypeInventoryMakes,
  BlockTypeFolderBrowser,
  BlockTypeHoverCard,
];

export class ContentBlock {
  id?: number | string;
  orgId?: 1;
  siteId?: number;
  pageId?: number;
  position?: number;
  parentId?: number | string;
  type: string;
  content?: any[] | null;
  children?: any[] | null;
  config?: any;
  linkToPageId?: number;
  websiteContactId?: number;
  locationId?: number;
  inventoryId?: number;
  linkToPage?: Partial<Page>;
  websiteContact?: Partial<WebsiteContact>;
  location?: Partial<RetailLocation>;
  inventoryItem?: Partial<Inventory>;
  uploadHandle?: string;
  formId?: number;
  form?: DsForm;
  size?: number;
}

export class SectionContentBlock extends ContentBlock {
  readonly type = BlockTypeSection;
  config: {
    title: string;
    titleColor?: string;
    titlePosition?:
      | 'inline'
      | 'top-left'
      | 'top-right'
      | 'bottom-left'
      | 'bottom-right';
    titleBackgroundColor?: string;
    outerBackgroundColor?: string;
    outerBackgroundImage?: string;
    innerBackgroundColor?: string;
    innerBackgroundImage?: string;
    innerPadding?: number;
    height?: number;
    heightUnits?: '%' | 'px' | 'vh';
    justifyContent?: 'center' | 'flex-start' | 'flex-end';
    alignItems?: 'center' | 'flex-start' | 'flex-end';
    hideDivider?: boolean;
  } = {
    title: '',
  };
  content?: RowContentBlock[];
}

export class SectionSliderContentBlock extends ContentBlock {
  readonly type = BlockTypeSectionSlider;
  config: {
    height?: number;
    heightUnits?: '%' | 'px' | 'vh';
    hideDivider?: boolean;
    timing?: number;
  };
  content?: SectionContentBlock[];
}

export class BlogIndexContentBlock extends ContentBlock {
  readonly type = BlockTypeBlogIndex;
  config: {};
  content: RowContentBlock[];
}

export class RowContentBlock extends ContentBlock {
  readonly type = BlockTypeRow;
  config: {
    backgroundColor?: string;
    backgroundImage?: string;
    padding?: number;
    height?: number;
    heightUnits?: '%' | 'px' | 'vh';
    justifyContent?: 'center' | 'top' | 'bottom';
    columnSizes: number[];
  };
  content: ColumnContentBlock[];
}

export class ColumnContentBlock extends ContentBlock {
  readonly type = BlockTypeColumn;
  config: {
    backgroundColor?: string;
    backgroundImage?: string;
    padding?: number;
    alignContent?: 'center' | 'top' | 'bottom';
  };
  content: TContentBlock[];
}

export class CardContentBlock extends ContentBlock {
  readonly type = BlockTypeCard;
  config: {
    header?: string;
    footer?: string;
    headerTextAlign?: 'center' | 'left' | 'right';
    footerTextAlign?: 'center' | 'left' | 'right';
    headerTextSize?: TextSize;
    footerTextSize?: TextSize;
    headerFooterColor?: string;
    headerFooterBackgroundColor?: string;
    type?: 'default' | 'slide-up' | 'slide-down';
    showBorder?: boolean;
    compact?: boolean;
    backgroundColor?: string;
    backgroundImage?: string;
    minHeight?: number;
    justifyContent?:
      | 'center'
      | 'flex-start'
      | 'flex-end'
      | 'space-between'
      | 'space-around'
      | 'space-evenly';
    fillHeight?: boolean;
  };
  content: TContentBlock[];
}

// export class HoverCardContentBlock extends ContentBlock {
//   readonly type = BlockTypeHoverCard;
//   config: {
//     header?: string;
//     subHeader?: string;
//     backgroundImage?: string;
//     backgroundColor?: string;
//     showBorder?: boolean;
//     height: 'small' | 'medium' | 'large';
//   };
//   content: TContentBlock[];
// }

export class ListContentBlock extends ContentBlock {
  readonly type = BlockTypeList;
  config: {
    layout: 'vertical' | 'horizontal';
  };
  content: TContentBlock[];
}

export type ImageSize = 'tiny' | 'icon' | 'small' | 'medium' | 'large' | 'full';

export class ImageContentBlock extends ContentBlock {
  readonly type = BlockTypeImage;
  config: {
    imageHandle?: string;
    text?: string;
    url?: string;
    backgroundColor?: string;
    borderColor?: string;
    lightbox?: boolean;
    imageSize?: ImageSize;
    fitType?: ImageFitOptions;
    imageAlign?: 'center' | 'left' | 'right';
  };
}

export class GalleryContentBlock extends ContentBlock {
  readonly type = BlockTypeGallery;
  config: {
    lightbox?: boolean;
    imageSize?: ImageSize;
    // sliderConfig?: false | SliderContentBlockConfig;
  };
  content: ImageContentBlock[];
}

export class HTMLContentBlock extends ContentBlock {
  readonly type = BlockTypeHTML;
  config: {
    html: string;
    fontSize?: number;
    color?: string;
  };
  constructor(defaultHtml?: string) {
    super();
    this.config.html = defaultHtml || '<p>Enter your content here</p>';
  }
}

export enum LinkTypes {
  external = 'EXTERNAL',
  page = 'PAGE',
  upload = 'UPLOAD',
  inventory = 'INVENTORY',
  location = 'LOCATION',
  blog = 'BLOG',
}

export class LinkContentBlock extends ContentBlock {
  readonly type = BlockTypeLink;
  linkPageId?: number; // This is required for config.linkTo === internal
  uploadHandle?: string;
  inventoryId?: number;
  inventoryItem?: Inventory;
  linkToPage?: Page;
  locationId?: number;
  location?: RetailLocation;
  config: {
    linkStyle: 'button' | 'link';
    linkType: LinkTypes;
    isCompact?: boolean;
    size?: 'regular' | 'large';
    displayThumbnail?: boolean;
    imageSize?: ImageSize;
    text: string;
    url?: string;
    textAlign?: TextAlign;
    icon?: string;
    route?: any[] | string; // needs to be removed and use linkPageId
    fileHandle?: string; // need to move into root on import
    oldLocationId?: number;
    color?: string;
    backgroundColor?: string;
  };
}

export type TextSize = 'x-small' | 'small' | 'medium' | 'large' | 'x-large';
export type TextAlign = 'center' | 'left' | 'right';
export type FluidTextSize = 'xs' | 'sm' | 'lg' | 'xl' | 'xxl';

export class HeaderContentBlock extends ContentBlock {
  readonly type = BlockTypeHeader;
  config: {
    textSize: TextSize;
    text: string;
    textAlign?: TextAlign;
    color?: string;
    backgroundColor?: string;
  };
}

export type ContactCardType = 'square' | 'default';

export class ContactCardContentBlock extends ContentBlock {
  readonly type = BlockTypeContactCard;
  websiteContactId?: number;
  websiteContact?: WebsiteContact;
  config: {
    websiteContactId?: number;
    cardType: ContactCardType;
  };
}

export class VideoContentBlock extends ContentBlock {
  readonly type = BlockTypeVideo;
  config: {
    text?: string;
    imageHandle?: string;
    url?: string;
    imageSize?: ImageSize;
  };
}

export class YouTubeContentBlock extends ContentBlock {
  readonly type = BlockTypeYouTube;
  config: {
    youTubeId: string;
    alt?: string;
  };
}

export class QuoteContentBlock extends ContentBlock {
  readonly type = BlockTypeQuote;
  config: {
    text: string;
    author?: string;
    citeUrl?: string;
  };
}

export class SimpleTextContentBlock extends ContentBlock {
  readonly type = BlockTypeSimpleText;
  config: {
    text: string;
    fluidTextSize: FluidTextSize;
    color?: string;
    textAlign: TextAlign;
    fontWeight?: string;
  };
}

export interface SliderContentBlockConfig {
  imageSize?: ImageSize;
  lightbox?: boolean;
}

export class SliderContentBlock extends ContentBlock {
  readonly type = BlockTypeSlider;
  config: SliderContentBlockConfig;
  content: ImageContentBlock[];
}

export class FinancingCalculatorContentBlock extends ContentBlock {
  readonly type = BlockTypeFinancingCalculator;
  config: {
    amount: number;
    rate: number;
    term: number;
    downPayment: number;
  };
}

export class WebsiteContactsContentBlock extends ContentBlock {
  readonly type = BlockTypeWebsiteContacts;
  config: {};
  content: ContactCardContentBlock[];
}

export class InventoryListContentBlock extends ContentBlock {
  readonly type = BlockTypeInventoryList;
  linkToPageId?: number;
  linkToPage?: Page;
  config: {
    route: string;
  };
  content: InventoryCardContentBlock[];
}

export class InventoryCardContentBlock extends ContentBlock {
  readonly type = BlockTypeInventoryCard;
  linkToPageId?: number;
  inventoryId?: number;
  inventoryItem?: Inventory;
  linkToPage?: Page;
  config: {
    route: string;
  };
}

export class ScriptContentBlock extends ContentBlock {
  readonly type = BlockTypeScript;
  config: {
    src: string;
    attributes: {
      prop: string;
      value: string;
    }[];
  };
}

export class InventoryMakesContentBlock extends ContentBlock {
  readonly type = BlockTypeInventoryMakes;
  linkPageId?: number;
  linkToPage?: Page;
  config: {
    route: string; // todo, to be removed.
  };
}

export class FormContentBlock extends ContentBlock {
  readonly type = BlockTypeForm;
  config: {};
  content: TContentBlock[];
}

export class FolderBrowserContentBlock extends ContentBlock {
  readonly type = BlockTypeFolderBrowser;
  config: {
    folderId: number;
  };
}

export class FirstDataPaymentFormContentBlock extends ContentBlock {
  readonly type = BlockTypeFirstDataPaymentForm;
  config: {};
}

export class LocationListContentBlock extends ContentBlock {
  readonly type = BlockTypeLocationList;
  config: {};
}

export class IframeContentBlock extends ContentBlock {
  readonly type = BlockTypeIFrame;
  config: {
    src: string;
    minHeight?: number;
    minHeightUnits?: '%' | 'px' | 'vh';
    minWidth?: number;
    minWidthUnits?: '%' | 'px' | 'vh';
  };
}

export type TContentBlock =
  | ColumnContentBlock
  | RowContentBlock
  | CardContentBlock
  | HTMLContentBlock
  | ImageContentBlock
  | HeaderContentBlock
  | LinkContentBlock
  | ListContentBlock
  | ContactCardContentBlock
  | GalleryContentBlock
  | SectionContentBlock
  | VideoContentBlock
  | YouTubeContentBlock
  | QuoteContentBlock
  | SliderContentBlock
  | BlogIndexContentBlock
  | FinancingCalculatorContentBlock
  | WebsiteContactsContentBlock
  | InventoryListContentBlock
  | InventoryCardContentBlock
  | ScriptContentBlock
  | InventoryMakesContentBlock
  | FormContentBlock
  | FolderBrowserContentBlock
  | FirstDataPaymentFormContentBlock
  | LocationListContentBlock
  | IframeContentBlock
  | SimpleTextContentBlock
  | SectionSliderContentBlock;

export const buildABlock = (type: string): TContentBlock => {
  switch (type) {
    case BlockTypeHTML:
      return new HTMLContentBlock();
    default:
      break;
  }
};
