import { Component, Input, ChangeDetectionStrategy } from '@angular/core';
import { Page } from '@ds/interfaces';
import { Router } from '@angular/router';
import { ContentBlockWebsiteEditService } from '../../services/content-block-website-edit.service';

@Component({
  selector: 'ds-cb-page-card',
  template: `
    <a [href]="createUrl(entry.slug)" (click)="navClick($event, entry.slug)">
      <figure>
        <div class="page-image">
          <img dsImage [imageHandle]="entry.config.imageHandle" />
        </div>
        <div class="page-info">
          <figcaption class="ds-fluid-text">
            <h3>{{ entry.title }}</h3>
            <span [innerHTML]="entry.config.description"></span>
          </figcaption>
        </div>
      </figure>
    </a>
  `,
  styles: [
    `
      :host {
        position: relative;
        display: block;
        border-radius: 4px;
        max-width: 100%;
        background-color: white;
        box-shadow: 0 1px 2px rgba(0, 0, 0, 0.3);
        transition: box-shadow 0.3s ease-in-out;
        padding: 25px;
      }

      figure {
        height: 100%;
        display: flex;
        flex-direction: column;
      }
      :host:hover {
        box-shadow: 0 5px 15px rgba(0, 0, 0, 0.5);
      }
      a {
        text-decoration: none;
        color: var(--ds-text-color);
      }
      .page-image {
        margin: -25px -25px 25px -25px;
      }
      .page-image img {
        background-color: var(--ds-cb-image-background-color);
        border-color: var(--ds-cb-image-border-color);
        max-width: 100%;
        min-height: 150px;
        max-height: 150px;
        height: 150px;
        object-fit: cover;
        vertical-align: middle;
        width: 100%;
      }

      .page-info {
        overflow: hidden;
      }

      .page-info .ds-fluid-text {
        overflow: hidden;
        overflow-text: ellipse;
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ContentBlockPageCardComponent {
  @Input() entry: Page;

  constructor(
    private router: Router,
    public wsDataMsg: ContentBlockWebsiteEditService
  ) {}

  createUrl(slug: string) {
    return '/' + (slug || '');
  }

  navClick(e: MouseEvent, slug: string) {
    e.preventDefault();
    const url = this.createUrl(slug);
    if (this.wsDataMsg.isEditing) {
      e.stopPropagation();
      this.wsDataMsg.adminNavigatePage(slug);
      return;
    }
    this.router.navigateByUrl(url);
  }

  isActive() {}
}
