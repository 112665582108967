// ToDo: replace all ds-col with local css.
// suggestion is to use grid
import { Component, HostBinding, Input } from '@angular/core';
import {
  BlockTypeSlider,
  GalleryContentBlock,
  ImageContentBlock,
  ImageSize,
  SliderContentBlock,
  SliderContentBlockConfig,
} from '@ds/interfaces';
import { ContentBlockDataService } from '../../services/content-block-data.service';
import { Observable, of } from 'rxjs';

@Component({
  selector: 'ds-cb-gallery',
  template: `
    <ds-cb-image
      *ngFor="let image of _images | async; trackBy: trackByFn"
      [block]="image"
      [lightbox]="lightbox"
      [imageSize]="imageSize"
    ></ds-cb-image>
    <div class="edit-shield"></div>
  `,
  styles: [
    `
      :host {
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        align-items: center;
        position: relative;
      }
      ds-cb-image {
        padding: 5px;
      }
      :host.tiny ds-cb-image {
        width: 50px;
        height: 50px;
      }
      :host.icon ds-cb-image {
        width: 100px;
        height: 100px;
      }
      :host.small ds-cb-image {
        width: 250px;
        width: 250px;
      }
      :host.small ds-cb-image {
        width: 350px;
        width: 350px;
      }
    `,
  ],
})
export class ContentBlockGalleryComponent {
  @Input() set images(images: ImageContentBlock[]) {
    this._images = of(images);
  }
  @Input() lightbox = false;
  @HostBinding('class') @Input() imageSize: ImageSize = 'icon';

  _block: GalleryContentBlock;
  _images: Observable<ImageContentBlock[]> = of([]);

  constructor(private cbDataService: ContentBlockDataService) {}

  @Input()
  set block(val: GalleryContentBlock) {
    this.init(val);
  }

  init(block: GalleryContentBlock) {
    this._block = block;
    this._images = block.content
      ? of(block.content)
      : this.cbDataService.getChildren(block.id);
    this.lightbox = block.config.lightbox || false;
    this.imageSize = block.config.imageSize || 'icon';
  }

  trackByFn(idx: number, model: any) {
    return model.id;
  }
}
