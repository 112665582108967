import {
  Component,
  HostBinding,
  Input,
  OnInit,
  ChangeDetectionStrategy,
} from '@angular/core';
import { LinkContentBlock, LinkTypes, Page } from '@ds/interfaces';
import { ImageLightboxService } from '@ds/core';
import { Router } from '@angular/router';

// ToDo: need to replace this with injected variable.
const fileStackPublicKey = 'AvLVW6vvuTjCqG5oZVY5Cz';

@Component({
  selector: 'ds-cb-link',
  template: `
    <ds-cb-image
      *ngIf="_displayThumbnail"
      [fileHandle]="fileHandle"
      [link]="_url"
    ></ds-cb-image>
    <a
      [href]="_url"
      (click)="handleUrlClick($event)"
      [target]="isInternal ? '_self' : '_blank'"
      [rel]="isInternal ? 'follow' : 'nofollow'"
      [style.color]="color"
      [style.justify-content]="textAlign"
      [style.text-align]="textAlign"
    >
      <span class="icon" *ngIf="icon" [inlineSVG]="icon"></span>
      <span>{{ text }}</span>
    </a>

    <div class="edit-shield"></div>
  `,
  styles: [
    // Note this component uses fluid-text.scss !!!
    `
      :host {
        display: block;
        width: 100%;
        position: relative;
      }
      a {
        display: flex;
        font-weight: 400;
        vertical-align: middle;
        user-select: none;
        border: 1px solid transparent;
        padding: 0.475rem 0.85rem;
        border-radius: 0.25rem;
        text-decoration: underline;
        color: var(--ds-link-color);
        width: 100%;
      }

      :host(.ds-fluid-text-xl):not(.ds-button) a {
        font-weight: 700;
      }

      :host(.compact):not(.ds-button) a,
      :host-context(.compact):not(.ds-button) a {
        padding: 0 0 0 0;
      }
      :host(.ds-button),
      :host(.ds-button) a {
        text-align: center;
        display: block;
        width: 100%;
        text-decoration: none;
      }
      :host(.ds-button) a {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        color: var(--ds-button-color);
        background-color: var(--ds-button-background-color);
        border-color: var(--ds-button-border-color);
        text-transform: uppercase;
      }
      .icon {
        height: 24px;
        width: 24px;
        margin-right: 8px;
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ContentBlockLinkComponent {
  @Input() role = 'link';
  @Input() text: string;
  @Input()
  set linkStyle(val: string) {
    this.classButton = val === 'button';
  }
  @Input() url: string;
  @Input() linkType: LinkTypes;
  @Input() route: any[] = [];
  @Input() slug: string;
  @Input() @HostBinding('class.compact') isCompact = false;
  @Input() size: 'regular' | 'large' = 'regular';

  @HostBinding('class.ds-fluid-text-xl') get isLarge() {
    return this.size === 'large';
  }

  @HostBinding('class.ds-button') classButton = false;

  @HostBinding('style.justify-content')
  @Input()
  textAlign: string;

  _block: LinkContentBlock;
  @Input()
  set block(val: LinkContentBlock) {
    this.init(val);
  }

  @Input() fileHandle: string;
  @Input() displayThumbnail = false;

  @Input() icon: string;

  @Input() color: string;

  linkToPage: Page;

  constructor(private router: Router) {}

  init(block: LinkContentBlock) {
    this.text =
      block.config.text ||
      (block.location && block.location.name) ||
      (block.linkToPage && block.linkToPage.title) ||
      '';
    this.linkStyle = block.config.linkStyle || '';
    this.url = block.config.url;
    this.linkType = block.config.linkType || LinkTypes.external;
    this.slug = block.linkToPage ? block.linkToPage.slug : '';
    this.textAlign = block.config.textAlign;
    this.fileHandle = block.uploadHandle;
    this.displayThumbnail = block.config.displayThumbnail;
    this.icon = block.config.icon;
    this.color = block.config.color;
    this.isCompact = block.config.isCompact;
    this.size = block.config.size;
    this._block = block;
  }

  get _url() {
    if (this.linkType === LinkTypes.external) {
      return this.url;
    }

    if (this.linkType === LinkTypes.upload && this.fileHandle) {
      return `https://cdn.filestackcontent.com/${fileStackPublicKey}/${this.fileHandle}`;
    }

    return this._route
      .map((i) => {
        if (i === '/') {
          return '';
        } else if (typeof i === 'string') {
          return i;
        } else if (Object.keys(i).length) {
          return (
            '?' +
            Object.keys(i)
              .map((k) => `${k}=${i[k]}`)
              .join('&')
          );
        }
        return null;
      })
      .filter(Boolean)
      .join('/');
  }

  get isInternal() {
    return ![LinkTypes.external, LinkTypes.upload].includes(this.linkType);
  }

  get _displayThumbnail() {
    return this.displayThumbnail && this.fileHandle;
  }

  get _route() {
    switch (this.linkType) {
      case LinkTypes.location:
        return this._block.location
          ? ['/', this.slug, this._block.location.slug]
          : [];
      case LinkTypes.page:
        if (!this._block.linkToPage) {
          console.warn('Link to Page missing!', this._block.config.route);
        }
        return ['/', this.slug];
      case LinkTypes.inventory:
        console.warn('inventory not setup yet');
        return ['/', this.slug];
      default:
        return this.route;
    }
  }

  handleUrlClick($event: MouseEvent) {
    if (this.isInternal) {
      this.router.navigate(this._route);
      $event.preventDefault();
      return;
    }
  }
}
