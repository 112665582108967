import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { Injectable, Optional, Inject } from '@angular/core';
import { Observable } from 'rxjs';
import { Request } from '@hapi/hapi';
import { REQUEST } from '@nguniversal/hapi-engine/tokens';
import {
  PaginatedResults,
  ExtraPaginatedResults,
  PaginatedQuery,
} from '@ds/interfaces';
import { map } from 'rxjs/operators';
import { GlobalCacheConfig } from 'ngx-cacheable';
import { Cacheable } from 'ngx-cacheable';

interface QueryParams {
  [key: string]: string | string[];
}

type MultiStringOrNumber = string | number | string[] | number[];
type MinMax = [string | number, string | number];

interface Params {
  [key: string]:
    | Date
    | string
    | number
    | string[]
    | number[]
    | boolean
    | [string | number, string | number];
}

function isEmpty(val: any) {
  return val === null || val === undefined || val === '';
}

const noCacheHeader = {
  'Cache-Control':
    'private, max-age=0, no-cache, no-store, must-revalidate, post-check=0, pre-check=0',
  Pragma: 'no-cache',
  Expires: '0',
};

function convertToString(
  val:
    | Date
    | string
    | number
    | string[]
    | number[]
    | boolean
    | [string | number, string | number]
): string | string[] {
  if (Array.isArray(val)) {
    const _val: string[] = [];
    for (const i of val) {
      _val.push(i.toString());
    }
    return _val;
  }

  return val.toString();
}

function convertToQueryParams(obj: Params): QueryParams {
  if (!obj) {
    return null;
  }
  return Object.keys(obj).reduce((a, c) => {
    if (!isEmpty(obj[c])) {
      a[c] = convertToString(obj[c]);
    }
    return a;
  }, {});
}

@Injectable({
  providedIn: 'root',
})
export class ApiService {
  private baseUrl = '/api';

  constructor(
    @Optional() @Inject(REQUEST) private request: Request,
    private http: HttpClient
  ) {}

  private getBaseUrl(): String {
    // If request is truthy that means we are running on the server
    // we need to set api requests to match the host since we use
    // domains to limit data returned

    if (this.request) {
      const protocol =
        this.request.headers['x-forwarded-proto'] ||
        this.request.server.info.protocol;
      const host = this.request.headers.host;
      return `${protocol}://${host}${this.baseUrl}`;
    }
    return this.baseUrl;
  }

  makeUrl(url: string): string {
    if (url.substr(0, 1) === '/') {
      return `${this.getBaseUrl()}${url}`;
    }
    return `${this.getBaseUrl()}/${url}`;
  }

  @Cacheable({
    maxCacheCount: 100,
  })
  private _getCached<T = any>(url: string, params?: QueryParams) {
    return this.http.get<T>(url, {
      params,
    });
  }

  private _getNotCached<T = any>(url: string, params?: QueryParams) {
    return this.http.get<T>(url, {
      params,
    });
  }

  get<T = any>(resource: string, _params: Params = {}, cache?: boolean) {
    const params = convertToQueryParams(_params);

    const useCache = !this.request && cache;

    return useCache
      ? this._getCached<T>(this.makeUrl(resource), params)
      : this._getNotCached<T>(this.makeUrl(resource), params);
  }

  getOne<T>(url: string, cache?: boolean) {
    return this.get<T>(url, {}, cache);
  }

  getAll<T>(url: string, _params: Params = {}, cache?: boolean) {
    return this.get<T[]>(url, _params, cache);
  }

  getPaginated<T>(
    url: string,
    _params: PaginatedQuery & Params = {},
    cache?: boolean
  ): Observable<ExtraPaginatedResults<T>> {
    return this.get<PaginatedResults<T>>(url, _params, cache).pipe(
      map((resp) => {
        return {
          ...resp,
          limit: _params.limit || 25,
          totalPages: resp.total
            ? Math.ceil(resp.total / (_params.limit || 25))
            : 0,
          currentPage: +_params.page || 0,
        };
      })
    );
  }

  put<T = any>(url: string, body?: any, options = {}): Observable<T> {
    return this.http.put<T>(this.makeUrl(url), body, {
      ...options,
      observe: 'body',
      headers: noCacheHeader,
    });
  }

  post<T = any>(url: string, body?: any): Observable<T> {
    return this.http.post<T>(this.makeUrl(url), body, {
      observe: 'body',
      headers: noCacheHeader,
    });
  }

  delete(url: string, body: any = {}, options?: any): Observable<any> {
    return this.http.request('delete', this.makeUrl(url), { body, ...options });
  }

  getUrl(url: string, query: any = {}) {
    const params = new HttpParams({
      fromObject: query,
    });
    return `${this.makeUrl(url)}?${params.toString()}`;
    // return this.http.request('get', this.makeUrl(url), { body, ...options }).;
  }
}
