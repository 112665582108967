import { BreakpointObserver } from '@angular/cdk/layout';
import {
  Component,
  HostBinding,
  AfterViewInit,
  PLATFORM_ID,
  Inject,
} from '@angular/core';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';
import { Site } from '@ds/interfaces';
import { ContentBlockWebsiteEditService } from '../../services/content-block-website-edit.service';
import { isPlatformBrowser } from '@angular/common';

declare let gtag: Function;
declare let fbq: Function;
declare let track_load: Function;

const screenSizes = [
  { value: '(max-width: 599.99px)', label: 'xsmall' },
  { value: '(min-width: 599.99px)', label: 'gt-xsmall' },
  { value: '(max-width: 599.99px)', label: 'lt-small' },
  { value: '(min-width: 600px) and (max-width: 959.99px)', label: 'small' },
  { value: '(min-width: 959.99px)', label: 'gt-small' },
  { value: '(max-width: 959.99px)', label: 'lt-medium' },
  { value: '(min-width: 960px) and (max-width: 1279.99px)', label: 'medium' },
  { value: '(min-width: 1279.99px)', label: 'gt-medium' },
  { value: '(max-width: 1279.99px)', label: 'lt-large' },
  { value: '(min-width: 1280px) and (max-width: 1919.99px)', label: 'large' },
  { value: '(min-width: 1919.99px)', label: 'gt-large' },
  { value: '(max-width: 1919.99px)', label: 'lt-xlarge' },
  { value: '(min-width: 1920px)', label: 'xlarge' },
  { value: '(orientation: landscape)', label: 'landscape' },
  { value: '(orientation: portrait)', label: 'portrait' },
];

@Component({
  selector: 'ds-cb-site',
  template: `
    <div class="public-background public-background-color"></div>
    <ds-cb-content-main-navigation
      [siteData]="siteData"
    ></ds-cb-content-main-navigation>
    <div class="public-main-content-container">
      <div class="public-main-content"><router-outlet></router-outlet></div>
      <ds-cb-footer [footerPage]="siteData.footerPage"></ds-cb-footer>
    </div>
    <ds-image-lightbox></ds-image-lightbox>
  `,
  styles: [
    `
      :host {
        display: grid;
        grid-template-areas: 'navigation' 'content';
      }
      :host :host-context(.ds-menu-position-left) {
        height: 100%;
        display: grid;
        grid-template-columns: auto 1fr;
        grid-template-areas: 'navigation content';
      }
      :host
        :host-context(.ds-menu-position-left)
        .public-main-content-container {
        height: 100%;
        overflow: auto;
      }
      :host.pop-out:not(.lt-medium) {
        max-width: 1200px;
        margin: 35px auto;
        border: 1px solid gray;
        box-shadow: 0 0 20px rgba(16, 16, 16, 0.5);
      }
      ds-cb-content-main-navigation {
        grid-area: navigation;
        position: sticky;
        top: 0;
        z-index: 999;
      }
      .public-main-content-container {
        grid-area: content;
      }
      .public-background-color {
        background-color: var(--ds-background-color);
      }
      .public-main-content {
        min-height: 400px;
        background-color: var(--ds-main-content-background-color);
      }
      .public-background {
        z-index: -1;
        display: block;
        position: fixed;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
      }
    `,
  ],
})
export class ContentBlockSiteComponent implements AfterViewInit {
  siteData: Site = this.route.snapshot.data['siteData'];

  @HostBinding('class') sizes: string;

  @HostBinding('class.is-editing') isEditing = false;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private breakpointObserver: BreakpointObserver,
    private wsDataMsg: ContentBlockWebsiteEditService,
    @Inject(PLATFORM_ID) private platformId: Object
  ) {
    this.isEditing = wsDataMsg.isEditing;
    this.breakpointObserver
      .observe(screenSizes.map((i) => i.value))
      .subscribe((val) => {
        this.sizes = screenSizes
          .filter((i) => val.breakpoints[i.value])
          .map((i) => i.label)
          .join(' ')
          .concat(this.siteData.config.sitePopOut ? ' pop-out' : '');
      });

    if (isPlatformBrowser(this.platformId)) {
      this.router.events.subscribe((y: NavigationEnd) => {
        if (y instanceof NavigationEnd) {
          if (this.siteData.config.ga) {
            gtag('config', this.siteData.config.ga, {
              page_path: y.urlAfterRedirects,
            });
          }
          if (this.siteData.config.fbq) {
            fbq('track', 'PageView');
          }
          if (track_load) {
            track_load(y.urlAfterRedirects);
          }
        }
      });
    }
  }

  ngAfterViewInit() {
    this.wsDataMsg.publicNotifyAdminReady();
  }
}
