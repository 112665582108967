import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Renderer2,
  Inject,
  Input,
  ElementRef,
} from '@angular/core';

import { ScriptContentBlock } from '@ds/interfaces';
import { DOCUMENT } from '@angular/common';

@Component({
  selector: 'ds-cb-content-block-script',
  template: ``,
  styles: [],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ContentBlockScriptComponent implements OnInit {
  _block: ScriptContentBlock;
  @Input()
  set block(val: ScriptContentBlock) {
    this.init(val);
  }

  constructor(private renderer2: Renderer2, private hostElement: ElementRef) {}

  init(block: ScriptContentBlock) {
    this._block = block;
  }

  ngOnInit() {
    const tagId = 'script_' + this._block.id.toString();
    const exists = window.document.getElementById(tagId);
    if (exists) {
      return;
    }
    const s = this.renderer2.createElement('script');
    s.type = 'text/javascript';
    s.src = this._block.config.src;
    if (this._block.config.attributes) {
      this._block.config.attributes.forEach((att) => {
        s.setAttribute(att.prop, att.value);
      });
    }
    s.id = tagId;
    s.text = ``;
    this.hostElement.nativeElement.appendChild(s);
  }
}
