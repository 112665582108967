import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiService } from './api.service';
import { ApiFile, FileStackClientConfig, DocumentFile } from '@ds/interfaces';

@Injectable({
  providedIn: 'root',
})
export class UploadService {
  constructor(private api: ApiService) {}

  getFormConfig() {
    return this.api.get<FileStackClientConfig>('uploads/form-config');
  }

  getUrl(file: DocumentFile) {
    return this.api.get<{
      url: string;
    }>(`uploads/url/${file.fileHandle}`);
  }

  create(file: ApiFile): Observable<ApiFile> {
    return this.api.post('uploads', { file });
  }
}
