import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import {
  Injectable,
  NgModule,
  ErrorHandler,
  Inject,
  PLATFORM_ID,
  Optional,
} from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {
  DefaultUrlSerializer,
  RouterModule,
  Routes,
  UrlSerializer,
  UrlTree,
} from '@angular/router';
import { PublicAppComponent } from './public-app.component';
import { OverlayModule } from '@angular/cdk/overlay';
import { CoreModule, GlobalErrorHandler } from '@ds/core';
import { FormlyModule } from '@ngx-formly/core';
import { FormlyBootstrapModule } from '@ngx-formly/bootstrap';
import { PublicContentBlockModule } from '@ds/content-block';
import { PublicInterceptor } from './public.interceptor';
import { environment } from '@ds/admin-env';

const routes: Routes = [
  {
    path: '',
    loadChildren: () =>
      import('@ds/content-block').then((m) => m.PublicContentBlockModule),
  },
];

@Injectable()
export class CustomUrlSerializer extends DefaultUrlSerializer {
  parse(url: string): UrlTree {
    // Change plus signs to encoded spaces
    url = url.replace(/\+/g, '%20');
    // Use the default serializer that you can import to just do the
    // default parsing now that you have fixed the url.
    return super.parse(url);
  }

  serialize(tree: UrlTree): string {
    // Use the default serializer to create a url and replace any spaces with + signs
    return super.serialize(tree).replace(/%20/g, '+');
  }
}

@NgModule({
  declarations: [PublicAppComponent],
  imports: [
    CoreModule,
    BrowserAnimationsModule,
    BrowserModule.withServerTransition({ appId: 'ds-public' }),
    HttpClientModule,
    FormlyModule.forRoot(),
    FormlyBootstrapModule,
    PublicContentBlockModule,
    RouterModule.forRoot(routes, {
      enableTracing: false,
      paramsInheritanceStrategy: 'always',
      initialNavigation: 'enabled',
      scrollPositionRestoration: 'enabled',
    }),
  ],
  providers: [
    { provide: UrlSerializer, useClass: CustomUrlSerializer },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: PublicInterceptor,
      multi: true,
    },
    environment.production
      ? { provide: ErrorHandler, useClass: GlobalErrorHandler }
      : null,
  ].filter((v) => v),
  bootstrap: [PublicAppComponent],
})
export class PublicAppModule {}
