import {
  Component,
  Input,
  OnChanges,
  ChangeDetectionStrategy,
} from '@angular/core';
import { Observable } from 'rxjs';
import { ContentBlockInventoryService } from './content-block-inventory.service';
import { startWith } from 'rxjs/operators';
import { Inventory, Page } from '@ds/interfaces';

@Component({
  selector: 'ds-cb-inventory',
  providers: [ContentBlockInventoryService],
  template: `
    <ds-cb-inventory-browse
      *ngIf="isBrowsing$ | async; else detail"
    ></ds-cb-inventory-browse>
    <ng-template #detail>
      <ds-cb-inventory-detail
        [isLegacy]="isLegacy"
        [item]="item$ | async"
      ></ds-cb-inventory-detail>
    </ng-template>
  `,
  styles: [``],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ContentBlockInventoryComponent implements OnChanges {
  @Input() pageData: Page;
  @Input() isLegacy: boolean;

  isBrowsing$: Observable<Boolean>;
  item$: Observable<Inventory> = this.cbInvService.currentInventoryItem$.pipe(
    startWith({
      props: {},
    })
  );

  constructor(private cbInvService: ContentBlockInventoryService) {
    this.isBrowsing$ = this.cbInvService.isBrowsing$;
  }

  ngOnChanges() {
    this.cbInvService.setDefaultSearchParams({
      physicalLocationId: this.pageData.config.physicalLocationId,
    });
  }
}
