import { Component, ViewEncapsulation, OnInit, ViewChild } from '@angular/core';
import {
  NgxGalleryOptions,
  NgxGalleryImage,
  NgxGalleryComponent,
} from '@kolkov/ngx-gallery';
import { Observable } from 'rxjs';
import { ImageLightboxService } from '../../services/image-lightbox.service';

@Component({
  selector: 'ds-image-lightbox',
  template: `
    <ngx-gallery
      [options]="galleryOptions"
      [images]="galleryImages | async"
      #gallery
    ></ngx-gallery>
  `,
  styles: [
    `
      i.ds-gallery-lightbox-icon {
        height: 30px;
        width: 30px;
        color: white;
        background-repeat: no-repeat;
        background-size: contain;
        background-position: center;
        background-color: white;
        border-radius: 15px;
      }
      i.ds-gallery-lightbox-icon-close {
        background-image: url('/icons/solid/times-circle.svg');
      }
      i.ds-gallery-lightbox-icon-next {
        background-image: url('/icons/solid/arrow-circle-right.svg');
      }
      i.ds-gallery-lightbox-icon-previous {
        background-image: url('/icons/solid/arrow-circle-left.svg');
      }
    `,
  ],
  encapsulation: ViewEncapsulation.None,
})
export class ImageLightBoxComponent implements OnInit {
  galleryOptions: NgxGalleryOptions[] = [
    {
      previewCloseOnEsc: true,
      image: false,
      thumbnails: false,
      width: '0px',
      height: '0px',
      closeIcon: 'ds-gallery-lightbox-icon ds-gallery-lightbox-icon-close',
      arrowNextIcon: 'ds-gallery-lightbox-icon ds-gallery-lightbox-icon-next',
      arrowPrevIcon:
        'ds-gallery-lightbox-icon ds-gallery-lightbox-icon-previous',
      imageDescription: true,
      previewBullets: true,
      previewKeyboardNavigation: true,
      previewInfinityMove: true,
    },
  ];

  @ViewChild(NgxGalleryComponent, { static: true })
  gallery: NgxGalleryComponent;

  galleryImages: Observable<NgxGalleryImage[]>;
  constructor(private imageLightboxService: ImageLightboxService) {
    this.galleryImages = this.imageLightboxService.imagesForLightbox$;
  }

  ngOnInit() {
    this.imageLightboxService.registerLightboxComponent(this.gallery);
  }
}
