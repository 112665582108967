import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { WebsiteEmailEntryService } from '@ds/api-services';
import { DS_MODAL_DATA } from '@ds/core';
import {
  PhoneNumberType,
  WebsiteContact,
  WebsiteEmailEntry,
  WebsiteEmailEntryType,
  phoneIcons,
} from '@ds/interfaces';

@Component({
  selector: 'ds-cb-contact-form',
  template: `
    <ng-container *ngIf="!isSent">
      <h1>{{ title }}</h1>
      <div *ngIf="websiteContact as contact">
        <div class="ds-row">
          <div class="ds-col-sm-4">
            <img
              class="photo"
              dsImage
              [imageHandle]="contact.photoHandle"
              imageSize="small"
            />
          </div>
          <div class="ds-col-sm-8">
            <div class="ds-fluid-text-lg">{{ contact.name }}</div>
            <div class="ds-fluid-text-sm" *ngIf="contact.title">
              {{ contact.title }}
            </div>
            <br />
            <div
              class="ds-fluid-text-lg"
              *ngFor="let phoneNumber of contact.phoneNumbers"
            >
              <a
                href="tel:{{ phoneNumber.number }}"
                [title]="phoneNumber.type"
                class="phone"
              >
                <span
                  class="icon"
                  [inlineSVG]="getPhoneIcon(phoneNumber.type)"
                ></span>
                <span>{{ phoneNumber.number }} </span>
              </a>
            </div>
          </div>
        </div>
      </div>
      <form [formGroup]="answers" (ngSubmit)="send()">
        <div class="ds-row">
          <label
            for="contactName"
            class="ds-col-sm-4 ds-text-sm-right col-form-label"
            >Your Name</label
          >
          <div class="ds-col-sm-8">
            <input
              type="text"
              class="form-control"
              id="contactName"
              placeholder="Optional"
              formControlName="name"
            />
          </div>
        </div>

        <div
          class="ds-row"
          [class.has-error]="!!answers.get('emailAddress').errors"
        >
          <label
            for="contactEmail"
            class="ds-col-sm-4 ds-text-sm-right col-form-label"
            >Email address</label
          >
          <div class="ds-col-sm-8">
            <input
              type="email"
              class="form-control"
              id="contactEmail"
              placeholder="Optional"
              formControlName="emailAddress"
            />
          </div>
        </div>

        <div class="ds-row">
          <label
            for="contactPhone"
            class="ds-col-sm-4 ds-text-sm-right col-form-label"
            >Phone Number</label
          >
          <div class="ds-col-sm-8">
            <input
              type="text"
              class="form-control"
              id="contactPhone"
              placeholder="Optional"
              formControlName="phoneNumber"
            />
          </div>
        </div>
        <div
          class="ds-row"
          [class.has-error]="!!answers.get('comments').errors"
        >
          <label
            for="contactComments"
            class="ds-col-sm-4 ds-text-sm-right col-form-label"
            >Your comments or questions</label
          >
          <div class="ds-col-sm-8">
            <textarea
              class="form-control"
              id="contactComments"
              placeholder="Required"
              formControlName="comments"
            ></textarea>
          </div>
        </div>

        <button type="submit" class="ds-button" [disabled]="disableSubmit">
          Send
        </button>
      </form>
    </ng-container>

    <ng-container *ngIf="isSent">
      We have received your information. Thanks for contacting us. If your
      message required a response, someone will reach out to you as soon as
      possible.
    </ng-container>
  `,
  styles: [
    `
      :host {
        // color: black;
        background-color: var(--ds-main-content-background-color);
      }
      h1 {
        font-size: 2em;
        margin-bottom: 1em;
      }

      .ds-button {
        float: right;
      }

      .has-error {
        color: red;
      }

      .has-error textarea {
        border-color: red;
      }

      .icon {
        height: 20px;
        width: 20px;
        margin-right: 8px;
      }

      .info {
        display: flex;
        flex-direction: column;
        align-content: center;
        justify-content: space-around;
        width: 100%;
      }

      a {
        display: flex;
        font-weight: 400;
        vertical-align: middle;
        user-select: none;
        text-decoration: none;
        border-bottom-width: 1px;
        border-bottom-style: solid;
        border-bottom-color: transparent;
        transition: border 500ms ease-out;
        opacity: 0.75;
      }
      a:hover {
        opacity: 1;
      }
    `,
  ],
})
export class ContentBlockContactFormComponent implements OnInit {
  answers: FormGroup;
  isSent = false;
  isSending = false;
  entry: WebsiteEmailEntry;

  get websiteContact(): WebsiteContact {
    return this.entry.websiteContact;
  }
  // inventoryItem: Inventory;
  // location: RetailLocation;

  socialAccounts = [
    { name: 'twitter', icon: '/icons/brands/twitter.svg' },
    { name: 'facebook', icon: '/icons/brands/facebook.svg' },
    { name: 'linkedIn', icon: '/icons/brands/linkedin.svg' },
    { name: 'instagram', icon: '/icons/brands/instagram.svg' },
  ];

  constructor(
    @Inject(DS_MODAL_DATA) public data: WebsiteEmailEntry,
    private formBuilder: FormBuilder,
    private weeService: WebsiteEmailEntryService
  ) {
    if (!this.data || !this.data.type) {
      console.warn('Form Not Setup Correctly');
    }
    this.entry = data;

    if (this.entry.answers) {
      this.answers.patchValue(this.entry.answers);
    }
  }

  ngOnInit() {
    this.answers = this.formBuilder.group({
      name: null,
      emailAddress: [null, Validators.email],
      phoneNumber: null,
      comments: [null, Validators.required],
    });
  }

  send() {
    this.isSending = true;
    const entry = {
      ...this.entry,
      answers: this.answers.value,
    };
    const { type, locationId, inventoryId, websiteContactId } = this.entry;
    const answers = this.answers.value;
    if (type === WebsiteEmailEntryType.INVENTORY) {
      answers.stockNumber = this.entry.inventoryItem.stockNumber;
    }
    this.weeService
      .create({
        type,
        locationId,
        inventoryId,
        websiteContactId,
        answers,
      })
      .subscribe(
        () => (this.isSent = true),
        () => null,
        () => (this.isSending = false)
      );
  }

  get disableSubmit() {
    return this.isSending || this.answers.invalid;
  }

  get title() {
    if (!this.entry) {
      return '';
    } else if (this.entry.type === WebsiteEmailEntryType.CONTACT) {
      return `Contact ${this.entry.websiteContact.name}`;
    } else if (this.entry.type === WebsiteEmailEntryType.INVENTORY) {
      return `Inquire About: ${this.entry.inventoryItem.displayName}`;
    } else if (this.entry.type === WebsiteEmailEntryType.LOCATION) {
      return `Contact ${this.entry.location.name}`;
    } else {
      console.warn('Error on title');
      return '';
    }
  }

  getPhoneIcon(type: PhoneNumberType) {
    return phoneIcons[type] || '/icons/phone.svg';
  }
}
