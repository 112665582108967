import { Overlay, OverlayConfig, OverlayRef } from '@angular/cdk/overlay';
import { ComponentPortal, Portal, PortalInjector } from '@angular/cdk/portal';
import {
  Component,
  HostListener,
  Inject,
  Injectable,
  InjectionToken,
  Injector,
  OnInit,
} from '@angular/core';

import { Subject } from 'rxjs';

export class ModalRef<TResult> {
  onClose = new Subject<TResult>();

  constructor(private overlayRef: OverlayRef) {}

  close(result: TResult = null): void {
    this.onClose.next(result);
    this.onClose.complete();
    this.overlayRef.dispose();
  }
}

// export interface ModalOptions {
//   component: any;
//   data?: any;
// }

export const DS_MODAL_DATA = new InjectionToken('DS_MODAL_DATA');

@Injectable({
  providedIn: 'root',
})
export class ModalService {
  constructor(private overlay: Overlay, private injector: Injector) {}

  open<TResult = any, TData = any>(component: any, data?: TData) {
    const overlayRef = this.createOverlay();
    const modalRef = new ModalRef<TResult>(overlayRef);
    const injector = this.createInjector(component, data, modalRef);
    const componentPortal = new ComponentPortal(ModalComponent, null, injector);

    overlayRef.attach(componentPortal);
    overlayRef.backdropClick().subscribe(() => modalRef.close());

    return modalRef;
  }

  private createOverlay() {
    const overlayConfig = this.getOverlayConfig();
    return this.overlay.create(overlayConfig);
  }

  private getOverlayConfig(): OverlayConfig {
    const positionStrategy = this.overlay
      .position()
      .global()
      .centerHorizontally()
      .centerVertically();

    const overlayConfig = new OverlayConfig({
      hasBackdrop: true,
      backdropClass: 'ds-modal-backdrop',
      panelClass: 'ds-overlay-pane',
      scrollStrategy: this.overlay.scrollStrategies.block(),
      positionStrategy,
    });

    return overlayConfig;
  }

  private createInjector<TResult, TData>(
    component: any,
    data: TData,
    modalRef: ModalRef<TResult>
  ): PortalInjector {
    const injectionTokens = new WeakMap();
    injectionTokens.set(ModalRef, modalRef);
    injectionTokens.set(DS_MODAL_COMPONENT, component);
    injectionTokens.set(DS_MODAL_DATA, data);
    return new PortalInjector(this.injector, injectionTokens);
  }
}

export const DS_MODAL_COMPONENT = new InjectionToken('DS_MODAL_COMPONENT');

@Component({
  selector: 'ds-modal',
  template: `
    <div class="ds-modal-panel">
      <button class="ds-modal-close" (click)="close()">&#x2715;</button>
      <div class="ds-modal-content">
        <ng-template [cdkPortalOutlet]="modalPortal"></ng-template>
      </div>
    </div>
  `,
  styles: [
    `
      :host {
        display: block;
        width: 100%;
        position: relative;
        background-color: var(--ds-main-content-background-color);
        color: var(--ds-text-color);
        border: 1px solid var(--ds-text-color);
        border-radius: 5px;
      }

      .ds-modal-panel {
        max-height: 65vh;
        overflow: auto;
        padding: 24px;
      }

      .ds-modal-close {
        position: absolute;
        top: 7px;
        right: 10px;

        border: none;
        background: none;
        cursor: pointer;

        opacity: 0.3;
        font-size: 18px;
        color: var(--ds-text-color);
      }

      .ds-modal-close:hover {
        opacity: 1;
      }
    `,
  ],
})
export class ModalComponent implements OnInit {
  modalPortal: Portal<any>;

  constructor(
    @Inject(DS_MODAL_COMPONENT) private component: any,
    private modalRef: ModalRef<any>
  ) {}

  ngOnInit() {
    this.modalPortal = new ComponentPortal(this.component);
  }

  @HostListener('document:keydown.escape')
  close() {
    this.modalRef.close();
  }
}
