import { Component } from '@angular/core';
import { TContentBlock } from '@ds/interfaces';

@Component({
  selector: 'ds-cb-missing',
  template: ` <div>!! Missing !!</div> `,
})
export class ContentBlockMissingComponent {
  block: TContentBlock;

  constructor() {}

  init(block: TContentBlock) {
    this.block = block;
  }
}
