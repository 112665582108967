import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { ContentBlockInventoryService } from './content-block-inventory.service';
import { tap } from 'rxjs/operators';
import { SectionContentBlock } from '@ds/interfaces';

@Component({
  selector: 'ds-cb-inventory-browse',
  template: `
    <ds-cb-section [block]="sectionConfig">
      <ds-cb></ds-cb>
      <div class="ds-row">
        <div class="ds-col-md-3" *ngIf="showSearch$ | async">
          <ds-cb-inventory-search-form
            [params]="searchParams$ | async"
            [config]="config | async"
            (update)="updateFormField($event)"
          ></ds-cb-inventory-search-form>
          <ds-cb-link
            [route]="['/', slug, {}]"
            role="button"
            text="Reset Search"
          >
          </ds-cb-link>
        </div>
        <div class="ds-col" *ngIf="inventory$ | async as inventory">
          <div class="ds-row">
            <div class="ds-col-md-6">
              <h2>Inventory List: {{ inventory.total }} Results</h2>
            </div>
            <div
              class="ds-col d-flex justify-content-center justify-content-md-end"
            >
              <ds-cb-pagination
                [previousPageRoute]="previousPage()"
                [nextPageRoute]="nextPage()"
                [currentPage]="inventory.currentPage"
                [totalPages]="inventory.totalPages"
              ></ds-cb-pagination>
            </div>
          </div>
          <div class="ds-row-sc">
            <ds-cb-inventory-list
              *ngIf="inventory.results.length; else noResults"
              [slug]="slug"
              [content]="inventory.results"
            ></ds-cb-inventory-list>
            <ng-template #noResults>
              <div class="ds-no-results">No Results</div>
            </ng-template>
          </div>
          <div class="d-flex justify-content-center justify-content-md-end">
            <ds-cb-pagination
              [previousPageRoute]="previousPage()"
              [nextPageRoute]="nextPage()"
              [currentPage]="inventory.currentPage"
              [totalPages]="inventory.totalPages"
            ></ds-cb-pagination>
          </div>
        </div>
      </div>
    </ds-cb-section>
  `,
  styles: [
    `
      :host {
        display: block;
      }

      ds-cb-inventory-pagination {
        float: right;
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ContentBlockInventoryBrowseComponent implements OnInit {
  inventory$ = this.cbInvService.inventory$;
  searchParams$ = this.cbInvService.routeParams$;
  config = this.cbInvService.invConfig$;
  slug = this.cbInvService.inventoryPageSlug;
  showSearch$ = this.cbInvService.showSearch$;
  isLegacy = this.cbInvService.isLegacySite;

  sectionConfig: SectionContentBlock = new SectionContentBlock();

  constructor(private cbInvService: ContentBlockInventoryService) {}

  ngOnInit() {
    if (!this.isLegacy) {
      this.sectionConfig.config.outerBackgroundColor = '#626262';
      this.sectionConfig.config.titlePosition = 'top-left';
      this.sectionConfig.config.title = this.cbInvService.pageData.title;
    }
  }

  updateFormField(val) {
    this.cbInvService.updateStore(val);
  }

  previousPage() {
    return this.cbInvService.previousPage();
  }

  nextPage() {
    return this.cbInvService.nextPage();
  }
}
