import {
  Component,
  EventEmitter,
  ViewChild,
  TemplateRef,
  Output,
  ContentChildren,
  QueryList,
  Input,
  ElementRef,
} from '@angular/core';
import { DsMenuItemDirective } from './ds-menu-item.directive';
import { Observable, combineLatest } from 'rxjs';
import { startWith, map, mergeMap } from 'rxjs/operators';

@Component({
  selector: 'ds-menu',
  template: `<ng-template>
      <div class="ds-menu" [ngClass]="classList">
        <div class="ds-menu-content"><ng-content></ng-content></div>
      </div>
    </ng-template>

    <div #portalOutlet></div> `,
  styles: [
    `
      ::ng-deep .ds-menu-item {
        padding: 16px;
        background: transparent;
        border: none;
        display: block;
        width: 100%;
      }
      ::ng-deep .ds-menu-item:hover {
        background-color: rgba(0, 0, 0, 0.04);
      }
      ::ng-deep .ds-menu {
        background-color: #fff;
        box-shadow: 0 3px 5px -1px rgba(0, 0, 0, 0.2),
          0 5px 8px 0 rgba(0, 0, 0, 0.14), 0 1px 14px 0 rgba(0, 0, 0, 0.12);
      }
      ::ng-deep .ds-menu a {
        color: #333;
      }
      ::ng-deep .ds-menu-content {
        padding: 0;
      }
    `,
  ],
  exportAs: 'dsMenu',
})
export class DsMenuComponent {
  public classList: any = {};

  @Input('class')
  set panelClass(classes: string) {
    if (classes && classes.length) {
      this.classList = classes
        .split(' ')
        .reduce((acc, className) => ({ ...acc, [className]: true }), {});
    }
  }
  @Output()
  closed = new EventEmitter<any>();

  @ViewChild(TemplateRef) templateRef: TemplateRef<any>;
  @ViewChild('portalOutlet') portalOutlet: ElementRef<HTMLElement>;

  @ContentChildren(DsMenuItemDirective, { descendants: true })
  directDescendantItems = new QueryList<DsMenuItemDirective>();

  get isHovered() {
    const itemChanges = this.directDescendantItems.changes as Observable<
      QueryList<DsMenuItemDirective>
    >;

    return itemChanges
      .pipe(
        startWith(this.directDescendantItems),
        mergeMap((items) => combineLatest(items.map((item) => item.isHovered)))
      )
      .pipe(map((results) => results.some((isHovered) => !!isHovered)));
  }

  close() {
    this.closed.emit();
  }
}
