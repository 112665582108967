import { Component, Input } from '@angular/core';
import { Page } from '@ds/interfaces';

@Component({
  selector: 'ds-cb-page-index',
  template: `
    <ds-cb-section>
      <div class="ds-row">
        <div class="ds-col">
          <div class="entry-list-grid">
            <ds-cb-page-card
              *ngFor="let entry of pageData.children; trackBy: trackByFn"
              [entry]="entry"
            ></ds-cb-page-card>
          </div>
        </div>
      </div>
    </ds-cb-section>
  `,
  styles: [
    `
      .entry-list-grid {
        display: grid;
        grid-gap: 10px;
        grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));

        grid-template-rows: 500px;
        grid-auto-rows: 400px;
      }
    `,
  ],
})
export class ContentBlockPageIndexComponent {
  @Input() pageData: Page;

  trackByFn(index, model) {
    return model.id;
  }
}
