import { Component, Inject, OnInit } from '@angular/core';

import { take, tap } from 'rxjs/operators';

import { AuthService, UserService } from '@ds/api-services';
import { DS_MODAL_DATA, ModalRef } from '@ds/core';
import { Profile, User } from '@ds/interfaces';

import { ContentBlockFileStackService } from '../content-block-form/content-block-form-upload.component';

@Component({
  selector: 'ds-cb-staff-detail-modal',
  template: `
    <div class="staff-detail-container ds-row" *ngIf="user; else loadingTpl">
      <div class="staff-detail-photo ds-col">
        <ds-cb-image
          [imageHandle]="user.photoHandle"
          size="large"
        ></ds-cb-image>
        <div
          style="display: flex; justify-content: space-evenly"
          *ngIf="allowPhotoEditing"
        >
          <button
            (click)="removePhoto(user)"
            class="ds-button alt"
            *ngIf="isOwner && user.photoHandle"
          >
            Remove Photo
          </button>
          <button class="ds-button" (click)="changePhoto(user)" *ngIf="isOwner">
            Replace Photo
          </button>
        </div>
      </div>
      <div class="staff-detail-info ds-fluid-text ds-col">
        <h2 class="ds-fluid-text-lg">{{ user.displayName }}</h2>
        <div *ngIf="user.title && user.title.length">{{ user.title }}</div>
        <div>{{ user.location.name }}</div>
        <div>
          <p *ngIf="user.props && user.props.bio">{{ user.props.bio }}</p>
        </div>
        <table class="ds-table">
          <tr>
            <th>Email Address</th>
            <td>
              <a [href]="getUserEmailAddressLink(user)">{{
                user.emailAddress
              }}</a>
            </td>
          </tr>
          <tr *ngFor="let ph of user.phoneNumbers">
            <th class="ph-type">{{ ph.type }}</th>
            <td>
              {{ ph.number }} <span class="extension">{{ ph.extension }}</span>
            </td>
          </tr>
        </table>
      </div>
    </div>
    <ng-template #loadingTpl>
      <div>Loading</div>
    </ng-template>
  `,
  styles: [
    `
      :host {
        display: block;
        max-width: 95vw;
        width: 600px;
      }
      .staff-detail-container {
      }
      .staff-detail-container > div {
      }
      .staff-detail-photo {
        text-align: center;
      }
      .staff-detail-info {
        display: flex;
        flex-direction: column;
      }
      .staff-detail-info > * {
        margin-bottom: 10px;
      }
      th {
        white-space: nowrap;
        text-align: left;
      }
      .ph-type {
        text-transform: capitalize;
      }
    `,
  ],
})
export class ContentBlockStaffDetailModalComponent implements OnInit {
  userId: number;

  user: User;

  currentUser: Profile;

  isOwner = false;

  allowPhotoEditing = false;

  constructor(
    private uploadService: ContentBlockFileStackService,
    private authService: AuthService,
    private userService: UserService,
    private modalRef: ModalRef<string>,
    @Inject(DS_MODAL_DATA)
    public data: {
      user?: User;
      userId?: number;
    }
  ) {
    if (data.user && data.userId) {
      console.warn('no user id found');
      return;
    }
    this.currentUser = this.authService.session.profile;
    this.userId = data.userId || data.user.id;

    this.fetchUser();
  }

  fetchUser() {
    this.userService
      .findOne(this.userId)
      .pipe(
        tap((user) => (this.isOwner = user.id === this.currentUser.id)),
        take(1)
      )
      .subscribe((val) => (this.user = val));
  }

  getUserEmailAddressLink(user: User) {
    return `mailto:${user.emailAddress}`;
  }

  ngOnInit(): void {}

  changePhoto() {
    this.uploadService
      .uploadFile({ accept: ['image/*'] })
      .subscribe((files) => {
        console.log(files);
        this.userService.updateIcon(this.user, files[0]).subscribe((user) => {
          this.fetchUser();
        });
      });
  }

  removePhoto() {
    this.userService.destroyIcon(this.user).subscribe((user) => {
      this.fetchUser();
    });
  }
}
