// bunch of commented code incase we did not want to load the player
// at first but instead load the thumbnail. (faster page loads)

import {
  Component,
  Input,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
} from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { VideoContentBlock } from '@ds/interfaces';
import { ModalService } from '@ds/core';
import { ContentBlockBlogVideoModalComponent } from './content-block-video-modal.component';

// http://i3.ytimg.com/vi/erLk59H86ww/maxresdefault.jpg

@Component({
  selector: 'ds-cb-video',
  template: `
    <img
      dsImage
      [imageHandle]="block.config.imageHandle"
      [imageSize]="block.config.imageSize"
      (click)="openVideo($event)"
    />
    <a href="" click="openVideo($event">{{ block.config.text }}</a>
    <!-- <video width="490" height="282" controls>
      <source [src]="source" type="video/mp4" />
      Your browser does not support the video tag.
    </video> -->
    <div class="edit-shield"></div>
  `,
  styles: [
    `
      :host {
        /* position: relative;
        padding-bottom: 56.25%;
        padding-top: 30px;
        height: 0; */
        display: flex;
        flex-direction: column;
        text-align: center;
        height: 100%;
        width: 100%;
      }
      img {
        background-color: var(--ds-cb-image-background-color);
        border-color: var(--ds-cb-image-border-color);
        border-radius: 4px;
        border-style: solid;
        border-width: 1px;
        padding: 4px;
        max-height: 100%;
        max-width: 100%;
        object-fit: contain;
        vertical-align: middle;
        cursor: pointer;
        margin-bottom: 5px;
      }
      a {
        font-weight: bold;
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ContentBlockVideoComponent {
  block: VideoContentBlock;

  isOn = false;

  constructor(
    private sanitizer: DomSanitizer,
    private modalService: ModalService
  ) {}

  init(block: VideoContentBlock) {
    this.block = block;
  }
  get source() {
    return (
      this.block &&
      this.block.config &&
      this.sanitizer.bypassSecurityTrustResourceUrl(this.block.config.url)
    );
  }

  openVideo($event: MouseEvent) {
    $event.preventDefault();
    this.modalService.open<void, { url: string }>(
      ContentBlockBlogVideoModalComponent,
      {
        url: this.block.config.url,
      }
    );
  }

  // default image
  // https://cdn.filestackcontent.com/AvLVW6vvuTjCqG5oZVY5Cz/resize=width:300,height:300,fit:max/compress/IdAzdi3cQ7KpNyOCC33R
}
