import {
  Component,
  Input,
  OnInit,
  ChangeDetectionStrategy,
} from '@angular/core';

@Component({
  selector: 'ds-cb-load-bar',
  template: `
    <div class="load-bar" [class.active]="loading">
      <div class="bar"></div>
      <div class="bar"></div>
      <div class="bar"></div>
    </div>
  `,
  styles: [
    `
      :host {
        height: 6px;
        width: 100%;
        display: block;
        background-color: var(--ds-cb-load-bar-background-color-1);
      }
      .load-bar {
        position: relative;
        width: 100%;
        height: 6px;
        opacity: 0;
        transition: opacity 0.5s;
      }
      .load-bar.active {
        opacity: 1;
      }
      .bar {
        content: '';
        display: inline;
        position: absolute;
        width: 0;
        height: 100%;
        left: 50%;
        text-align: center;
      }
      .bar:nth-child(1) {
        background-color: var(--ds-cb-load-bar-background-color-1);
        animation: loading 3s linear infinite;
      }
      .bar:nth-child(2) {
        background-color: var(--ds-cb-load-bar-background-color-2);
        animation: loading 3s linear 1s infinite;
      }
      .bar:nth-child(3) {
        background-color: var(--ds-cb-load-bar-background-color-3);
        animation: loading 3s linear 2s infinite;
      }
      @keyframes loading {
        from {
          left: 50%;
          width: 0;
          z-index: 100;
        }
        33.3333% {
          left: 0;
          width: 100%;
          z-index: 10;
        }
        to {
          left: 0;
          width: 100%;
        }
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ContentBlockLoadBarComponent {
  @Input() loading = false;
}
