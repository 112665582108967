import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit,
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { LocationService } from '@ds/api-services';
import { filterNullOrUndefined } from '@ds/core';
import {
  BlockTypeLink,
  CardContentBlock,
  LinkContentBlock,
  LinkTypes,
  Page,
  RetailLocation,
  SectionContentBlock,
} from '@ds/interfaces';
import { Dictionary, groupBy } from 'lodash';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
  selector: 'ds-cb-location-list',
  template: `
    <ds-cb-section [block]="sectionConfig">
      <div class="ds-row">
        <div class="ds-col-sm-6">
          <ds-cb-content-block-location-map
            *ngIf="locations$ | async as locations"
            [locations]="locations"
            (stateSelected)="stateSelected($event)"
            (backSelected)="stateSelected(null)"
          ></ds-cb-content-block-location-map>
        </div>
        <div class="ds-col-sm-6">
          <ds-cb-card
            *ngFor="let state of states$ | async"
            [showContent]="!stateFilter || state === stateFilter"
            [block]="stateBlock(state)"
          >
          </ds-cb-card>
        </div>
      </div>
    </ds-cb-section>
  `,
  styles: [
    `
      :host {
        display: block;
      }
      .hidden {
        display: none;
      }
      .fade {
        opacity: 0;
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ContentBlockLocationListComponent implements OnInit {
  // block: LocationListContentBlock;

  @Input() locations$: Observable<RetailLocation[]>;
  @Input() isLegacy = true;

  states$: Observable<string[]>;

  stateMap: Dictionary<RetailLocation[]>;

  page: Page;

  stateFilter: string = null;

  sectionConfig: SectionContentBlock = new SectionContentBlock();

  constructor(
    private locationService: LocationService,
    private route: ActivatedRoute
  ) {
    this.page = this.route.snapshot.data.pageData;
  }

  ngOnInit(): void {
    this.states$ = this.locations$.pipe(
      filterNullOrUndefined(),
      map((locations) => {
        this.stateMap = groupBy(locations, (l) => l.address.state);
        return Object.keys(this.stateMap).sort();
      })
    );

    if (!this.isLegacy) {
      this.sectionConfig.config.outerBackgroundColor = '#626262';
      this.sectionConfig.config.titlePosition = 'top-left';
      this.sectionConfig.config.title = 'Locations';
    }
  }

  // init(block: LocationListContentBlock) {
  //   // this.block = block;
  // }

  stateBlock(state: string): CardContentBlock {
    return {
      type: 'CARD',
      config: {
        header: state,
        showBorder: true,
        backgroundColor: '#fff',
      },
      content: this.createListContent(this.stateMap[state]),
    };
  }

  createListContent(locations: RetailLocation[]): LinkContentBlock[] {
    return locations.map((loc) => ({
      id: 'location' + loc.id,
      type: BlockTypeLink,
      linkPageId: this.page.id,
      linkToPage: this.page,
      locationId: loc.id,
      location: loc,
      config: {
        linkStyle: 'link',
        linkType: LinkTypes.location,
        text: loc.name,
        color: this.isLegacy ? null : '#000',
      },
    }));
  }

  stateSelected(state: string) {
    if (!state) {
      this.stateFilter = null;
    }

    this.stateFilter = state;
  }
}
