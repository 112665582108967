import { Component } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';

@Component({
  selector: 'ds-public-root',
  template: '<router-outlet></router-outlet>',
  styles: [],
})
export class PublicAppComponent {
  constructor(private router: Router) {}
}
