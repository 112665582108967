import { Component, OnInit, Input, HostListener } from '@angular/core';
import { User } from '@ds/interfaces';
import { ModalService } from '@ds/core';
import { ContentBlockStaffDetailModalComponent } from './content-block-staff-detail-modal.component';

@Component({
  selector: 'ds-cb-staff-card',
  template: `<div class="user-photo">
      <img
        class="photo"
        dsImage
        [imageHandle]="user.photoHandle"
        imageSize="icon"
      />
    </div>
    <div class="user-details">
      <div>
        <strong>{{ user.displayName }}</strong>
        <br />{{ user.title }}
        <div>{{ user.location.address.city }}</div>
      </div>
    </div>`,
  styles: [
    `
      :host {
        display: flex;
        cursor: pointer;
      }
      .user-photo {
        min-width: 50px;
        text-align: center;
      }
      .user-details {
        margin-left: 5px;
        /* padding: 5px; */
      }
      .photo {
        width: 5rem;
        height: 5rem;
        border-radius: 15%;
        padding: 3px;
        border: 1px solid var(--ds-cb-contact-card-photo-border-color);
        object-fit: cover;
        object-position: center;
      }
    `,
  ],
})
export class ContentBlockStaffCardComponent implements OnInit {
  @Input() user: User;

  @HostListener('click', ['$event'])
  onClick(e: MouseEvent) {
    e.preventDefault();
    this.openProfile();
  }

  constructor(private modalService: ModalService) {}

  ngOnInit(): void {}

  openProfile() {
    this.modalService.open<void, ContentBlockStaffDetailModalComponent['data']>(
      ContentBlockStaffDetailModalComponent,
      {
        userId: this.user.id,
      }
    );
  }
}
