import { Pipe, PipeTransform } from '@angular/core';
import { formatDate } from '@angular/common';
/*
 * prints out '[ None ]' or specified placeholder when no content exists.
 */
@Pipe({ name: 'noneDate' })
export class NoneDatePipe implements PipeTransform {
  transform(
    value: string | number | Date,
    placeholder: string | number = 'none'
  ): string | number | boolean {
    if (value === null || value === undefined) {
      return `~ ${placeholder} ~`;
    }
    if (typeof value === 'string' && value.length === 0) {
      return `~ ${placeholder} ~`;
    }
    return formatDate(value, 'MMM dd yyyy', 'en-US');
  }
}
