import { Component, Inject } from '@angular/core';
import { ModalRef, DS_MODAL_DATA } from '@ds/core';
import { BlogConfigTag } from '@ds/interfaces';

@Component({
  selector: 'ds-cb-blog-tags-modal',
  template: `
    <div class="tags">
      <a
        class="tag"
        *ngFor="let tag of data.tags"
        [routerLink]="['/', data.slug, { tag: tag.label }]"
        (click)="tagClicked($event)"
      >
        {{ tag.label }}
        <span class="badge badge-pill badge-secondary">{{ tag.count }}</span>
      </a>
    </div>
  `,
  styles: [
    `
      .tags {
        border: 1px solid var(--ds-cb-card-border-color);
        width: 100%;
      }
      .tag:not(:last-child) {
        border-bottom: 1px solid var(--ds-cb-card-border-color);
      }

      .tag {
        padding: 10px 15px;
        margin-bottom: -1px;
        display: flex;
        justify-content: space-between;
        text-decoration: none;
        align-items: flex-start;
      }
    `,
  ],
})
export class ContentBlockBlogTagsModalComponent {
  constructor(
    private modalRef: ModalRef<string>,
    @Inject(DS_MODAL_DATA)
    public data: {
      tags: BlogConfigTag[];
      slug: string;
    }
  ) {}

  tagClicked($event) {
    this.modalRef.close();
  }
}
