export enum PhoneNumberType {
  phone = 'phone',
  cell = 'cell',
  work = 'work',
  fax = 'fax',
  personal = 'personal',
  toll_free = 'toll_free',
  business = 'business',
  direct_dial = 'direct_dial',
}

export const phoneIcons = {
  [PhoneNumberType.phone]: '/icons/phone.svg',
  [PhoneNumberType.cell]: '/icons/mobile.svg',
  [PhoneNumberType.work]: '/icons/office-phone.svg',
  [PhoneNumberType.business]: '/icons/office-phone.svg',
  [PhoneNumberType.toll_free]: '/icons/office-phone.svg',
  [PhoneNumberType.fax]: '/icons/fax.svg',
};

export interface PhoneNumber {
  type: PhoneNumberType;
  number: string;
  extension?: string;
}

export type PhoneNumbers = PhoneNumber[];
