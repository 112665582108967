import { Input, Component, ChangeDetectionStrategy } from '@angular/core';
import { BlogSearchParams } from './content-block-blog.service';

@Component({
  selector: 'ds-cb-blog-active-filters',
  template: `
    <ng-container *ngIf="params.tag || (params.year && params.month)">
      <div class="ds-alert ds-alert--warning">
        <div>
          Filtered: <strong *ngIf="params.tag">{{ params.tag }}</strong>
          <strong *ngIf="params.month && params.year"
            >{{ monthNames[params.month - 1] }}, {{ params.year }}</strong
          >
        </div>

        <a class="ds-button" href="" [routerLink]="['./', {}]"> Remove </a>
      </div>
    </ng-container>
  `,
  styles: [
    `
      :host .ds-alert {
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ContentBlockBlogActiveFiltersComponent {
  @Input()
  params: BlogSearchParams;

  monthNames = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];
}
