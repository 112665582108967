import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  HostListener,
  AfterViewInit,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {
  Page,
  PageTypes,
  SiteUpdateMessageEvent,
  SiteUpdateMessageTypes,
  RetailLocation,
} from '@ds/interfaces';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { ContentBlockDataService } from '../../services/content-block-data.service';
import {
  ContentBlockPageService,
  MetaPageProps,
} from '../../services/content-block-page.service';
import { LocationService } from '@ds/api-services';
import { ContentBlockSiteService } from '../../services/content-block-site.service';

@Component({
  selector: 'ds-cb-page',
  template: `
    <ng-container *ngIf="pageData">
      <ng-container *ngIf="pageData.config.showTitleBar">
        <ds-cb-title-bar [config]="titleBarConfig$ | async"></ds-cb-title-bar>
      </ng-container>
      <ng-container [ngSwitch]="pageData.type">
        <ds-cb-inventory
          *ngSwitchCase="pageTypes.INVENTORY"
          [isLegacy]="isLegacy"
          [pageData]="pageData"
        >
        </ds-cb-inventory>
        <ds-cb-blog *ngSwitchCase="pageTypes.BLOG_INDEX"></ds-cb-blog>
        <ds-cb-location
          *ngSwitchCase="pageTypes.LOCATION_INDEX"
          [pageData]="pageData"
        ></ds-cb-location>
        <ds-cb-location-detail
          *ngSwitchCase="pageTypes.LOCATION_DETAIL"
          [location]="currentLocation$ | async"
        ></ds-cb-location-detail>
        <ds-cb-staff-browser
          *ngSwitchCase="pageTypes.STAFF_BROWSER"
        ></ds-cb-staff-browser>
        <ds-cb-page-index
          *ngSwitchCase="pageTypes.PAGE_INDEX"
          [pageData]="pageData"
        ></ds-cb-page-index>

        <div *ngSwitchCase="pageTypes.REDIRECT">
          <ds-cb-section cbSectionTitle="Redirect">
            <p>
              This page will automatically redirect you to
              <a [href]="pageData.config.url" target="_blank">{{
                pageData.config.url
              }}</a
              >. Clicking this link will open in new window.
            </p>
          </ds-cb-section>
        </div>
        <ng-container *ngSwitchDefault>
          <ds-cb
            *ngFor="let block of content | async; trackBy: trackByFn"
            [block]="block"
            class="main"
          ></ds-cb>
        </ng-container>
      </ng-container>
    </ng-container>
  `,
  styles: [
    `
      :host {
        display: block;
        position: relative;
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [ContentBlockDataService, ContentBlockPageService],
})
export class ContentBlockPageComponent implements AfterViewInit {
  pageData: Page;
  titleBarConfig$: Observable<MetaPageProps>;
  pageTypes = PageTypes;
  currentLocation$: Observable<RetailLocation>;

  content = this.cbDataService.getChildren(null);

  isLegacy = this.cbPageService.isLegacySite;

  @HostListener('window:message', ['$event'])
  onMessage(e: SiteUpdateMessageEvent) {
    if (!e.data || !e.data.type) return;
    console.log('[New Message Event]', e.data);
    const msg = e.data;
    switch (msg.type) {
      case SiteUpdateMessageTypes.PUBLIC_NAVIGATE_PAGE:
        this.router.navigate(['/', msg.slug]);
        break;
      case SiteUpdateMessageTypes.PUBLIC_UPDATE_PAGE:
        if (msg.pageData.id !== this.pageData.id) return;
        this.cbPageService.updatedPageData.next(msg.pageData);
        this.cbDataService.updatePage(msg.pageData);
        break;
      case SiteUpdateMessageTypes.PUBLIC_UPDATE_SITE:
        console.warn('Site Update Not done yet');
        break;
      case SiteUpdateMessageTypes.PUBLIC_UPDATE_CB:
        this.cbDataService.update(msg.content);
        break;
      case SiteUpdateMessageTypes.PUBLIC_ADD_CB:
        this.cbDataService.add(msg.content);
        break;
      case SiteUpdateMessageTypes.PUBLIC_SAVE_PAGE:
        if (msg.pageId !== this.pageData.id) return;
        this.cbDataService.savePage(this.pageData.id);
        break;
      case SiteUpdateMessageTypes.PUBLIC_HIGHLIGHT_CB:
        this.cbDataService.setHighlighted(msg.cbId);
        break;
      case SiteUpdateMessageTypes.PUBLIC_REMOVE_CB:
        this.cbDataService.remove(msg.content);
        break;
      case SiteUpdateMessageTypes.PUBLIC_UPDATE_SITE_THEME:
        this.cbSiteService.loadSiteTheme(msg.siteData.config.theme);
        break;
      default:
        console.log(
          '[ContentBlockPageComponent]: No Action for ',
          msg.type,
          msg
        );
        break;
    }
  }

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private cbDataService: ContentBlockDataService,
    private cbPageService: ContentBlockPageService,
    private locationService: LocationService,
    private cd: ChangeDetectorRef,
    private cbSiteService: ContentBlockSiteService
  ) {
    this.cbPageService.pageData$.subscribe((pageData) => {
      this.pageData = pageData;
      this.cbDataService.registerContent(pageData);
      if (pageData.type === PageTypes.LOCATION_DETAIL) {
        if (pageData.config.physicalLocationId[0]) {
          this.currentLocation$ = locationService.findOne(
            pageData.config.physicalLocationId[0]
          );
        }
      } else if (pageData.type === PageTypes.REDIRECT && pageData.config?.url) {
        setTimeout((window.location.href = pageData.config.url), 2000);
      }
      this.cd.markForCheck();
    });

    this.titleBarConfig$ = this.cbPageService.pageTitleBarConfig$;
  }

  ngAfterViewInit() {
    // this.cbDataService.
  }

  trackByFn(index, model) {
    return model.id;
  }
}
