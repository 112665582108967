import {
  ChangeDetectionStrategy,
  Component,
  HostBinding,
  Input,
} from '@angular/core';
import { ModalService } from '@ds/core';
import {
  ContactCardContentBlock,
  WebsiteContact,
  WebsiteEmailEntry,
  WebsiteEmailEntryType,
  PhoneNumberType,
  phoneIcons,
  ContactCardType,
} from '@ds/interfaces';
import { ContentBlockContactFormComponent } from './content-block-contact-form.component';

const defaultContact: WebsiteContact = {
  name: '',
  title: '',
  phoneNumbers: [],
  photoHandle: null,
  emailAddress: '',
  externalLinks: {},
};

@Component({
  selector: 'ds-cb-contact-card',
  template: `
    <ng-container *ngIf="contact && !isSquare">
      <div class="photo-container">
        <img
          class="photo"
          dsImage
          [imageHandle]="contact.photoHandle"
          imageSize="small"
        />
      </div>
      <div class="divider"></div>
      <div class="info">
        <div>
          <div class="name ds-fluid-text">{{ contact.name }}</div>
          <div class="title ds-fluid-text-sm" *ngIf="contact.title">
            -- {{ contact.title }}
          </div>
        </div>
        <div class="contact-button" *ngIf="contact.emailAddress">
          <a href="#" (click)="openContactForm($event, contact)">
            <span class="icon" [inlineSVG]="'/icons/envelope.svg'"></span>
            <span>Email</span>
          </a>
        </div>
        <div
          class="contact-button"
          *ngFor="let phoneNumber of contact.phoneNumbers"
        >
          <a href="tel:{{ phoneNumber.number }}" [title]="phoneNumber.type">
            <span
              class="icon"
              [inlineSVG]="getPhoneIcon(phoneNumber.type)"
            ></span>
            <span>{{ phoneNumber.number }} </span>
          </a>
        </div>
        <ng-container
          class="contact-button social"
          *ngFor="let account of socialAccounts"
        >
          <div
            class="contact-button social ds-fluid-text-xs"
            *ngIf="contact[account.name]"
          >
            <a [href]="contact.externalLinks[account.name]" target="_blank">
              <span class="icon" [inlineSVG]="account.icon"></span>
              <span>{{ account.name }}</span>
            </a>
          </div>
        </ng-container>
      </div>
    </ng-container>
    <ng-container *ngIf="contact && isSquare">
      <div
        class="photo-container"
        role="button"
        (click)="openContactForm($event, contact)"
      >
        <img
          class="photo"
          dsImage
          [imageHandle]="contact.photoHandle"
          imageSize="small"
        />
      </div>
      <div class="info" (click)="openContactForm($event, contact)">
        <div class="name ds-fluid-text">{{ contact.name }}</div>
        <div class="title ds-fluid-text-sm" *ngIf="contact.title">
          {{ contact.title }}
        </div>
      </div>
    </ng-container>
    <div class="edit-shield"></div>
  `,
  styles: [
    `
      :host(.default) {
        padding: 10px;
        background-color: var(--ds-cb-contact-card-background-color);
        border: 1px solid var(--ds-cb-contact-card-border-color);
        border-radius: 5px;
        transition: border 500ms ease-out;
        display: flex;
        width: 100%;
        min-height: 100px;
        position: relative;
      }

      .divider {
        transition: border 500ms ease-out;
        border-left: 2px solid var(--ds-cb-contact-card-border-color);
        margin: 10px;
      }
      :host:hover {
        border: 1px solid var(--ds-cb-contact-card-border-color--hover);
      }
      :host:hover .divider {
        border-left: 2px solid var(--ds-cb-contact-card-border-color--hover);
        margin: 10px;
      }
      :host(.default) .info {
        display: flex;
        flex-direction: column;
        align-content: center;
        justify-content: space-around;
        width: 100%;
      }
      :host(.default) .photo-container {
        min-width: 100px;
        text-align: center;
      }
      :host(.default) .photo {
        width: 8rem;
        height: 8rem;
        border-radius: 25%;
        padding: 3px;
        border: 1px solid var(--ds-cb-contact-card-photo-border-color);
        object-fit: cover;
        object-position: center;
      }
      .contact-button {
        text-align: right;
      }
      .icon {
        height: 20px;
        width: 20px;
        margin-right: 8px;
      }
      a {
        display: flex;
        font-weight: 400;
        vertical-align: middle;
        user-select: none;
        text-decoration: none;
        border-bottom-width: 1px;
        border-bottom-style: solid;
        border-bottom-color: transparent;
        transition: border 500ms ease-out;
        opacity: 0.75;
      }
      a:hover {
        opacity: 1;
      }
      .contact-button a span {
        text-transform: capitalize;
      }
      .name {
        font-weight: bold;
      }

      :host(.square) {
        display: block;
        background-color: var(--ds-cb-contact-card-background-color);
        height: 250px;
        max-width: 250px;
        width: 100%;
        position: relative;
        margin: 0 auto;
      }

      :host(.square) .photo-container {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
      }

      :host(.square) .info {
        display: flex;
        flex-direction: column;
        align-content: center;
        justify-content: space-around;
        width: 100%;
        position: absolute;
        bottom: 0;
        background-color: var(--ds-cb-contact-card-background-color);
        text-align: center;
        padding: 5px;
      }

      :host(.square) img {
        object-fit: cover;
        object-position: center;
        width: 250px;
        height: 250px;
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ContentBlockContactCardComponent {
  @Input() contact: WebsiteContact;
  @Input() @HostBinding('class') type: ContactCardType = 'default';

  _block: ContactCardContentBlock;

  @Input()
  set block(val: ContactCardContentBlock) {
    this.init(val);
  }

  get isSquare() {
    return this.type === 'square';
  }

  socialAccounts = [
    { name: 'twitter', icon: '/icons/brands/twitter.svg' },
    { name: 'facebook', icon: '/icons/brands/facebook.svg' },
    { name: 'linkedIn', icon: '/icons/brands/linkedin.svg' },
    { name: 'instagram', icon: '/icons/brands/instagram.svg' },
  ];

  constructor(private modalService: ModalService) {}

  init(block: ContactCardContentBlock) {
    this._block = block;
    this.contact = block.websiteContact;
    this.type = block.config.cardType || this.type;
  }

  openContactForm($event: MouseEvent, contact: WebsiteContact) {
    $event.preventDefault();
    this.modalService.open<void, WebsiteEmailEntry>(
      ContentBlockContactFormComponent,
      {
        type: WebsiteEmailEntryType.CONTACT,
        websiteContactId: contact.id,
        websiteContact: contact,
      }
    );
  }

  getPhoneIcon(type: PhoneNumberType) {
    return phoneIcons[type] || '/icons/phone.svg';
  }
}
