import { ApiFile } from './filestack.interface';

export interface DsForm {
  id?: number;
  title?: string;
  questions?: DsFormQuestion[];
  isActive?: boolean;
  config?: {
    inboxHeaderLabel?: string;
    inboxHeaderQuestionId?: string;
    inboxSubHeaderLabel?: string;
    inboxSubHeaderQuestionId?: string;
    emails: DsEmailConfig[];
  };
  publicConfig?: {
    postSubmitAction?: 'thank-you' | 'redirect';
    thankYouContent?: string;
    submitButtonText?: string;
  };
}

export enum DsEmailTemplateSendTo {
  LIST = 'LIST',
  CONDITIONAL = 'CONDITIONAL',
  FIELD = 'FIELD',
  SITE = 'SITE',
}

export interface DsEmailConfig {
  name: string;
  subject: string;
  sendTo: DsEmailTemplateSendTo;
  recipients: string[];
  conditionMatch: 'ANY' | 'ALL';
  type: 'LINK' | 'RESPONSES';
  conditions: DsEmailConfigCondition[];
  recipientField: string;
}

export enum DsConditionStringCompareOperator {
  EQUAL = 'EQUAL',
  NOT_EQUAL = 'NOT_EQUAL',
  EMPTY = 'EMPTY',
  NOT_EMPTY = 'NOT_EMPTY',
}

export interface DsEmailConfigCondition {
  questionId?: string;
  compareOperator: DsConditionStringCompareOperator;
  answer?: string | string[];
}

export enum DsFormQuestionTypes {
  HEADER = 'HEADER',
  SHORT_ANSWER = 'SHORT_ANSWER',
  LONG_ANSWER = 'LONG_ANSWER',
  SIMPLE_TEXT = 'SIMPLE_TEXT',
  RICH_TEXT = 'RICH_TEXT',
  DROPDOWN = 'DROPDOWN',
  SINGLE_OPTION = 'SINGLE_OPTION',
  MULTIPLE_OPTIONS = 'MULTIPLE_OPTIONS',
  FILE = 'FILE',
  ADDRESS = 'ADDRESS',
  FULL_NAME = 'FULL_NAME',
  PHONE_NUMBER = 'PHONE_NUMBER',
  HIDDEN = 'HIDDEN',
  EMAIL_ADDRESS = 'EMAIL_ADDRESS',
  DATE = 'DATE',
  SENSITIVE = 'SENSITIVE',
  SIGNATURE = 'SIGNATURE',
}

export interface DsFormQuestion {
  id?: string;
  normId?: string;
  type?: DsFormQuestionTypes;
  prompt?: string;
  richText?: string;
  simpleText?: string;
  headerText?: string;
  options?: string[];
  isRequired?: boolean;
  size?: number;
  config?: {
    fullNameMiddle: boolean;
  };
}

export interface DsFormEntry {
  id?: number;
  siteId?: number;
  formId?: number;
  meta?: {
    referrer?: string;
    ipAddress?: string;
  };
  questions?: DsFormQuestion[];
  answers?: DsFormAnswers;
  recipients?: {
    template: string;
    emailAddresses: string[];
  }[];
  recipientMeta?: {};
}

export interface DsFormAnswers {
  [dsFormQuestionId: string]: DsFormAnswer;
}

export interface DsFormAnswer {
  text?: string;
  fullName?: {
    firstName?: string;
    middleName?: string;
    lastName?: string;
  };
  address?: {
    lineOne?: string;
    lineTwo?: string;
    city?: string;
    state?: string;
    country?: string;
    postalCode?: string;
  };
  files?: ApiFile[];
  image?: string;
}

export function normalizeResponse(val: DsFormAnswer): (string | number)[] {
  if (!val) {
    console.warn('Normalize Values Missing Value');
    return [];
  }

  if (val.fullName) {
    return [val.fullName.firstName, val.fullName.lastName];
  }

  if (val.address) {
    return [
      val.address.lineOne,
      val.address.lineTwo,
      val.address.city,
      val.address.state,
      val.address.postalCode,
      val.address.country,
    ].filter((v) => v);
  }

  if (val.files && val.files.length) {
    return val.files.map((file) => file.url);
  }

  return [val.text];
}
