import { Injectable } from '@angular/core';
import * as filestack from 'filestack-js';
import { NgxGalleryImage, NgxGalleryComponent } from '@kolkov/ngx-gallery';
import { map, tap } from 'rxjs/operators';

import { BehaviorSubject, Observable } from 'rxjs';

// ToDo: need to replace this with injected variable.
const fileStackPublicKey = 'AvLVW6vvuTjCqG5oZVY5Cz';

export const ImageSizes = {
  tiny: 48,
  icon: 100,
  small: 300,
  medium: 600,
  large: 900,
  full: null,
};

export interface LightboxGalleryImage {
  id: Number | String;
  imageHandle: string;
  label: string;
}

@Injectable({
  providedIn: 'root',
})
export class ImageLightboxService {
  private _lightboxImages: BehaviorSubject<LightboxGalleryImage[]> =
    new BehaviorSubject([]);
  private lightboxComponent: NgxGalleryComponent;

  private lightboxImages: LightboxGalleryImage[] = [];

  imagesForLightbox$: Observable<NgxGalleryImage[]> = this._lightboxImages
    .asObservable()
    .pipe(
      map((images) =>
        images.map((image) => ({
          small: this.getUrl(image.imageHandle, ImageSizes.small),
          medium: this.getUrl(image.imageHandle, ImageSizes.medium),
          big: this.getUrl(image.imageHandle),
        }))
      )
    );

  transFormToLightboxImages(images: LightboxGalleryImage[]): NgxGalleryImage[] {
    return images.map((image) => ({
      small: this.getUrl(image.imageHandle, ImageSizes.small),
      medium: this.getUrl(image.imageHandle, ImageSizes.medium),
      big: this.getUrl(image.imageHandle),
    }));
  }

  addImageToLightbox(image: LightboxGalleryImage) {
    this.lightboxImages = [...this.lightboxImages, image];
  }

  removeImageFromLightbox(id: String | Number) {
    this.lightboxImages = this.lightboxImages.filter((i) => i.id !== id);
  }

  openLightbox(id: String | Number) {
    this._lightboxImages.next(this.lightboxImages);
    this.lightboxComponent.ngDoCheck();
    setTimeout(() => {
      this.lightboxComponent.openPreview(
        this.lightboxImages.findIndex((image) => image.id === id)
      );
    });
  }

  registerLightboxComponent(comp: NgxGalleryComponent) {
    this.lightboxComponent = comp;
  }

  getUrl(
    handle: string,
    size: number = null,
    fit: filestack.FitOptions = filestack.FitOptions.max
  ) {
    // this makes the very broad assumption that a dash
    // means it could be url instead of handle (used in dev)

    if (!handle) {
      console.warn('missing handle');
      return;
    }

    if (handle.indexOf('/') !== -1) {
      return handle;
    }

    const src = new filestack.Filelink(handle);
    if (size) {
      src.resize({
        width: size,
        height: size,
        fit: fit || filestack.FitOptions.max,
      });
    }

    src.rotate({ deg: 'exif' });
    src.compress();

    return src.toString();
  }

  thumbnailForFileUrl(handle: string, size: number = null) {
    const src = new filestack.Filelink(handle);

    src.output({
      format: 'png',
    });

    src.resize({
      width: size || 300,
      height: size || 300,
      fit: filestack.FitOptions.scale,
    });

    src.compress();

    return src.toString();
  }
}
