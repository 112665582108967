import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Input,
} from '@angular/core';
import { FolderBrowserContentBlock, DocumentFile } from '@ds/interfaces';
import { Observable, BehaviorSubject } from 'rxjs';
import { DocumentFileService, UploadService } from '@ds/api-services';
import { filterNullOrUndefined } from '@ds/core';
import { switchMap, map, tap } from 'rxjs/operators';

@Component({
  selector: 'ds-cb-folder-browser',
  template: `
    <ng-container *ngIf="currentFolder$ | async as _data">
      <div class="bread-crumbs display-flex">
        <span
          *ngIf="_data.id !== folderId && _data.parentFolder.id !== folderId"
        >
          <a
            href="javascript:void(0)"
            (click)="changeFolder(_data.parentFolder.parentId)"
          >
            ...
          </a>
          <span class="dash">/</span>
        </span>
        <span *ngIf="_data.id !== folderId">
          <a
            href="javascript:void(0)"
            (click)="changeFolder(_data.parentFolder.id)"
            >{{ _data.parentFolder.label }}</a
          >
          <span class="dash">/</span>
        </span>
        <span>{{ _data.label }}</span>
      </div>
      <table class="table">
        <tr *ngFor="let folder of _data.childFolders">
          <td>
            <div
              class="icon"
              inlineSVG="/icons/folder.svg"
              (click)="changeFolder(folder.id)"
            ></div>
          </td>
          <td>
            <div class="label">
              <a href="javascript:void(0)" (click)="changeFolder(folder.id)">{{
                folder.label
              }}</a>
            </div>
          </td>
        </tr>
        <tr *ngFor="let file of _data.files">
          <td>
            <div
              class="icon"
              inlineSVG="/icons/file.svg"
              (click)="openFile(file)"
            ></div>
          </td>
          <td>
            <div class="label">
              <a href="javascript:void(0)" (click)="openFile(file)">{{
                file.label
              }}</a>
            </div>
          </td>
        </tr>
      </table>
      <div class="folders">
        <span></span>
      </div>
      <div class="files"></div>
    </ng-container>
  `,
  styles: [
    `
      .bread-crumbs {
        font-weight: bold;
        padding: 5px;
      }
      .dash {
        margin: 0 10px;
      }
      .icon {
        height: 25px;
        width: 25px;
        cursor: pointer;
      }
      .label {
        margin-bottom: 5px;
      }
      td {
        vertical-align: bottom;
        padding: 5px;
      }
    `,
  ],
})
export class ContentBlockFolderBrowserComponent implements OnInit {
  folderId: number;

  _block: FolderBrowserContentBlock;

  currentFolderId$: BehaviorSubject<number> = new BehaviorSubject(null);

  currentFolder$: Observable<DocumentFile>;

  @Input()
  set block(val: FolderBrowserContentBlock) {
    this.init(val);
  }

  constructor(
    private fileService: DocumentFileService,
    private uploadService: UploadService
  ) {
    this.currentFolder$ = this.currentFolderId$.pipe(
      filterNullOrUndefined(),
      switchMap((id) => this.fileService.findOne(id))
    );
  }

  init(block: FolderBrowserContentBlock) {
    this.folderId = block.config.folderId;
  }

  ngOnInit(): void {
    this.currentFolderId$.next(this.folderId);
  }

  openFile(file: DocumentFile) {
    this.uploadService.getUrl(file).subscribe((data) => {
      window.open(data.url);
    });
  }

  changeFolder(id: number) {
    this.currentFolderId$.next(id);
  }
}
