import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnChanges,
} from '@angular/core';
import { Page } from '@ds/interfaces';
import { ContentBlockBlogService } from './content-block-blog.service';
import { ContentBlockDataService } from '../../services/content-block-data.service';
import { Observable, of } from 'rxjs';

@Component({
  selector: 'ds-cb-blog-detail',
  template: `
    <div class="ds-row-sc">
      <ds-cb-link [route]="searchLink" text="Back To Browsing"></ds-cb-link>
    </div>

    <img
      dsImage
      *ngIf="data.config.imageHandle"
      [imageHandle]="data.config.imageHandle"
      size="large"
    />

    <div class="ds-row-sc">
      <ds-cb-header
        [text]="data.title"
        type="large"
        textAlign="center"
      ></ds-cb-header>
    </div>

    <ds-cb
      *ngFor="let block of content | async; trackBy: trackByFn"
      [block]="block"
      class="main"
    ></ds-cb>

    <div class="ds-tags">
      <a
        href=""
        [routerLink]="['./../', { tag: tag.tag }]"
        *ngFor="let tag of data.tags"
        class="ds-tag"
      >
        <span class="icon" [inlineSVG]="'/icons/tag.svg'"></span
        >{{ tag.tag }}</a
      >
    </div>

    <div class="ds-row-sc">
      <ds-cb-contact-card [contact]="data.websiteContact"></ds-cb-contact-card>
    </div>
  `,
  styles: [
    `
      :host {
        display: block;
      }
      ds-cb-header {
        margin: 25px;
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [ContentBlockDataService],
})
export class ContentBlockBlogDetailComponent implements OnChanges {
  @Input() data: Page;

  content: Observable<any[]> = of([]);

  constructor(
    private cbBlogService: ContentBlockBlogService,
    private cbDataService: ContentBlockDataService
  ) {}

  ngOnChanges() {
    if (!this.data) {
      return;
    }
    this.cbDataService.registerContent(this.data);
    this.setContent();
  }

  get searchLink() {
    return this.cbBlogService.generateRoutesFromStore();
  }

  setContent() {
    this.content = this.cbDataService.getChildren(null);
  }

  trackByFn(index, model) {
    return model.id;
  }
}
