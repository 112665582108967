import { Injectable } from '@angular/core';
import * as interfaces from '@ds/interfaces';
import { Observable } from 'rxjs';
import { ApiService } from './api.service';
import { PaginatedResults } from '@ds/interfaces';

export interface FileFindAllOptions {
  limit?: number;
  page?: number;
  parentId?: string | number | string[] | number[];
  q?: string;
}

const resource = 'document-files';
type ModelType = interfaces.DocumentFile;

@Injectable({
  providedIn: 'root',
})
export class DocumentFileService {
  constructor(private api: ApiService) {}

  findAll(
    params?: FileFindAllOptions
  ): Observable<PaginatedResults<ModelType>> {
    return this.api.get(resource, { ...params });
  }

  findOne(id: number): Observable<ModelType> {
    return this.api.get(`${resource}/${id.toString()}`);
  }

  create(model: Partial<ModelType | any>): Observable<ModelType> {
    return this.api.post(resource, model);
  }

  update(model: ModelType): Observable<ModelType> {
    return this.api.put(`${resource}/${model.id.toString()}`, model);
  }

  destroy(model: ModelType): Observable<ModelType> {
    return this.api.delete(`${resource}/${model.id.toString()}`);
  }
}
