import { User } from './user.interface';
import { Contact } from './contact.interface';
import { Base } from './base.interface';

export enum ContactLogEntryStatus {
  Pending = 'Pending',
  Completed = 'Completed',
}

export enum ContactLogEntryType {
  EMAIL = 'Email',
  PHONE = 'Phone',
  QUOTE = 'Quote',
  LETTER = 'Letter',
  ATLOCATION = 'At Location',
  ATDEALERSHIP = 'At Dealership',
  DELIVERY = 'Delivery',
}

export interface ContactLogEntry extends Base {
  userId: number;
  contactId: number;

  type: ContactLogEntryType;
  comments: string;
  status: ContactLogEntryStatus;
  contactedAt: Date;

  user?: Partial<User>;
  contact?: Partial<Contact>;
}
