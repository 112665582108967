import {
  ComponentFactoryResolver,
  Injectable,
  ViewContainerRef,
} from '@angular/core';

import {
  BlockTypeContactCard,
  BlockTypeGallery,
  BlockTypeHeader,
  BlockTypeHTML,
  BlockTypeImage,
  BlockTypeLink,
  BlockTypeList,
  BlockTypeCard,
  BlockTypeSection,
  BlockTypeYouTube,
  TContentBlock,
  BlockTypeColumn,
  BlockTypeQuote,
  BlockTypeRow,
  BlockTypeSlider,
  BlockTypeFinancingCalculator,
  BlockTypeWebsiteContacts,
  BlockTypeInventoryList,
  BlockTypeScript,
  BlockTypeInventoryMakes,
  BlockTypeForm,
  BlockTypeFolderBrowser,
  BlockTypeVideo,
  BlockTypeFirstDataPaymentForm,
  BlockTypeLocationList,
  BlockTypeIFrame,
  BlockTypeSimpleText,
  BlockTypeSectionSlider,
} from '@ds/interfaces';

import { ContentBlockContactCardComponent } from '../components/content-block-contact-card/content-block-contact-card.component';
import { ContentBlockGalleryComponent } from '../components/content-block-gallery/content-block-gallery.component';
import { ContentBlockHeaderComponent } from '../components/content-block-header/content-block-header.component';
import { ContentBlockHTMLComponent } from '../components/content-block-html/content-block-html.component';
import { ContentBlockImageComponent } from '../components/content-block-image/content-block-image.component';
import { ContentBlockLinkComponent } from '../components/content-block-link/content-block-link.component';
import { ContentBlockListComponent } from '../components/content-block-list/content-block-list.component';
import { ContentBlockCardComponent } from '../components/content-block-card/content-block-card.component';
import { ContentBlockMissingComponent } from '../components/content-block-missing/content-block-missing.component';
import { ContentBlockSectionComponent } from '../components/content-block-section/content-block-section.component';
import { ContentBlockYouTubeComponent } from '../components/content-block-youtube/content-block-youtube.component';
import { ContentBlockColumnComponent } from '../components/content-block-column/content-block-column.component';
import { ContentBlockQuoteComponent } from '../components/content-block-quote/content-block-quote.component';
import { ContentBlockRowComponent } from '../components/content-block-row/content-block-row.component';
import { ContentBlockSliderComponent } from '../components/content-block-slider/content-block-slider.component';
import { ContentBlockFinancingCalculatorComponent } from '../components/content-block-financing-calculator/content-block-financing-calculator.component';
import { ContentBlockWebsiteContactsComponent } from '../components/content-block-contact-card/content-block-website-contacts.component';
import { ContentBlockInventoryListComponent } from '../components/content-block-inventory/content-block-inventory-list.component';
import { ContentBlockScriptComponent } from '../components/content-block-script/content-block-script.component';
import { ContentBlockInventoryMakesComponent } from '../components/content-block-inventory-makes/content-block-inventory-makes.component';
import { ContentBlockFormComponent } from '../components/content-block-form/content-block-form.component';
import { ContentBlockFolderBrowserComponent } from '../components/content-block-folder-browser/content-block-folder-browser.component';
import { ContentBlockVideoComponent } from '../components/content-block-video/content-block-video.component';
import { ContentBlockFirstDataPaymentFormComponent } from '../components/content-block-first-data/content-block-first-data-payment-form.component';
import { ContentBlockLocationListComponent } from '../components/content-block-location-list/content-block-location-list.component';
import { ContentBlockIFrameComponent } from '../components/content-block-iframe/content-block-iframe.component';
import { ContentBlockSimpleTextComponent } from '../components/content-block-simple-text/content-block-simple-text.component';
import { ContentBlockSectionSliderComponent } from '../components/content-block-section/content-block-section-slider.component';

@Injectable({
  providedIn: 'root',
})
export class ContentBlockRenderService {
  constructor(private resolver: ComponentFactoryResolver) {}

  static getContentBlockComponent(block: TContentBlock): any {
    switch (block.type) {
      case BlockTypeSection:
        return ContentBlockSectionComponent;
      case BlockTypeSectionSlider:
        return ContentBlockSectionSliderComponent;
      case BlockTypeColumn:
        return ContentBlockColumnComponent;
      case BlockTypeCard:
        return ContentBlockCardComponent;
      case BlockTypeList:
        return ContentBlockListComponent;
      case BlockTypeHTML:
        return ContentBlockHTMLComponent;
      case BlockTypeImage:
        return ContentBlockImageComponent;
      case BlockTypeLink:
        return ContentBlockLinkComponent;
      case BlockTypeHeader:
        return ContentBlockHeaderComponent;
      case BlockTypeContactCard:
        return ContentBlockContactCardComponent;
      case BlockTypeGallery:
        return ContentBlockGalleryComponent;
      case BlockTypeYouTube:
        return ContentBlockYouTubeComponent;
      case BlockTypeQuote:
        return ContentBlockQuoteComponent;
      case BlockTypeRow:
        return ContentBlockRowComponent;
      case BlockTypeSlider:
        return ContentBlockSliderComponent;
      case BlockTypeFinancingCalculator:
        return ContentBlockFinancingCalculatorComponent;
      case BlockTypeWebsiteContacts:
        return ContentBlockWebsiteContactsComponent;
      case BlockTypeInventoryList:
        return ContentBlockInventoryListComponent;
      case BlockTypeScript:
        return ContentBlockScriptComponent;
      case BlockTypeInventoryMakes:
        return ContentBlockInventoryMakesComponent;
      case BlockTypeForm:
        return ContentBlockFormComponent;
      case BlockTypeFolderBrowser:
        return ContentBlockFolderBrowserComponent;
      case BlockTypeVideo:
        return ContentBlockVideoComponent;
      case BlockTypeFirstDataPaymentForm:
        return ContentBlockFirstDataPaymentFormComponent;
      case BlockTypeLocationList:
        return ContentBlockLocationListComponent;
      case BlockTypeIFrame:
        return ContentBlockIFrameComponent;
      case BlockTypeSimpleText:
        return ContentBlockSimpleTextComponent;
      default:
        console.warn('Missing Component', block);
        return ContentBlockMissingComponent;
    }
  }

  renderContentBlock(content: ViewContainerRef, block: TContentBlock) {
    content.clear();
    const factory = this.resolver.resolveComponentFactory(
      ContentBlockRenderService.getContentBlockComponent(block)
    );
    const instance: any = content.createComponent(factory).instance;
    instance.init(block);
  }
}
