import {
  Component,
  Output,
  EventEmitter,
  ChangeDetectionStrategy,
  Input,
  HostBinding,
} from '@angular/core';

@Component({
  selector: 'ds-cb-pagination',
  template: `
    <a class="pagination-button" [routerLink]="previousPageRoute">
      <span
        (click)="clickPrev($event)"
        class="icon icon--90-cw"
        [inlineSVG]="'/icons/icon-carret.svg'"
      ></span>
      <span (click)="clickPrev($event)">Prev</span>
    </a>
    <span class="pagination">{{ +currentPage + 1 }} / {{ totalPages }}</span>
    <a class="pagination-button" [routerLink]="nextPageRoute">
      <span (click)="clickNext($event)">Next</span>
      <span
        (click)="clickNext($event)"
        class="icon icon--90-ccw"
        [inlineSVG]="'/icons/icon-carret.svg'"
      ></span>
    </a>
  `,
  styles: [
    `
      :host {
        display: flex;
        align-items: center;
      }
      :host(.no-pages) {
        opacity: 0.5;
      }
      :host(.no-pages) a {
        color: inherit;
      }
      :host(.no-pages) a:hover {
        color: inherit;
      }
      .pagination {
        margin: 0 10px;
      }
      .pagination-button {
        display: flex;
        align-items: center;
        background-color: transparent;
        border: none;
        cursor: pointer;
        font-weight: bold;
        color: var(--ds-cb-inventory-pagination-button-color);
      }
      .pagination-button:focus {
        outline: none;
      }
      .pagination-button:hover {
        color: var(--ds-cb-inventory-pagination-button-color--hover);
        text-decoration: underline;
      }
      .icon--90-cw {
        transform: rotate(90deg);
      }
      .icon--90-ccw {
        transform: rotate(-90deg);
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ContentBlockPaginationComponent {
  @Output()
  previous = new EventEmitter();
  @Output()
  next = new EventEmitter();

  @Input() previousPageRoute: any[];
  @Input() nextPageRoute: any[];

  @HostBinding('class.no-pages')
  get noPages() {
    return this.totalPages <= 1;
  }

  @Input() currentPage = 0;
  @Input() totalPages = 0;

  clickPrev($event: MouseEvent) {
    if (this.noPages) {
      $event.stopPropagation();
      $event.preventDefault();
    } else {
      if (!this.previousPageRoute) {
        $event.stopPropagation();
        $event.preventDefault();
      }
      if (+this.currentPage - 1 < 0) {
        return;
      }
      this.previous.emit(+this.currentPage - 1);
    }
  }

  clickNext($event: MouseEvent) {
    if (this.noPages) {
      $event.stopPropagation();
      $event.preventDefault();
    } else {
      if (!this.nextPageRoute) {
        $event.stopPropagation();
        $event.preventDefault();
      }
      if (+this.currentPage + 1 >= +this.totalPages) {
        return;
      }
      this.next.emit(+this.currentPage + 1);
    }
  }
}
