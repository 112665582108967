import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { SiteConfig, SiteTheme } from '@ds/interfaces';

declare let gtag: Function;
declare let fbq: Function;

@Injectable({ providedIn: 'root' })
export class ContentBlockSiteService {
  constructor(@Inject(DOCUMENT) private document: Document) {}

  loadSiteConfig(config: SiteConfig) {
    if (config.ga) {
      // gtag('config', config.ga);
    }
    if (config.fbq) {
      fbq('init', config.fbq);
    }
    this.document.documentElement.classList.add(
      `ds-menu-position-${config.menuPosition}`
    );
    this.loadSiteTheme(config.theme);
  }

  loadSiteTheme(theme: SiteTheme) {
    Object.keys(theme).forEach((k) =>
      this.document.documentElement.style.setProperty(`--${k}`, theme[k])
    );
  }
}
