import { isPlatformBrowser } from '@angular/common';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Inject,
  Input,
  NgZone,
  OnDestroy,
  OnInit,
  PLATFORM_ID,
} from '@angular/core';
import { OutsideZoneScheduler } from '@ds/core';
import {
  ImageContentBlock,
  ImageSize,
  SliderContentBlock,
} from '@ds/interfaces';
import { asyncScheduler, Observable, of } from 'rxjs';
import { tap } from 'rxjs/operators';
import { ContentBlockDataService } from '../../services/content-block-data.service';

@Component({
  selector: 'ds-cb-content-block-slider',
  template: `
    <ng-container *ngIf="_content | async as content">
      <ds-cb-image
        *ngFor="let image of content; let i = index; trackBy: trackByFn"
        [block]="image"
        [class.active]="i === activeIndex"
        [lightbox]="lightbox"
        [imageSize]="imageSize"
        [preLoad]="true"
        [class.fit]="true"
      ></ds-cb-image>
    </ng-container>
  `,
  styles: [
    `
      :host {
        display: block;
        width: 100%;
        position: relative;
        /* If you want text inside of it */
        overflow: hidden;
      }
      ds-cb-image {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 100%;
      }
      @keyframes slideLeft {
        0% {
          left: -100%;
        }
        100% {
          left: 0;
        }
      }
      @keyframes slideRight {
        0% {
          left: 0;
        }
        100% {
          left: 100%;
        }
      }
      ds-cb-image:not(.active) {
        /*left: 100%;
	 animation-name: slideRight;
	 animation-duration: 1s;
	 animation-iteration-count: 1;
	*/
        display: none;
      }
      ds-cb-image.active {
        /*animation-name: slideLeft;
	 animation-duration: 1s;
	 animation-iteration-count: 1;
	*/
        display: block;
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ContentBlockSliderComponent
  implements OnInit, AfterViewInit, OnDestroy
{
  @Input() set content(value: ImageContentBlock[]) {
    if (value) {
      this._content = of(value);
    }
  }
  @Input() lightbox: boolean;
  @Input() imageSize: ImageSize;
  @Input() startOn = 0;

  @Input()
  set block(val: SliderContentBlock) {
    this.init(val);
  }

  activeIndex = 0;

  _content: Observable<ImageContentBlock[]> = of([]);

  interval: NodeJS.Timeout;

  count = 0;

  constructor(
    @Inject(PLATFORM_ID) private platformId: string,
    private ngZone: NgZone,
    private cdRef: ChangeDetectorRef,
    private cbDataService: ContentBlockDataService,
    private el: ElementRef
  ) {}

  init(block: SliderContentBlock) {
    this._content = block.content
      ? of(block.content)
      : this.cbDataService.getChildren(block.id);

    this.lightbox = block.config.lightbox || false;
    this.imageSize = block.config.imageSize || 'icon';
  }

  ngOnInit() {}

  ngAfterViewInit() {
    this.count = this.el.nativeElement.querySelectorAll('ds-cb-image').length;
    this.autoRotate();
  }

  ngOnDestroy() {
    if (this.interval) {
      clearInterval(this.interval);
    }
  }

  trackByFn(idx, model) {
    return model.id;
  }

  autoRotate() {
    if (isPlatformBrowser(this.platformId)) {
      this.interval = setInterval(() => {
        if (!this.count) {
          this.activeIndex = 0;
        } else if (this.activeIndex < this.count - 1) {
          this.activeIndex += 1;
        } else {
          this.activeIndex = 0;
        }
        this.cdRef.markForCheck();
      }, 3000);
    }
  }
}
