import {
  Component,
  Input,
  HostBinding,
  ChangeDetectionStrategy,
} from '@angular/core';
import { ImageContentBlock, ImageFitOptions, ImageSize } from '@ds/interfaces';
import { Router } from '@angular/router';

@Component({
  selector: 'ds-cb-image',
  template: `
    <a
      [href]="link"
      (click)="onLabelClick($event)"
      [target]="target"
      [ngClass]="imageSize"
    >
      <figure style="display: flex; flex-direction: column;">
        <div
          class="image-container"
          style="flex-grow: 1; min-height: 0;"
          [style.text-align]="imageAlign"
        >
          <img
            [ngStyle]="imgStyle"
            dsImage
            [imageHandle]="imageHandle"
            [fileHandle]="fileHandle"
            [imageSize]="imageSize"
            [lightbox]="lightbox"
            [preLoad]="preLoad"
            [fitType]="fitType"
            [alt]="label"
          />
        </div>
        <figcaption *ngIf="label">{{ label }}</figcaption>
      </figure>
    </a>
  `,
  styles: [
    `
      :host {
        display: block;
        text-align: center;
        height: 100%;
        width: 100%;
      }

      a,
      figure {
        height: 100%;
        width: 100%;
      }

      .img-container {
        flex-grow: 1;
        min-height: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        overflow: hidden;
      }
      img {
        background-color: var(--ds-cb-image-background-color);
        border-color: var(--ds-cb-image-border-color);
        border-radius: 4px;
        border-style: solid;
        border-width: 1px;
        padding: 4px;
        flex-shrink: 0;
        min-width: auto;
        min-height: auto;
        max-height: 100%;
        max-width: 100%;
        object-fit: contain;
        vertical-align: middle;
      }

      .label {
        margin-top: 4px;
      }

      figcaption {
        display: flex;
        flex-direction: column;
        justify-content: center;
      }

      .icon figcaption {
        display: none;
      }

      .small figcaption {
        // height: 1em;
        line-height: 1em;
        overflow: hidden;
      }

      .medium figcaption {
        // height: 2em;
        line-height: 1em;
        overflow: hidden;
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ContentBlockImageComponent {
  @Input() label: string;
  @Input() link: string;
  @Input() imageHandle: string;
  @Input() fileHandle: string;

  @Input() borderColor = null;
  @Input() backgroundColor = null;
  @Input() imageSize: ImageSize;
  @Input() fitType: ImageFitOptions;
  @Input() lightbox: boolean;
  @Input() preLoad: boolean;
  @Input()
  set block(val: ImageContentBlock) {
    this.init(val);
  }

  @Input() imageAlign: 'left' | 'right' | 'center' = 'center';

  target = '_blank';

  @HostBinding('style.cursor')
  get clickable() {
    return this.lightbox || this.link ? 'pointer' : null;
  }

  constructor(private router: Router) {}

  init(block: ImageContentBlock) {
    this.label = block.config.text;
    this.link = block.config.url;
    this.imageHandle = block.config.imageHandle;
    this.borderColor = block.config.borderColor;
    this.backgroundColor = block.config.backgroundColor;
    if (!this.imageSize) {
      this.imageSize = block.config.imageSize;
    }
    if (this.lightbox === undefined || this.lightbox === null) {
      this.lightbox = block.config.lightbox;
    }
    if (this.link && this.link.substr(0, 1) === '/') {
      this.target = '_self';
    }
    this.fitType = block.config.fitType;
    this.imageAlign = block.config.imageAlign || 'center';
  }

  get imgStyle() {
    return {
      'border-color': this.borderColor,
      'background-color': this.backgroundColor,
    };
  }

  onLabelClick(event: MouseEvent) {
    if (!this.link) {
      event.preventDefault();
    } else if (this.link.substr(0, 1) === '/') {
      event.preventDefault();
      this.router.navigate([this.link]);
    }
  }
}
