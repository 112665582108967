import { Component, Input, ChangeDetectionStrategy } from '@angular/core';
import { ModalService } from '@ds/core';
import { ContentBlockBlogTagsModalComponent } from './content-block-blog-tags-modal.component';
import { BlogConfigTag } from '@ds/interfaces';

@Component({
  selector: 'ds-cb-blog-tags',
  template: `
    <ds-cb-card [header]="'Tags'">
      <a
        href=""
        class="tag"
        *ngFor="let tag of tags.slice(0, 15)"
        [routerLink]="['/', slug, { tag: tag.label }]"
      >
        {{ tag.label }}
        <span class="badge badge-pill badge-secondary">{{ tag.count }}</span>
      </a>
      <div class="tag">
        <ds-cb-link text="Show More" (click)="showMore()"></ds-cb-link>
      </div>
    </ds-cb-card>
  `,
  styles: [
    `
      .tag:not(:last-child) {
        border-bottom: 1px solid var(--ds-cb-card-border-color);
      }

      .tag {
        padding: 10px 15px;
        margin-bottom: -1px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        text-decoration: none;
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ContentBlockBlogTagsComponent {
  @Input()
  tags: BlogConfigTag[];

  @Input()
  slug: string;

  constructor(private modalService: ModalService) {}

  showMore() {
    const modal = this.modalService.open<string>(
      ContentBlockBlogTagsModalComponent,
      {
        slug: this.slug,
        tags: this.tags,
      }
    );
  }
}
