import { Component, Input, ChangeDetectionStrategy } from '@angular/core';
import { FinancingCalculatorContentBlock } from '@ds/interfaces';
import { FormGroup, FormBuilder } from '@angular/forms';
import { map, startWith } from 'rxjs/operators';
import { Observable } from 'rxjs';

@Component({
  selector: 'ds-cb-financing-calculator',
  styles: [
    `
      :host {
        display: block;
      }
      form {
        margin-bottom: 15px;
      }

      p {
        margin-bottom: 15px;
      }

      p.result {
        text-align: center;
        font-size: 1.2em;
        font-weight: bold;
      }

      .input-group {
        margin-bottom: 8px;
        flex-wrap: nowrap;
      }

      .input-group-prepend {
        width: 40%;
        min-width: 115px;
      }
      .input-group-append {
        width: 25%;
      }
      .input-group-text {
        width: 100%;
      }

      .form-control {
        width: 100px;
        text-align: right;
        font-weight: bold;
      }
    `,
  ],
  template: `
    <p class="ds-fluid-text">
      Edit these details to calculate your monthly payment.
    </p>
    <ds-cb-simple-text></ds-cb-simple-text>
    <form [formGroup]="form">
      <div class="input-group">
        <div class="input-group-prepend">
          <span class="input-group-text">Price</span>
        </div>
        <input
          placeholder="???"
          type="number"
          class="form-control"
          formControlName="amount"
          aria-label="Price"
        />
        <div class="input-group-append">
          <span class="input-group-text">Dollars</span>
        </div>
      </div>

      <div class="input-group">
        <div class="input-group-prepend">
          <span class="input-group-text">Interest Rate</span>
        </div>
        <input
          placeholder="??.?"
          type="number"
          class="form-control"
          formControlName="rate"
          aria-label="Interest Rate"
        />
        <div class="input-group-append">
          <span class="input-group-text">Percent</span>
        </div>
      </div>

      <div class="input-group">
        <div class="input-group-prepend">
          <span class="input-group-text">Down Payment</span>
        </div>
        <input
          placeholder="???"
          type="number"
          class="form-control"
          formControlName="downPayment"
          aria-label="Down Payment"
        />
        <div class="input-group-append">
          <span class="input-group-text">Dollars</span>
        </div>
      </div>

      <div class="input-group">
        <div class="input-group-prepend">
          <span class="input-group-text">Term</span>
        </div>
        <input
          placeholder="???"
          type="number"
          class="form-control"
          formControlName="term"
          aria-label="Term"
        />
        <div class="input-group-append">
          <span class="input-group-text">Months</span>
        </div>
      </div>
    </form>

    <p class="result ds-fluid-text">
      {{ (result$ | async | currency: '$') || '?' }} / month
    </p>

    <p class="disclaimer fluid-text">
      Title and other fees and incentives are not included in this calculation,
      which is an estimate only. Monthly payment estimates are for informational
      purpose and do not represent a financing offer form this dealership.
    </p>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ContentBlockFinancingCalculatorComponent {
  form: FormGroup;
  result$: Observable<number>;

  @Input()
  set amount(val: number) {
    this.form.patchValue({
      amount: val,
    });
  }

  @Input()
  set rate(val: number) {
    this.form.patchValue({
      rate: val,
    });
  }

  @Input()
  set term(val: number) {
    this.form.patchValue({
      term: val,
    });
  }

  @Input()
  set downPayment(val: number) {
    this.form.patchValue({
      downPayment: val,
    });
  }

  constructor(private formBuilder: FormBuilder) {
    this.form = this.formBuilder.group({
      amount: '',
      rate: '',
      term: '',
      downPayment: '',
    });

    this.result$ = this.form.valueChanges.pipe(
      startWith(this.form.value),
      map((value) => this.calculate(value))
    );
  }

  @Input()
  set block(val: FinancingCalculatorContentBlock) {
    this.init(val);
  }

  init(block: FinancingCalculatorContentBlock) {
    this.form.patchValue({
      amount: block.config.amount,
      rate: block.config.rate,
      term: block.config.term,
      downPayment: block.config.downPayment,
    });
  }

  calculate({ amount, downPayment, rate, term }) {
    amount = amount || 0;
    downPayment = downPayment || 0;
    rate = (rate || 0) / 100.0;
    term = term || 0;

    if (amount && rate && term) {
      const totalCost = amount - downPayment;
      const montlyRate = rate / 12;
      const multiplier = Math.pow(1 + montlyRate, term);

      return (
        Math.round(
          100 * (totalCost * montlyRate) * (multiplier / (multiplier - 1))
        ) / 100
      );
    }

    return null;
  }
}
