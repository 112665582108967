import {
  Input,
  Component,
  ChangeDetectionStrategy,
  HostBinding,
} from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { IframeContentBlock } from '@ds/interfaces';

@Component({
  selector: 'ds-cb-iframe',
  template: `
    <iframe
      [style.min-width]="width"
      [style.min-height]="height"
      *ngIf="src"
      [src]="trustedLink"
    ></iframe>
  `,
  styles: [
    `
      :host {
        display: block;
        width: 100%;
        min-height: 200px;
        position: relative;
      }

      iframe {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ContentBlockIFrameComponent {
  @Input()
  src: string;

  @Input() minHeight: number;
  @Input() minHeightUnits: '%' | 'px' | 'vh';

  @Input() minWidth: number;
  @Input() minWidthUnits: '%' | 'px' | 'vh';

  _block: IframeContentBlock;
  @Input()
  set block(val: IframeContentBlock) {
    this.init(val);
  }

  get height(): string {
    if (!this.minHeight || !this.minHeightUnits) {
      return 'auto';
    }
    return `${this.minHeight}${this.minHeightUnits}`;
  }

  get width(): string {
    if (!this.minWidth || !this.minWidthUnits) {
      return 'auto';
    }
    return `${this.minWidth}${this.minWidthUnits}`;
  }

  @HostBinding('style.min-height')
  get sectionHeight(): string {
    return this.height;
  }

  @HostBinding('style.min-width')
  get sectionWidth(): string {
    return this.width;
  }

  constructor(private sanitizer: DomSanitizer) {}

  init(block: IframeContentBlock) {
    this._block = block;
    this.src = block.config.src;
    this.minHeight = block.config.minHeight;
    this.minHeightUnits = block.config.minHeightUnits;
    this.minWidth = block.config.minWidth;
    this.minWidthUnits = block.config.minWidthUnits;
  }

  get trustedLink() {
    return this.sanitizer.bypassSecurityTrustResourceUrl(this.src);
  }
}
