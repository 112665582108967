import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { SelectOption } from '@ds/interfaces';

type Options = (string | number | { label: string; value: any })[];

@Component({
  selector: 'ds-cb-inventory-search-form-select',
  template: `
    <select
      class="form-control"
      [class.active]="value"
      (change)="valueUpdate.emit(input.value)"
      #input
    >
      <option value="">All {{ label }}</option>
      <ng-container *ngIf="groupedOptions; else unGrouped">
        <optgroup *ngFor="let group of groupedOptions" [label]="group.label">
          <option
            *ngFor="let option of group.options"
            [value]="option.value"
            [selected]="value === option.label"
            >{{ option.label }}</option
          >
        </optgroup>
      </ng-container>
      <ng-template #unGrouped>
        <option
          *ngFor="let option of options"
          [value]="option.value"
          [selected]="value === option.label || value === option.value"
          >{{ option.label }}</option
        >
      </ng-template>
    </select>
  `,
  styles: [
    `
      :host {
        display: inline-block;
        width: 100%;
      }
      .active {
        background-color: whitesmoke;
        color: black;
        font-weight: bold;
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ContentBlockInventorySearchFormSelectComponent {
  @Input() label: string;
  @Input() value: string | number;
  @Input() options: SelectOption[];
  @Input() groupedOptions: {
    label: string;
    options: SelectOption[];
  }[];

  @Output() valueUpdate: EventEmitter<string> = new EventEmitter();
  constructor() {}
}
