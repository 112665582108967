import {
  ErrorHandler,
  Injectable,
  PLATFORM_ID,
  Inject,
  Optional,
} from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { RESPONSE } from '@nguniversal/hapi-engine/tokens';
declare var TrackJS: any;

@Injectable()
export class GlobalErrorHandler implements ErrorHandler {
  isBrowser = false;
  constructor(
    @Inject(PLATFORM_ID) private platformId: Object,
    @Optional() @Inject(RESPONSE) private response: any
  ) {
    this.isBrowser = isPlatformBrowser(this.platformId);
  }

  handleError(error: any) {
    if (this.isBrowser) {
      // Add the error message to the telemetry timeline.
      // It can occasionally have useful additional context.
      console.error(error.message ? error.message : error);

      // Assumes we have already loaded and configured TrackJS*
      // tslint:disable-next-line: no-unused-expression
      window['TrackJS'] && TrackJS.track(error.originalError || error);
    } else {
      // At this point we're on the server doing SSR.
      // We should properly end request and send correct status code
      // to the client
      const exception = error.rejection || error;
      const status = exception.status || 500;
      const statusText = exception.statusText || 'Internal Server Error';

      this.response.writeHead(status);
      this.response.end(statusText);

      console.error(error.message ? error.message : error);
    }
  }
}
