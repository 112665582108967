import { Component, Input } from '@angular/core';
import { ModalService } from '@ds/core';
import { ContentBlockBlogDatesModalComponent } from './content-block-blog-dates-modal.component';
import { BlogConfigDate } from '@ds/interfaces';

@Component({
  selector: 'ds-cb-blog-dates',
  template: `
    <ds-cb-card header="Dates">
      <a
        href=""
        class="date"
        *ngFor="let date of dates.slice(0, 12)"
        [routerLink]="['/', slug, { year: date.year, month: date.month }]"
      >
        {{ monthNames[date.month - 1] }}, {{ date.year }}
        <span class="badge badge-pill badge-secondary">{{ date.count }}</span>
      </a>
      <div class="date">
        <ds-cb-link text="Show More" (click)="showMore()"></ds-cb-link>
      </div>
    </ds-cb-card>
  `,
  styles: [
    `
      .date:not(:last-child) {
        border-bottom: 1px solid var(--ds-cb-card-border-color);
      }

      .date {
        padding: 10px 15px;
        margin-bottom: -1px;
        display: flex;
        justify-content: space-between;
        text-decoration: none;
      }
    `,
  ],
})
export class ContentBlockBlogDatesComponent {
  @Input() dates: BlogConfigDate[] = [];
  @Input() slug: string;

  monthNames = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];

  constructor(private modalService: ModalService) {}

  showMore() {
    const modal = this.modalService.open<string>(
      ContentBlockBlogDatesModalComponent,
      {
        slug: this.slug,
        dates: this.dates,
      }
    );
  }
}
