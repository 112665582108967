import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse,
} from '@angular/common/http';
import { Injectable, Inject, Optional, PLATFORM_ID } from '@angular/core';
import { Observable, throwError, of } from 'rxjs';
import { tap, map, catchError } from 'rxjs/operators';

import { BUILD_VERSION, ModalService } from '@ds/core';
import { environment } from '@ds/admin-env';
import { REQUEST } from '@nguniversal/hapi-engine/tokens';
import { isPlatformBrowser } from '@angular/common';

// Interceptor to catch errors and display them to the enduser.
@Injectable()
export class PublicInterceptor implements HttpInterceptor {
  loginUrl = environment.loginUrl;
  versionMessageShowing = false;
  errorGenericMessagingShow = false;
  isBrowser = true;
  constructor(
    private modalService: ModalService,
    @Inject(PLATFORM_ID) private platformId: Object
  ) {
    this.isBrowser = isPlatformBrowser(this.platformId);
  }

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    if (!req.url.includes('/api/')) {
      return next.handle(req);
    }
    return next
      .handle(req)
      .pipe(tap(this.allGood.bind(this)), catchError(this.noGood.bind(this)));
  }

  allGood(event: HttpEvent<any>) {
    if (event instanceof HttpResponse) {
      if (BUILD_VERSION.date !== event.headers.get('x-ds-builddate')) {
        console.warn(
          '[AuthInterceptor] Version headers do not match!',
          BUILD_VERSION.date,
          event.headers.get('x-ds-builddate')
        );
        if (this.versionMessageShowing) return;
        this.versionMessageShowing = true;
        if (this.isBrowser) {
          window.location.reload(true);
        }
      }
    }
  }

  noGood(err: any) {
    if (!this.isBrowser) {
      return throwError(err);
    }
    if (err instanceof HttpErrorResponse) {
      if (err.status === 401 && !window.location.href.includes('login')) {
        console.warn('[Auth]: redirecting to login', window.location.href);
        this.goToLogin();
        // JWT expired, go to login
        return throwError(err);
      }
      const message = err.error && err.error.message;
      if (err.status > 399 && err.status < 499) {
        console.warn('[AuthInterceptor]: Bad Request', message);
      } else if (err.status === 504) {
        // this.msg
        //   .alert({
        //     text:
        //       'Server is unreachable. Either it is down or you might have lost internet. You might try again later. Closing this message will attempt to reload the service.',
        //   })
        //   .afterClosed()
        //   .subscribe(() => window.location.reload());
        console.warn('[AuthInterceptor]: Server Unreachable Error', message);
        return throwError(err);
      } else {
        console.warn('[AuthInterceptor]: Server Error', message);
      }
      const data = err && err.error && err.error.data && err.error.data;
      // this.msg.alert({
      //   text: message,
      //   list:
      //     data &&
      //     data.map((d) => ({
      //       label: 'Error',
      //       value: d.message,
      //     })),
      // });
      return throwError({
        message,
        data,
      });
    }
  }

  alert(msg: string[]) {
    if (this.isBrowser) {
    }
  }

  goToLogin() {
    if (this.isBrowser) {
      const redirect = window.location.href.substring(
        window.location.href.indexOf('/', 8)
      );

      let gotoPage: string;

      if (redirect === '/') {
        gotoPage = this.loginUrl;
      } else {
        gotoPage = this.loginUrl + '?redirect=' + encodeURIComponent(redirect);
      }
      window.location.href = gotoPage;
    }
  }
}
