import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';

@Component({
  selector: 'ds-cb-share',
  template: `
    <share-buttons [showIcon]="true" [showText]="false"></share-buttons>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ContentBlockShareComponent {
  constructor() {}
}
