import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'ds-cb-content-block-staff-edit',
  template: ` <p>content-block-staff-edit works!</p> `,
  styles: [],
})
export class ContentBlockStaffEditComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
