import { Component, Input } from '@angular/core';
import {
  Inventory,
  PaginatedResults,
  InventoryListContentBlock,
  InventoryCardContentBlock,
} from '@ds/interfaces';
import { ContentBlockDataService } from '../../services/content-block-data.service';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
  selector: 'ds-cb-inventory-list',
  template: `
    <div class="inventory-list-grid">
      <ds-cb-inventory-card
        [item]="item"
        [slug]="slug"
        [isLegacy]="isLegacy"
        *ngFor="let item of _content | async; trackBy: trackByFn"
      ></ds-cb-inventory-card>
    </div>
  `,
  styles: [
    `
      :host {
        display: block;
      }
      .inventory-list-grid {
        display: grid;
        grid-gap: 10px;
        grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
        margin-bottom: 25px;
      }
    `,
  ],
})
export class ContentBlockInventoryListComponent {
  @Input() set content(value: Inventory[]) {
    this._content = of(value);
  }
  @Input() slug: string;

  _content: Observable<Inventory[]>;

  isLegacy = this.cbDataService.isLegacySite;

  constructor(private cbDataService: ContentBlockDataService) {}

  @Input()
  set block(val: InventoryListContentBlock) {
    this.init(val);
  }

  init(block: InventoryListContentBlock) {
    this._content = block.content
      ? of(block.content.map((b) => b.inventoryItem))
      : this.cbDataService
          .getChildren(block.id)
          .pipe(
            map((items: InventoryCardContentBlock[]) =>
              items.map((b) => b.inventoryItem)
            )
          );
    this.slug = block.linkToPage.slug;
  }

  trackByFn(index, model) {
    return model.id;
  }
}
