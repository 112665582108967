import { PhoneNumbers } from './phonenumber.interface';
import { Base } from './base.interface';
import { WebsiteContact } from './website-contact.interface';

export enum HourTypes {
  OPEN = 'OPEN',
  CLOSED = 'CLOSED',
  '24H' = '24H',
  'BY_APPOINTMENT' = 'BY APPOINTMENT',
}

export interface RetailLocationHours {
  type: HourTypes;
  openTime: string;
  openPeriod: string;
  closedTime: string;
  closedPeriod: string;
}

export interface HoursOfOperation {
  label: string;
  hours: {
    [key: number]: RetailLocationHours;
  };
  disclaimer: string;
}

export interface RetailLocation extends Base {
  name?: string;
  shortName?: string;
  address: {
    addressLineOne?: string;
    addressLineTwo?: string;
    city?: string;
    state?: string;
    postalCode?: string;
    country?: string;
  };

  active?: boolean;
  emailAddress?: string[];
  hoursOfOperationPrimary?: {
    [key: number]: RetailLocationHours;
  };
  services?: string[];
  description?: string;
  phoneNumbers?: PhoneNumbers;

  websiteContacts?: Array<WebsiteContact>;

  hoursOfOperation?: HoursOfOperation[];

  config?: {
    googleMapsEmbed: string;
    googleMapsLink: string;
    towingLink: string;
    hoursDisclaimer: string;
    showEliteSupportLogo: boolean;
    lat: number;
    lng: number;
  };

  readonly slug?: string;

  defaultPhotoHandle?: string;
}
