import { Observable, BehaviorSubject } from 'rxjs';
import { filter, map, switchMap } from 'rxjs/operators';
import { isEmpty } from 'lodash';

interface SelectOption {
  value: any;
  label: string | number;
}

export const filterNullOrUndefined = () => <T>(source: Observable<T>) =>
  source.pipe(filter((value) => value !== undefined && value !== null));

export const filterFalsy = () => <T>(source: Observable<T>) =>
  source.pipe(filter((value) => !!value));

export const filterEmpty = () => <T>(source: Observable<T>) =>
  source.pipe(filter((value) => !isEmpty(value)));

export const convertListToSelectOptions = () => (
  source: Observable<(number | string | null)[]>
): Observable<SelectOption[]> =>
  source.pipe(
    map((list) =>
      list.map((i) => ({
        label: i,
        value: i,
      }))
    )
  );

export function refreshObs<M>(
  r: BehaviorSubject<any>,
  obs: Observable<M>
): Observable<M> {
  return r.pipe(switchMap(() => obs));
}
