import { Component, Input, ChangeDetectionStrategy } from '@angular/core';
import { HeaderContentBlock } from '@ds/interfaces';

@Component({
  selector: 'ds-cb-header',
  template: `
    <h2 [style.color]="color" [class]="textSize" [style.text-align]="textAlign">
      {{ text }}
    </h2>
  `,
  styles: [
    // !!! Note this component uses fluid-text.scss !!!
    `
      :host {
        display: block;
        font-weight: 700;
        text-transform: uppercase;
      }
      h2 {
        color: var(--ds-cb-card-header-footer-color, var(--ds-text-color));
        text-align: var(--ds-cb-header-text-align, left);
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ContentBlockHeaderComponent {
  @Input() text: string;
  @Input() textSize: string;
  @Input() textAlign = 'left';
  @Input() color: string;

  _block: HeaderContentBlock;
  @Input()
  set block(val: HeaderContentBlock) {
    this.init(val);
  }

  init(block: HeaderContentBlock) {
    this._block = block;
    this.textSize = block.config.textSize || 'large';
    this.text = block.config.text || '';
    this.textAlign = block.config.textAlign || 'left';
    this.color = block.config.color || null;
  }
}
