import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Input,
  HostBinding,
} from '@angular/core';
import { RowContentBlock, ColumnContentBlock } from '@ds/interfaces';
import { ContentBlockDataService } from '../../services/content-block-data.service';
import { of, Observable } from 'rxjs';

@Component({
  selector: 'ds-cb-content-block-row',
  template: `
    <ds-cb
      *ngFor="let column of columns | async; let i = index; trackBy: trackByFn"
      [block]="column"
    ></ds-cb>
  `,
  styles: [],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ContentBlockRowComponent {
  columns: Observable<ColumnContentBlock[]>;
  columnSizes: number[];

  @Input() @HostBinding('style.justify-content') justifyContent =
    'center';

  @Input()
  set block(val: RowContentBlock) {
    this.init(val);
  }

  constructor(private cbDataService: ContentBlockDataService) {}

  init(block: RowContentBlock) {
    this.columns = block.content
      ? of(block.content)
      : this.cbDataService.getChildren(block.id);
    this.columnSizes = block.config.columnSizes;
    this.justifyContent = block.config.justifyContent || 'center';
  }

  // columnSize(i: number) {
  //   if (!this.columnSizes || !this.columnSizes[i]) {
  //     return 'ds-col';
  //   }
  //   return `ds-col-sm-${this.columnSizes[i]}`;
  // }

  trackByFn(idx, model) {
    return model.id;
  }
}
