import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import * as interfaces from '@ds/interfaces';

import { ApiService } from './api.service';
import { PaginatedResults } from '@ds/interfaces';
import { tap } from 'rxjs/operators';

const resource = 'sites';
type ModelType = interfaces.Site;

@Injectable({
  providedIn: 'root',
})
export class SiteService {
  private _currentSite: ModelType;

  constructor(private api: ApiService) {}

  get currentSite() {
    return this._currentSite;
  }

  setCurrentSite(site: ModelType) {
    this._currentSite = site;
  }

  findAll(): Observable<PaginatedResults<ModelType>> {
    return this.api.get(resource);
  }

  findOne(id?: number): Observable<ModelType> {
    if (!id) {
      return this.loadHost();
    }
    return this.api.get(`${resource}/${id}`);
  }

  findByHostname(hostname: string) {
    return this.api.get<ModelType>(`${resource}/hostname/${hostname}`);
  }

  loadHost() {
    return this.api.get<ModelType>(`${resource}/host`);
  }

  create(model: ModelType): Observable<ModelType> {
    return this.api.post(resource, model);
  }

  update(model: ModelType): Observable<ModelType> {
    return this.api.put(`${resource}/${model.id.toString()}`, model);
  }
}
