import { Component, ChangeDetectionStrategy } from '@angular/core';
import { Observable } from 'rxjs';
import { ContentBlockBlogService } from './content-block-blog.service';
import { Page } from '@ds/interfaces';

@Component({
  selector: 'ds-cb-blog',
  providers: [ContentBlockBlogService],
  template: `
    <ds-cb-section>
      <div class="ds-row">
        <div class="ds-col">
          <ds-cb-blog-browse
            [entries]="entries$ | async"
            [params]="params$ | async"
            [slug]="slug"
            [previousPage]="previousPage()"
            [nextPage]="nextPage()"
            *ngIf="isBrowsing$ | async; else detail"
          ></ds-cb-blog-browse>
          <ng-template #detail>
            <ds-cb-blog-detail
              *ngIf="data$ | async as data"
              [data]="data"
            ></ds-cb-blog-detail>
          </ng-template>
        </div>
        <div class="ds-col-sm-3" *ngIf="config$ | async as config">
          <ds-cb-blog-tags [tags]="config.tags" [slug]="slug"></ds-cb-blog-tags>
          <ds-cb-blog-dates
            [dates]="config.dates"
            [slug]="slug"
          ></ds-cb-blog-dates>
        </div>
      </div>
    </ds-cb-section>
  `,
  styles: [
    `
      ds-cb-blog-tags {
        margin-bottom: 25px;
        display: block;
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ContentBlockBlogComponent {
  isBrowsing$: Observable<Boolean>;
  data$: Observable<Page> = this.cbBlogService.currentEntry$;
  config$ = this.cbBlogService.blogConfig$;
  slug = this.cbBlogService.blogPageSlug;
  entries$ = this.cbBlogService.entries$;
  params$ = this.cbBlogService.routeParams$;

  constructor(private cbBlogService: ContentBlockBlogService) {
    this.isBrowsing$ = this.cbBlogService.isBrowsing$;
  }

  previousPage(): any[] {
    return this.cbBlogService.previousPage();
  }

  nextPage(): any[] {
    return this.cbBlogService.nextPage();
  }
}
