import { Injectable } from '@angular/core';
import * as interfaces from '@ds/interfaces';
import { PaginatedResults } from '@ds/interfaces';
import { Observable } from 'rxjs';
import { ApiService } from './api.service';
import { PaginatedOptions } from '@ds/admin-ui';

export interface LocationFindAllOptions extends PaginatedOptions {
  id?: string | number | string[] | number[];
  q?: string;
  inActive?: boolean;
}

// generic variables loaded at top to make using
// this as a template easy. Can be removed / replaced
const resource = 'locations';
type ModelType = interfaces.RetailLocation;

@Injectable({
  providedIn: 'root',
})
export class LocationService {
  constructor(private api: ApiService) {}

  findAll(
    params?: LocationFindAllOptions
  ): Observable<PaginatedResults<ModelType>> {
    return this.api.get<PaginatedResults<ModelType>>(resource, { ...params });
  }

  findOne(id: number | string): Observable<ModelType> {
    return this.api.get(`${resource}/${id.toString()}`);
  }

  create(model: ModelType): Observable<ModelType> {
    return this.api.post(resource, model);
  }

  update(model: ModelType): Observable<ModelType> {
    return this.api.put(`${resource}/${model.id.toString()}`, model);
  }

  destroy(model: ModelType): Observable<ModelType> {
    return this.api.delete(`${resource}/${model.id.toString()}`);
  }

  makeDefaultPhoto(locationId: number, photoId: number) {
    return this.api.put(`${resource}/${locationId}/default-photo/${photoId}`);
  }
}
