import { Component, Input } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

import { ModalService } from '@ds/core';
import {
  HourTypes,
  RetailLocation,
  RetailLocationHours,
  WebsiteEmailEntry,
  WebsiteEmailEntryType,
} from '@ds/interfaces';

import { ContentBlockContactFormComponent } from '../content-block-contact-card/content-block-contact-form.component';

@Component({
  selector: 'ds-cb-location-detail',
  template: `
    <ds-cb-section *ngIf="location">
      <ds-cb-link
        *ngIf="parentSlug"
        [route]="[parentSlug]"
        text="Back To List"
      ></ds-cb-link>

      <div class="ds-row">
        <div class="ds-col-md-8">
          <div class="ds-row">
            <div class="ds-col-sm-6">
              <ds-cb-card header="Contact" class="contact-card">
                <ul class="ds-fluid-text">
                  <li *ngFor="let phoneNumber of location.phoneNumbers">
                    {{ phoneNumber.type.toLocaleUpperCase() }}:
                    <a href="{{ 'tel:' + phoneNumber.number }}">{{
                      phoneNumber.number
                    }}</a>
                  </li>
                </ul>

                <div class="ds-text-right">
                  <button class="ds-button" (click)="emailLocation($event)">
                    <span
                      class="icon"
                      [inlineSVG]="'/icons/envelope.svg'"
                    ></span
                    >Email
                  </button>
                </div>
              </ds-cb-card>
              <ds-cb-card header="Address">
                <address class="ds-fluid-text">
                  {{ location.address.addressLineOne }} <br />
                  {{ location.address.addressLineTwo }}
                  <br
                    *ngIf="
                      location.address.addressLineTwo &&
                      location.address.addressLineTwo.length
                    "
                  />
                  {{ location.address.city }}, {{ location.address.state }}
                  <br />
                  {{ location.address.postalCode }}
                </address>

                <div class="ds-text-right">
                  <a
                    class="ds-button"
                    *ngIf="
                      location.config.googleMapsLink &&
                      location.config.googleMapsLink.length
                    "
                    [href]="location.config.googleMapsLink"
                  >
                    <span class="icon" [inlineSVG]="'/icons/map.svg'"></span
                    >Google Maps</a
                  >
                </div>
              </ds-cb-card>
              <ds-cb-card header="Hours">
                <ng-container *ngFor="let item of location.hoursOfOperation">
                  <h3 class="ds-fluid-text">{{ item.label }}</h3>
                  <ul class="ds-fluid-text" style="margin-bottom: 15px;">
                    <li
                      *ngFor="let day of [0, 1, 2, 3, 4, 5, 6]; index as i"
                      [class.active-day]="i === today"
                    >
                      {{ weekDays[i] }}:
                      {{ getHours(item.hours[i]) }}
                    </li>
                  </ul>
                  <p style="margin-bottom: 15px" *ngIf="item.disclaimer">
                    {{ item.disclaimer }}
                  </p>
                </ng-container>
              </ds-cb-card>
            </div>
            <div class="ds-col-sm-6">
              <ds-cb-card
                header="Services"
                *ngIf="location.services && location.services.length"
              >
                <ul class="services ds-fluid-text">
                  <li *ngFor="let service of location.services">
                    <span class="icon" [inlineSVG]="'/icons/cog.svg'"></span
                    >{{ service }}
                  </li>
                </ul>
              </ds-cb-card>
              <ds-cb-card header="Amenities">
                <ul class="amenities ds-fluid-text">
                  <li>
                    <span class="icon" [inlineSVG]="'/icons/cog.svg'"></span
                    >Drivers' lounge
                  </li>
                  <li>
                    <span class="icon" [inlineSVG]="'/icons/cog.svg'"></span
                    >Free water and coffee
                  </li>
                  <li>
                    <span class="icon" [inlineSVG]="'/icons/cog.svg'"></span
                    >Free Wi-Fi
                  </li>
                  <li>
                    <span class="icon" [inlineSVG]="'/icons/cog.svg'"></span
                    >Satellite tv
                  </li>
                  <li>
                    <span class="icon" [inlineSVG]="'/icons/cog.svg'"></span
                    >Vending machines
                  </li>
                  <li>
                    <span class="icon" [inlineSVG]="'/icons/cog.svg'"></span
                    >Hotel shuttle
                  </li>
                  <li>
                    <span class="icon" [inlineSVG]="'/icons/cog.svg'"></span
                    >List of hotels, restaurants and taxis
                  </li>
                  <li>
                    <span class="icon" [inlineSVG]="'/icons/cog.svg'"></span>Non
                    smoking facility
                  </li>
                  <li>
                    <span class="icon" [inlineSVG]="'/icons/cog.svg'"></span
                    >Trailer parking
                  </li>
                </ul>
              </ds-cb-card>
              <div *ngIf="location.config.showEliteSupportLogo">
                <ds-cb-image
                  imageHandle="vQAoM04zSQKrqX5gef1i"
                  size="small"
                ></ds-cb-image>
              </div>
            </div>
          </div>

          <div class="ds-row map" *ngIf="embedLink">
            <div class="ds-col">
              <ds-cb-card class="map-card">
                <iframe
                  style="width: 100%; min-height: 350px;"
                  [src]="embedLink"
                ></iframe>
              </ds-cb-card>
            </div>
          </div>
        </div>
        <div class="ds-col-md-4">
          <ds-cb-card
            ><p class="ds-fluid-text">{{ location.description }}</p>
          </ds-cb-card>
        </div>
      </div>

      <div class="ds-row-sc">
        <ds-cb-website-contacts
          [contacts]="location.websiteContacts"
        ></ds-cb-website-contacts>
      </div>
    </ds-cb-section>
  `,
  styles: [
    `
      ds-cb-card:not(:last-child) {
        margin-bottom: 16px;
      }

      .active-day {
        font-weight: bold;
      }

      address {
        font-style: normal;
      }

      .ds-button {
        margin-top: 4px;
      }

      .icon {
        width: 16px;
        display: inline-block;
        margin-right: 4px;
      }

      ul.services .icon,
      ul.amenities .icon {
        width: 12px;
        color: rgb(217, 83, 79);
      }

      :host ::ng-deep ds-cb-card:not(.map-card) .content {
        padding: 10px 15px;
      }

      :host-context(.small) .map {
        display: none;
      }

      .contact-card a {
        text-decoration: none;
      }
    `,
  ],
})
export class ContentBlockLocationDetailComponent {
  @Input()
  location: RetailLocation;

  @Input()
  parentSlug: string;

  weekDays = [
    'Sunday',
    'Monday',
    'Tuesday',
    'Wednesday',
    'Thursday',
    'Friday',
    'Saturday',
  ];

  today = new Date().getDay();

  constructor(
    private modalService: ModalService,
    private sanitizer: DomSanitizer
  ) {}

  emailLocation($event: MouseEvent) {
    $event.preventDefault();
    this.modalService.open<void, WebsiteEmailEntry>(
      ContentBlockContactFormComponent,
      {
        type: WebsiteEmailEntryType.LOCATION,
        locationId: this.location.id,
        location: this.location,
      }
    );
  }

  getHours(hours: RetailLocationHours): string {
    if (!hours || hours.type === HourTypes.CLOSED) {
      return 'Closed';
    } else if (hours.type === HourTypes['24H']) {
      return '24Hr';
    } else if (hours.type === HourTypes.BY_APPOINTMENT) {
      return 'By Appointment';
    }
    return `${hours.openTime}${hours.openPeriod} - ${hours.closedTime}${hours.closedPeriod}`;
  }

  get embedLink() {
    if (!this.location.config || !this.location.config.googleMapsEmbed) {
      return;
    }
    return this.sanitizer.bypassSecurityTrustResourceUrl(
      this.location.config.googleMapsEmbed
    );
  }
}
