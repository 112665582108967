// bunch of commented code incase we did not want to load the player
// at first but instead load the thumbnail. (faster page loads)

import {
  Component,
  Input,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
} from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { YouTubeContentBlock } from '@ds/interfaces';

// http://i3.ytimg.com/vi/erLk59H86ww/maxresdefault.jpg

@Component({
  selector: 'ds-cb-youtube',
  template: `
    <!-- <img
      *ngIf="!isOn"
      [src]="imageLink"
      [alt]="block.config.alt || 'Thumbnail for Video'"
    />
    <div *ngIf="!isOn" class="play-button" (click)="turnOn()">
      <span class="icon" [inlineSVG]="'icons/play.svg'"></span>
    </div> -->
    <iframe [src]="trustedLink" frameborder="0" allowfullscreen> </iframe>
    <div class="edit-shield"></div>
  `,
  styles: [
    `
      :host {
        position: relative;
        padding-bottom: 56.25%;
        padding-top: 30px;
        height: 0;
        overflow: hidden;
        display: inline-block;
        width: 100%;
      }
      img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
      }
      iframe {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
      }
      /* .play-button {
        box-sizing: border-box;
        display: inline-block;
        padding-top: 14px;
        padding-left: 8px;
        line-height: 20px;
        border: 6px solid #fff;
        border-radius: 25%;
        color: #f5f5f5;
        text-align: center;
        text-decoration: none;
        background-color: rgba(0, 0, 0, 0.5);
        font-size: 20px;
        font-weight: bold;
        transition: all 0.3s ease;
        height: 100px;
        width: 100px;
        display: inline-block;
        position: absolute;
        left: 50%;
        top: 50%;
        margin-top: -50px;
        margin-left: -50px;
      }
      .play-button:hover {
        background-color: rgba(0, 0, 0, 0.8);
        box-shadow: 0px 0px 10px rgba(255, 255, 100, 1);
        text-shadow: 0px 0px 10px rgba(255, 255, 100, 1);
        cursor: pointer;
      }
      .icon {
        display: block;
        height: 50px;
        width: 50px;
        margin: 0 auto;
      } */
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ContentBlockYouTubeComponent {
  block: YouTubeContentBlock;
  @Input() youTubeId: string;

  // isOn = false;

  constructor(private sanitizer: DomSanitizer) {}

  // turnOn() {
  //   this.isOn = true;
  //   // this.cd.markForCheck()
  // }

  get trustedLink() {
    const id = this.youTubeId;
    return this.sanitizer.bypassSecurityTrustResourceUrl(
      `https://www.youtube.com/embed/${id}?&rel=0`
    );
    // `https://www.youtube.com/embed/${id}?&rel=0&autoplay=1&mute=1`
  }

  // get imageLink() {
  //   return `https://img.youtube.com/vi/${this.youTubeId}/mqdefault.jpg`;
  // }

  init(block: YouTubeContentBlock) {
    this.block = block;
    this.youTubeId = block.config.youTubeId || '';
  }
}
